import React from 'react';
import { FaUserPlus } from 'react-icons/fa';
import AdminsTable from '../../components/tables/AdminsTable';
import adminService from '../../../services/adminService';
import useAdmins from '../../../hooks/business/useAdmins';
import AddAdminModal from '../../components/modals/AddAdminModal';
import SearchBar from '../../components/forms/SearchBar';
import useSelectedBusiness from '../../../hooks/business/useSelectedBusiness';
import { AdminRole } from '../../../types/business';
import useBusinessTranslation from '../../../hooks/useBusinessTranslation';
import Button from '../../components/buttons/Button';
import useBusinessEffect from '../../../hooks/effects/useBusinessEffect';

export default function AdminOverviewPage(): JSX.Element {
  const admins = useAdmins();
  const { t } = useBusinessTranslation();
  const { role } = useSelectedBusiness() || {};

  const [modalOpen, setModalOpen] = React.useState(false);
  const [search, setSearch] = React.useState('');

  useBusinessEffect(() => {
    adminService.getAllAdmins();
    adminService.getAllAdminInvites();
  }, []);

  return (
    <>
      <div className=" mb-4">
        <h1 className="text-3xl font-semibold font-serif">{t('page.settings.admins.title')}</h1>
        <p className="text-lg font-semibold py-1">{`${admins.length} ${t('general.total')}`}</p>
      </div>
      <div className="flex justify-between flex-wrap gap-2 mb-4">
        <div className="flex justify-start ">
          <SearchBar search={search} setSearch={setSearch} className="md:w-80 lg:w-96" />
        </div>
        {role === AdminRole.OWNER && (
          <div className="flex items-center">
            <Button onClick={() => setModalOpen(true)} variant="primary">
              <FaUserPlus className="h-5 w-5" />
              {t('page.settings.admins.addAdmin')}
            </Button>
          </div>
        )}
      </div>
      <div className="overflow-x-scroll">
        <AdminsTable
          data={admins.filter((x) => x.display.toLowerCase().replace('+', ' ').includes(search))}
        />
      </div>
      <AddAdminModal open={modalOpen} setOpen={setModalOpen} />
    </>
  );
}
