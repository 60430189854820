/* eslint-disable @typescript-eslint/no-redeclare */
import { BusinessInvite } from '../services/model/inviteService.model';

export default interface Business {
  businessId?: number;
  role: AdminRole;
  inviteId: string | null;
  picture: string | null;
  name: string;
  description: string;
  type: BusinessType;
  inviteFields?: BusinessInvite;
  communityId?: number;
  communityLink?: string;
}

export enum CommunityType {
  STUDENT = 'STUDENT',
  SPORT = 'SPORT',
  SOCIAL = 'SOCIAL',
  BUSINESS = 'BUSINESS',WORK = 'WORK',
  FAMILY = 'FAMILY',
  FRIENDS = 'FRIENDS',
  OTHER_COMMUNITY = 'OTHER_COMMUNITY',
}

export enum MomentType {
  WEDDING = 'WEDDING',
  BIRTH = 'BIRTH',
  FUNERAL = 'FUNERAL',
  OTHER_MOMENT = 'OTHER_MOMENT',
}

export const BusinessType = {
  ...CommunityType,
  ...MomentType,
};

export type BusinessType = CommunityType | MomentType;

export enum AdminRole {
  OWNER = 'OWNER',
  MEMBER = 'MEMBER',
}

export interface Admin {
  adminId?: number;
  userId?: number;
  role: AdminRole;
  display: string;
  picture?: string;
  pending?: boolean;
  inviteId?: number;
  onboarding?: boolean;
}

/**
 * Model AdminInvite
 *
 */
export type AdminInvite = {
  inviteId: number;
  userId?: number;
  display: string;
  picture?: string;
  role: AdminRole;
};
