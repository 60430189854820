import httpService from './httpService';
import { ProfileField } from '../types/Profile';
import { PersonalConnection } from '../types/misc';
import {
  setPersonalConnections,
  setProfileFields,
  setProfilePicture,
} from '../redux/slices/userSlice';
import { store } from '../redux/store';
import { RootState } from '../redux/reducers';
import {
  SharedBusinessData,
  SharedBusinessDataResponse,
  SharedDataResponse,
  UpdateBusinessConnectionOptions,
} from './model/userService.model';

/**
 * The user service, contains all logic for user actions
 */
const userService = {
  /**
   * Deletes the user account from the server
   * @param password The password of the user
   * @returns A promise that resolves when the account is deleted
   * @throws An error if the password is incorrect
   */
  deleteAccount: async (password: string): Promise<void> => {
    const config = {
      headers: {
        'X-Password': password,
      },
    };
    await httpService.delete('/business/account', config);
  },

  /**
   * Changes the password of the account from the server
   * @param password The current password of the user
   * @param newPasswordArgument The new password of the user
   * @returns A promise that resolves when the account is deleted
   * @throws An error if the password is incorrect
   */
  changePassword: async (password: string, newPasswordArgument: string): Promise<number> => {
    const config = {
      currentPassword: password,
      newPassword: newPasswordArgument,
    };
    const response = await httpService.put('/business/account/password', config);
    const { status }: { status: number } = response;
    return status;
  },

  getProfilePicture: async (): Promise<string> => {
    return httpService
      .get('/users/@me/avatar')
      .then(({ data }): string => {
        store.dispatch(setProfilePicture(data.path));
        return data.path;
      })
      .catch(() => '');
  },

  getConnections(): Promise<PersonalConnection[]> {
    return httpService.get<PersonalConnection[]>('/connection/general').then(({ data }) => {
      store.dispatch(setPersonalConnections(data));
      return data;
    });
  },

  getUserData(): Promise<ProfileField[]> {
    return httpService.get<ProfileField[]>('/getData').then(({ data }): ProfileField[] => {
      store.dispatch(setProfileFields(data));
      return data;
    });
  },
  setFields(field: ProfileField[]): Promise<ProfileField[]> {
    return httpService.post<ProfileField[]>('/setData', field).then(({ data }): ProfileField[] => {
      const difference = data.filter(
        (nf) => !(store.getState() as RootState).user.fields?.map((f) => f.id).includes(nf.id),
      );
      store.dispatch(setProfileFields(data));
      return difference;
    });
  },

  getSharedDataFromUser(userId: number): Promise<ProfileField[]> {
    return httpService
      .get<SharedDataResponse>(`/getAllSharedInfo/${userId}`)
      .then(({ data }): ProfileField[] => data.sharedDataByTarget);
  },

  updateBusinessConnection(
    businessId: number,
    args: SharedBusinessData,
    options: UpdateBusinessConnectionOptions = {},
  ): Promise<void> {
    const body = {
      ...args,
      ...options,
    };

    return httpService.put<void>(`/b2c-connection/${businessId}`, body).then();
  },
  getSharedDataWithBusiness(businessId: number): Promise<SharedBusinessData> {
    return httpService.get<SharedBusinessDataResponse>(`/b2c-connection/shared/${businessId}`).then(
      ({ data }): SharedBusinessData => ({
        ...data,
        propertyIds: data.propertyIds.map((pId: number): string => pId.toString()),
        dataForMembers: data.dataForMembers.map((d: number): string => d.toString()),
      }),
    );
  },
};

export default userService;
