/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable prefer-template */
import React, { useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import PhoneInput, { parsePhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { ProfileField } from '../../../../types/Profile';
import useDelayedEffect from '../../../../hooks/effects/useDelayedEffect';

interface PhoneNumberInputProps {
  field: ProfileField;
  setField: (field: ProfileField) => void;
  canBeEmpty?: boolean;
  mandatory?: boolean;
  label?: boolean;
  validate?: boolean;
}
export default function PhoneNumberInput({
  field,
  setField,
  canBeEmpty = false,
  mandatory = false,
  label = false,
  validate = false,
}: PhoneNumberInputProps): JSX.Element {
  const { t } = useTranslation();
  const [error, setError] = useState<string>('');

  useDelayedEffect(() => {
    if (!validate) return;
    if (
      !field.phoneNumber?.prefix &&
      !field.phoneNumber?.suffix &&
      !field.phoneNumber?.countryCode
    ) {
      setError(canBeEmpty ? '' : t('invalid.empty'));
      return;
    }
    setError(
      parsePhoneNumber(`${field.phoneNumber?.prefix}${field.phoneNumber?.suffix}`)?.isValid()
        ? ''
        : t('invalid.invalid'),
    );
  }, [`${field.phoneNumber?.prefix}${field.phoneNumber?.suffix}`, validate]);

  const handleChange = (s: string): void => {
    if (!s) {
      setField({ ...field, phoneNumber: { prefix: '', suffix: '', countryCode: '' } });
      return;
    }
    const number = parsePhoneNumber(s);
    if (!number || !number.isValid()) {
      setField({ ...field, phoneNumber: { prefix: s, suffix: '', countryCode: '' } });
      return;
    }

    const prefix = `+${number.countryCallingCode}`;
    const suffix = s.replace(prefix, '');

    setField({
      ...field,
      phoneNumber: {
        countryCode: number.country!,
        prefix,
        suffix,
      },
    });
  };

  return (
    <div className="w-full">
      {' '}
      {label && (
        <label className=" pr-2 font-medium text-base mb-1">
          {`${t('general.your')} ${t('dataType.phoneNumber').toLowerCase()}`}
          {mandatory ? '*' : ''}
        </label>
      )}
      <div className="flex flex-col w-full relative">
        <label htmlFor={field.id} className="mb-1 text-sm">
          {t('dataType.phoneNumber')}
        </label>
        <PhoneInput
          autoComplete="tel"
          className={classNames('pl-2 w-full', { invalid: error })}
          international
          defaultCountry='NL'
          countryCallingCodeEditable
          placeholder={t('dataType.phoneNumber')}
          onChange={handleChange}
          value={`${field.phoneNumber?.prefix}${field.phoneNumber?.suffix}`}
        />
        <p className="text-red-500 absolute top-0 right-1 text-sm transition-all">{error}</p>
      </div>
    </div>
  );
}
