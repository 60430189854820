import { Frequency } from '../types/event';

function getDatesBetween(startDate: Date, endDate: Date, interval: Frequency): Date[] {
  const dates: Date[] = [];
  const currentDate = new Date(startDate);

  while (currentDate <= endDate) {
    dates.push(new Date(currentDate));

    switch (interval) {
      case Frequency.DAILY:
        currentDate.setDate(currentDate.getDate() + 1);
        break;
      case Frequency.WEEKLY:
        currentDate.setDate(currentDate.getDate() + 7);
        break;
      case Frequency.BI_WEEKLY:
        currentDate.setDate(currentDate.getDate() + 14);
        break;
      case Frequency.MONTHLY:
        currentDate.setMonth(currentDate.getMonth() + 1);
        break;
      case Frequency.YEARLY:
        currentDate.setFullYear(currentDate.getFullYear() + 1);
        break;
      default:
        throw new Error('Invalid interval');
    }
  }
  return dates;
}

function calculateDayDifference(startDate: Date, endDate: Date): number {
  const oneDay = 24 * 60 * 60 * 1000; // One day in milliseconds
  const startDay = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
  const endDay = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());
  const diffDays = Math.round(Math.abs((endDay.getTime() - startDay.getTime()) / oneDay));
  return diffDays;
}

export default { getDatesBetween, calculateDayDifference };
