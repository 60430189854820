import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ModalLayout from '../layouts/ModalLayout';
import { PostcardTemplate } from '../../../types/misc';
import CancelSaveButton from '../misc/CancelSaveButton';

interface PostcardModalProps {
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
  templates: PostcardTemplate[];
  card: PostcardTemplate | null;
  setCard: (selectedCard: PostcardTemplate) => void;
}

export default function PostcardModal({
  showModal,
  setShowModal,
  templates,
  card,
  setCard,
}: PostcardModalProps): JSX.Element {
  const [selectedCard, setSelectedCard] = useState<PostcardTemplate | null>(card);

  const [index, setIndex] = useState<number>(0);
  const { t } = useTranslation();

  const handleCardSelect = (newCard: PostcardTemplate): void => {
    setSelectedCard(newCard);
    setIndex(templates.indexOf(newCard));

    const element = document.getElementsByClassName('active-card')[0];
    if (!element) return;
    if (templates.indexOf(newCard) < index)
      element.scrollIntoView({ behavior: 'smooth', inline: 'end' });
    else element.scrollIntoView({ behavior: 'smooth', inline: 'start' });
  };

  useEffect(() => setSelectedCard(templates[0]), [templates]);

  useEffect((): any => {
    const handleKeyDown = (event: KeyboardEvent): void => {
      if (event.key === 'ArrowLeft') {
        if (index === 0) return;
        handleCardSelect(templates[index - 1]);
      } else if (event.key === 'ArrowRight') {
        if (index === templates.length - 1) return;
        handleCardSelect(templates[index + 1]);
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return (): void => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [index, templates]);

  const handleSubmit = (): void => {
    setCard(selectedCard!);
    setShowModal(false);
  };

  return (
    <ModalLayout
      open={showModal}
      setOpen={setShowModal}
      className="bg-secondary-50 min-w-[400px] w-2/5 pb-4 pt-5  z-50 rounded-[20px] py-6">
      <div className="flex flex-col px-10 justify-center items-center">
        <div className="w-full">
          <p className="font-semibold text-lg ">{t('component.modal.postcard.title')}</p>
          <p>{t('component.modal.postcard.subtitle')}</p>
        </div>
        <img
          data-testid="card-big"
          src={templates.find((c) => c === selectedCard)?.thumbnail}
          alt="Card"
          className="min-w-[80] min-h-[80] max-w-96 max-h-96 border-primary-900 border-2  border my-4"
        />
        <div className="relative flex w-full">
          {index > 0 && (
            <button
              data-testid="postcard-left"
              type="button"
              className="bg-[#d8f4f0] hover:bg-[#dff2f2] mx-auto absolute inset-y-1/2 -left-4 transform -translate-y-1/2 w-fit h-fit text-black p-2 rounded-lg"
              onClick={(): void => handleCardSelect(templates[index - 1])}>
              <FaChevronLeft className="h-6 w-6" />
            </button>
          )}
          <div data-testid="card-scroller" className="flex w-full justify-start overflow-x-scroll">
            {templates.map(
              (image: PostcardTemplate): JSX.Element => (
                <img
                  key={image.id}
                  data-testid={image.id}
                  src={image.thumbnail}
                  alt={image.name}
                  className={`h-24 w-24 border-primary-900 border-2 my-4 mx-2 cursor-pointer ${
                    selectedCard === image ? '!border-primary-300 active-card' : ''
                  }`}
                  onClick={(): void => setSelectedCard(image)}
                />
              ),
            )}
          </div>
          {index < templates.length - 1 && (
            <button
              data-testid="postcard-right"
              type="button"
              className="bg-[#d8f4f0] hover:bg-[#dff2f2] mx-auto absolute inset-y-1/2 -right-4 transform -translate-y-1/2 w-fit h-fit text-black p-2 rounded-lg"
              onClick={(): void => handleCardSelect(templates[index + 1])}>
              <FaChevronRight className="h-6 w-6" />
            </button>
          )}
        </div>
        <div className="w-full mt-4 justify-end flex">
          <CancelSaveButton
            disabled={selectedCard === null}
            onSave={handleSubmit}
            onCancel={() => setShowModal(false)}
          />
        </div>
      </div>
    </ModalLayout>
  );
}
