import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { RootState } from '../../../redux/reducers';
import Button from '../../components/buttons/Button';
import { sendEmail } from '../../../utils/actionUtils';
import ChangeSelectionModal from '../../components/modals/ChangeSelectionModal';
import { ConnectionIdentifier } from '../../../types/misc';
import { Connection } from '../../../redux/slices/connectionsSlice';

export default function EmailPage() {
  const location = useLocation();
  const [selected, setSelected] = useState<ConnectionIdentifier[]>(
    location?.state?.connections?.map(({ id, type }: Connection) => ({ id, type })) ?? [],
  );
  const [subject, setSubject] = useState<string>('');
  const [text, setText] = useState<string>('');
  const [mode, setMode] = useState<'cc' | 'bcc'>('bcc');
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const connections = useSelector((state: RootState) => state.connections);
  const { t } = useTranslation();

  const handleSubmit = () => {
    const emails = connections
      .filter(({ id, type }) => selected.some((s) => s.id === id && s.type === type))
      .map(({ id, fields: { EMAIL } }) => EMAIL)
      .filter(Boolean);
    if (!emails.length) toast.error(t('page.communication.noneSelected'));

    sendEmail(mode === 'cc' ? emails : [], mode === 'bcc' ? emails : [], subject, text);
  };

  return (
    <>
      <div className="grid grid-cols-8 gap-6 flex-1">
        <div className="col-span-8 lg:col-span-5 flex flex-col gap-6 flex-1">
          <div data-testid="postcard-info" className="bg-secondary-200 rounded-2xl p-4 h-fit">
            <h2 className="mb-2 pl-2 font-serif text-3xl font-semibold">
              {t('page.communication.sendEmail.title')}
            </h2>
            <div className="pl-2">
              <p>{t('page.communication.sendEmail.subtitle')}</p>
            </div>
          </div>
          <div className="flex justify-between mb-2 pl-2 ">
            <span className="flex">
              <p className="text-primary font-semibold">{selected.length}</p>/{connections.length}
              {t('page.payment.selected')}
            </span>
            <p
              data-testid="edit-selection"
              onClick={(): void => setModalOpen(true)}
              className="underline cursor-pointer">
              {t('page.payment.editSelection')}
            </p>
          </div>
          <div className="flex gap-4 flex-col border-t border-secondary-200 pt-6">
            <div className="flex flex-col gap-2">
              <h2 className="text-2xl font-semibold">{t('page.communication.sendEmail.header')}</h2>
            </div>
            <div className="flex flex-col gap-2">
              <label className="pr-2 font-medium text-base">
                {t('page.communication.sendEmail.cc')}
              </label>
              <div className="flex gap-1 cursor-pointer w-fit" onClick={() => setMode('cc')}>
                <input type="checkbox" checked={mode === 'cc'} />
                CC
              </div>
              <div className="flex gap-1 cursor-pointer w-fit" onClick={() => setMode('bcc')}>
                <input type="checkbox" checked={mode === 'bcc'} />
                BCC
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <label className="pr-2 font-medium text-base">
                {t('page.communication.sendEmail.subject')}
              </label>
              <input
                className="w-full"
                type="text"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
              />
            </div>
            <div className="flex gap-1 flex-col">
              <label className="pr-2 font-medium text-base">
                {t('page.communication.message')}
              </label>
              <textarea
                className="w-full h-44"
                value={text}
                onChange={(e) => setText(e.target.value)}
              />
            </div>
          </div>
          <div className="flex-1 justify-end items-center gap-4 flex">
            <span className="text-xs font-medium text-primary-900">
              {t('page.communication.sendEmail.warning')}
            </span>
            <Button variant="primary" disabled={!selected.length} onClick={handleSubmit}>
              {t('page.communication.sendEmail.submit')}
            </Button>
          </div>
        </div>
      </div>
      <ChangeSelectionModal
        open={modalOpen}
        setOpen={setModalOpen}
        selection={selected}
        setSelection={setSelected}
        type="email"
      />
    </>
  );
}
