import { ProfileDataType } from '../../types/Profile';

export enum CustomFieldType {
  TEXT = 'TEXT',
  MULTIPLE_CHOICE = 'MULTIPLE_CHOICE',
  COMMUNICATION_NAME = 'COMMUNICATION_NAME',
}

export interface CustomTextField {
  customFieldId?: number;
  type: CustomFieldType.TEXT;
  label: string;
  mandatory: boolean;
}

export interface CustomMultipleChoiceField {
  customFieldId?: number;
  type: CustomFieldType.MULTIPLE_CHOICE;
  label: string;
  options: string[];
  mandatory: boolean;
}

export interface CommunicationName {
  customFieldId?: number;
  type: CustomFieldType.COMMUNICATION_NAME;
  mandatory: boolean;
}

export type CustomField = CustomLabelField | CommunicationName;

export type CustomLabelField = CustomTextField | CustomMultipleChoiceField;

/**
 * Response for custom fields
 */
export type CustomFieldResponses = {
  [customFieldId: number]: string;
};

export type BusinessInvite = {
  /**
   * The mandatory fields the user specified
   */
  mandatoryFields: ProfileDataType[];
  /**
   * The optional fields the user specified
   */
  optionalFields: ProfileDataType[];
  /**
   * The non requested fields the user specified
   */
  nonRequestedFields: ProfileDataType[];
  /**
   * The custom fields the user specified
   */
  customFields: CustomField[];

  /**
   * Whether the user accepts the invite automatically
   */
  acceptAutomatically: boolean;

  /**
   * The message the user specified
   */
  message: string;

  /**
   * Friendly link
   */
  friendlyId?: string;

  linkExpiry?: Date;
};
