import React from 'react';
import { useTranslation } from 'react-i18next';
import ComingSoon from '../../components/misc/ComingSoon';

export default function EventReportsPage() {
  const { t } = useTranslation();
  return (
    <div className="flex flex-1  items-center justify-center flex-col">
      <ComingSoon text={t('page.agenda.report.text')} className="max-w-sm w-full" />
    </div>
  );
}
