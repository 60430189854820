import httpService from './httpService';
import { store } from '../redux/store';
import { ProfileField } from '../types/Profile';
import {
  addBusiness,
  deleteBusiness,
  setDescription,
  setName,
  setPicture,
  setSelectedBusiness,
  setState,
  wipeSelectedBusiness,
} from '../redux/slices/applicationSlice';
import { CreateBusinessForm } from '../types/forms';
import Business from '../types/business';
import { getSelectedBusiness } from '../hooks/business/useSelectedBusiness';

const businessService = {
  /**
   * Sets the user's profile image.
   *
   * @function
   * @async
   * @param {File} image - The image file to set as the user's profile image.
   * @returns {Promise<void>} A Promise that resolves when the profile image has been set.
   */
  setProfileImage: async (image: File): Promise<void> => {
    const formData = new FormData();
    formData.append('avatar', image);
    return httpService.post('/business/image', formData).then(({ data }): void => {
      store.dispatch(setPicture(data.path));
    });
  },

  /**
   * Deletes the user's profile image.
   *
   * @function
   * @async
   * @returns {Promise<void>} A Promise that resolves when the profile image has been deleted.
   */
  deleteProfileImage: async (): Promise<void> => {
    return httpService.delete('/business/image').then((): void => {
      store.dispatch(setPicture(null));
      window.dispatchEvent(new Event('profileImage'));
    });
  },

  createBusiness: async (businessForm: CreateBusinessForm): Promise<void> => {
    const { data: business } = await httpService.post<Business>('/business', businessForm);
    store.dispatch(addBusiness(business));
    if (business.businessId) store.dispatch(setSelectedBusiness(business.businessId));
  },

  fetchBusinesses: async (): Promise<void> => {
    const selectedBusiness = getSelectedBusiness();
    return httpService.get<Business[]>('/business').then(({ data }): void => {
      const businesses = data.map((b) =>
        b.businessId === selectedBusiness?.businessId
          ? { ...b, inviteFields: selectedBusiness?.inviteFields }
          : b,
      );
      store.dispatch(setState({ ...store.getState().application, businesses }));
    });
  },

  deleteBusiness: async (): Promise<void> => {
    await httpService.delete(`/business/account`);
    const id = store.getState().application.selectedBusiness;
    store.dispatch(wipeSelectedBusiness());
    store.dispatch(deleteBusiness(id));
  },

  updateBusiness: async (name: string, description: string): Promise<void> => {
    await httpService.put(`/business/account`, { name, description });
    store.dispatch(setName(name));
    store.dispatch(setDescription(description));
  },
};

export default businessService;
