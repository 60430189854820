import React from 'react';
import { twMerge } from 'tailwind-merge';

interface LogoLetterProps {
  dark?: boolean;
  className?: string;
}
export default function LogoLetter({ dark = false, className }: LogoLetterProps): JSX.Element {
  return (
    <img
      className={twMerge('h-10 w-auto cursor-pointer flex-shrink-0', className)}
      src={`${process.env.PUBLIC_URL}/assets/logo/logo_letter${dark ? '_dark' : ''}.svg`}
      alt="Vera Connect"
    />
  );
}
