export function sendSMS(phoneNumbers: string[], message: string = '') {
  if (navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
    // On iOS, open the messaging app with a single recipient
    const smsUrl = `sms:/open?addresses=${phoneNumbers.join(',')}&body=${encodeURIComponent(
      message,
    )}`;
    window.open(smsUrl, '_blank');
  } else if (navigator.userAgent.match(/Android/i)) {
    // On Android, open the messaging app using the intent scheme
    const smsUrl = `sms:${phoneNumbers.join(',')}?body=${encodeURIComponent(message)}`;
    window.open(smsUrl, '_blank');
  } else {
    // Fallback behavior for other platforms
    const smsUrl = `sms:/open?addresses=${phoneNumbers.join(',')}&body=${encodeURIComponent(
      message,
    )}`;
    window.open(smsUrl, '_blank');
  }
}

export function sendEmail(cc: string[], bcc: string[], subject: string, text: string) {
  const mailtoLink = `mailto:?cc=${cc.join(';')}&bcc=${bcc.join(
    ';',
  )}&subject=${subject}&body=${text}`;

  window.open(mailtoLink, '_blank');
}
