import React, { useEffect } from 'react';
import QRCode from 'qrcode';
import { toast } from 'react-toastify';
import { isMobile } from 'react-device-detect';
import { FaQrcode } from 'react-icons/fa';
import { MdContentCopy } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import WhatsappButton from '../buttons/WhatsappButton';
import useSelectedBusiness from '../../../hooks/business/useSelectedBusiness';
import ShareButton from '../buttons/ShareButton';
import QrModal from '../modals/QrModal';
import inviteService from '../../../services/inviteService';
import Button from '../buttons/Button';
import { CONSUMER_URL } from '../../../constants';

interface InvitationLinkProps {
  link?: string;
}

export default function InvitationLink({ link }: InvitationLinkProps): JSX.Element {
  const [qrCode, setQrCode] = React.useState<string>('');
  const [showQRCode, setShowQRCode] = React.useState<boolean>(false);
  const [text, setText] = React.useState('');

  const business = useSelectedBusiness();
  const { t } = useTranslation();
  const inviteLink = `${CONSUMER_URL}/vc/${link || business?.inviteFields?.friendlyId}`;

  const getShareText = async (): Promise<void> => {
    const invite = await inviteService.fetchInvite();
    setText(
      invite.message
        ? `${invite.message} \n\n${
            invite.linkExpiry
              ? `(Geldig tot ${invite.linkExpiry.toLocaleDateString('en-GB', {
                  day: '2-digit',
                  month: '2-digit',
                  year: 'numeric',
                })}) \n`
              : ''
          }${inviteLink}`
        : `Mag ik je gegevens voor "${business?.name.replace(
            '&',
            'en',
          )}"? Hiervoor kun je deze link gebruiken: \n\n${
            invite.linkExpiry
              ? `(Geldig tot ${invite.linkExpiry.toLocaleDateString('en-GB', {
                  day: '2-digit',
                  month: '2-digit',
                  year: 'numeric',
                })}) \n`
              : ''
          }${inviteLink}`,
    );
  };

  const clipBoardHandler = (): void => {
    navigator.clipboard.writeText(inviteLink);
    toast.success(t('toast.success.copiedToClipboard'));
  };

  useEffect((): void => {
    const generateQRCode = async (): Promise<void> => {
      try {
        const qr = await QRCode.toDataURL(inviteLink);
        setQrCode(qr);
      } catch (err) {
        console.error(err);
      }
    };
    generateQRCode();
    getShareText();
  }, [inviteLink]);

  const handleQrCodebutton = (): void => {
    setShowQRCode(true);
  };
  return (
    <div className="flex flex-col w-full">
      <div className="flex mb-2 pb-2 items-center justify-center gap-2">
        <input
          data-testid="invite-link-input"
          type="text"
          className="copy-link-input flex-1 overflow-x-scroll custom-text-selection "
          value={inviteLink}
          readOnly
        />
        <Button variant="secondary" onClick={clipBoardHandler} className="rounded-full p-3">
          <MdContentCopy className="h-5 w-5" />
        </Button>
      </div>
      <div className="flex mb-2 gap-2">
        {isMobile ? (
          <ShareButton title="Deel informatie via Vera Connect" text={text} url={inviteLink} />
        ) : (
          <WhatsappButton text={text} />
        )}
        <Button variant="secondary" onClick={handleQrCodebutton}>
          <FaQrcode className="h-5 w-5" />
          {' QR'}
        </Button>
      </div>
      <QrModal qrCode={qrCode} open={showQRCode} setOpen={setShowQRCode} />
    </div>
  );
}
