import React, { useEffect, useState } from 'react';
import { AiOutlinePlus } from 'react-icons/ai';
import { FiCalendar } from 'react-icons/fi';
import { HiUserGroup } from 'react-icons/hi2';
import { FaCalendar } from 'react-icons/fa';

import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { FaArrowRight, FaRegClock } from 'react-icons/fa6';
import PageHeader from '../../components/misc/PageHeader';
import SearchBar from '../../components/forms/SearchBar';
import eventService from '../../../services/eventService';
import useEvents from '../../../hooks/business/useEvents';
import { Event, EventParticipationAnswer, EventViewType } from '../../../types/event';
import eventUtils from '../../../utils/eventUtils';
import Button from '../../components/buttons/Button';

interface EventsPageProps {
  type: EventViewType;
}

export default function EventsPage({ type }: EventsPageProps) {
  const [search, setSearch] = useState<string>('');
  const events = useEvents(type);
  const { t } = useTranslation();

  useEffect(() => {
    eventService.getUpcomingEvents();
  }, []);

  return (
    <>
      <div className="flex flex-row">
        <div className="flex-1">
          <PageHeader
            className="pb-6"
            title={t(`page.event.${type.toLocaleLowerCase()}.title`)}
            subtitle={t(`page.event.${type.toLocaleLowerCase()}.subtitle`)}
          />
        </div>
        <Link to={`/events${type === EventViewType.DATE_PICKER ? '/date-picker' : ''}/create`}>
          <Button variant="primary">
            <FaCalendar className="h-5 w-5" />
            {t(`page.event.${type.toLocaleLowerCase()}.add`)}
          </Button>
        </Link>
      </div>
      <div className="flex flex-1 flex-col gap-5 border-y py-4 border-primary-300">
        <SearchBar search={search} setSearch={setSearch} className="w-[380px]" />
        <div className="flex flex-wrap gap-5">
          {eventUtils
            .sortEvents(events)
            .filter((e) =>
              e.title
                .toLowerCase()
                .replaceAll(' ', '')
                .includes(search.toLowerCase().replaceAll(' ', '')),
            )
            .map((e) => (
              <EventCard key={e.id} event={e} />
            ))}
          <Link to={`/events${type === EventViewType.DATE_PICKER ? '/date-picker' : ''}/create`}>
            <div className="flex hover:opacity-80 transition-all cursor-pointer flex-col items-center justify-center border border-primary border-dashed w-[250px] h-[230px] rounded-[20px] overflow-hidden">
              <AiOutlinePlus className="w-14 h-14 stroke-1 text-primary" />
              <p className="text-primary text-center">
                {t(`page.event.${type.toLocaleLowerCase()}.add`)}
              </p>
            </div>
          </Link>
        </div>
      </div>
    </>
  );
}

interface EventCardProps {
  event: Event;
}

function EventCard({ event }: EventCardProps) {
  const { t } = useTranslation();
  return (
    <Link
      className="flex group cursor-pointer flex-col border border-primary-300 w-[250px] h-[230px] rounded-[20px] overflow-hidden"
      to={`/events${event.isDatePicker ? '/date-picker' : ''}/details`}
      state={{ eventId: event.id }}>
      <div className="w-full h-[90px] flex justify-end bg-primary-300 relative" />
      {/* <img src={URL} alt="Event" className="object-cover w-full h-full absolute" />
      <div className="flex flex-col gap-2 items-end m-2">
        <div className="bg-secondary-200 gap-1 text-sm flex items-center justify-center py-[2px] px-[6px] rounded-[6px] h-fit w-fit z-[1]">
          <HiOutlineTicket />
          Gratis
        </div>
        <div className="bg-secondary-200 gap-1 text-sm flex items-center justify-center py-[2px] px-[6px] rounded-[6px] h-fit w-fit z-[1]">
          <HiUserGroup className="w-4 h-4" />
          323
        </div>
      </div> */}
      <div className="w-full group-hover:bg-opacity-80 transition-all flex flex-1 flex-col bg-primary-900 p-4 gap-4 text-secondary-50">
        <div className="flex justify-between w-full h-fit items-center">
          <p className="text-secondary-50 font-serif text-xl">{event.title}</p>
          <FaArrowRight className="w-5 h-5" />
        </div>
        <div className="flex flex-col gap-2">
          {event.isDatePicker ? (
            <>
              <div className="flex gap-2 w-full items-center">
                <FiCalendar className="w-4 h-4" />
                <p className="text-secondary-50 text-sm">
                  {`${format(event.dateOptions[0]?.startTime, 'dd-MM-yyyy')} + ${
                    event.dateOptions.length
                  } ${t('general.options')}`}
                </p>
              </div>
              <div className="flex gap-2 w-full items-center">
                <HiUserGroup className="w-4 h-4" />
                <p className="text-secondary-50 text-sm">
                  {`${
                    event.participants.filter((p) =>
                      p.answers?.some((a) => a.answer !== EventParticipationAnswer.PENDING),
                    ).length
                  } / ${event.memberCount}`}
                </p>
              </div>
            </>
          ) : (
            <>
              <div className="flex gap-2 w-full items-center">
                <FiCalendar className="w-4 h-4" />
                <p className="text-secondary-50 text-sm">
                  {`${format(event.startTime, 'dd-MM-yyyy')}${
                    event.endTime ? ` -> ${format(event.startTime, 'dd-MM-yyyy')}` : ''
                  }`}
                </p>
              </div>
              <div className="flex gap-2">
                <FaRegClock className="w-4 h-4" />
                <p className="text-secondary-50 text-sm">{eventUtils.formatDate(event, 'time')}</p>
              </div>
            </>
          )}
        </div>
      </div>
    </Link>
  );
}
