import React from 'react';
import { twMerge } from 'tailwind-merge';
import { APPLICATION_VERSION } from '../../../constants';

interface LogoProps {
  dark?: boolean;
  className?: string;
}
export default function Logo({ dark = false, className }: LogoProps): JSX.Element {
  return (
    <img
      className={twMerge('h-10 w-auto cursor-pointer', className)}
      src={`${process.env.PUBLIC_URL}/assets/logo/logo_name_${
        dark ? 'dark_' : ''
      }${APPLICATION_VERSION.toLowerCase()}.svg`}
      alt="Vera Connect"
    />
  );
}
