import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment } from 'react';
import { AiOutlinePlus } from 'react-icons/ai';
import { twMerge } from 'tailwind-merge';

interface ModalLayoutProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  className?: string;
  children?: React.ReactNode;
  preventClose?: boolean;
  closeButton?: boolean;
}

export default function ModalLayout({
  open,
  setOpen,
  className,
  children = null,
  preventClose = false,
  closeButton = false,
}: ModalLayoutProps): JSX.Element {
  const focusRef = React.useRef(null);
  return (
    <Transition.Root data-testid="postcard-modal" show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        onClose={preventClose ? () => {} : setOpen}
        initialFocus={focusRef}>
        <input className="hidden" ref={focusRef} />
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-primary-900 bg-opacity-60 transition-opacity backdrop-filter backdrop-blur-sm" />
        </Transition.Child>
        <div className="fixed inset-0 z-50 overflow-y-auto ">
          <div className="flex min-h-full justify-center p-4 items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel className={twMerge('relative', className)}>
                {children}
                {closeButton && (
                  <button
                    onClick={() => setOpen(false)}
                    type="button"
                    className="absolute text-primary-900 top-3 right-3">
                    <AiOutlinePlus className="h-7 w-7 rotate-45" />
                  </button>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
