import { format } from 'date-fns';
import { enUS, nl } from 'date-fns/locale';
import { DateOption, EventParticipationAnswer, Event } from '../types/event';
import { capitalizeWords } from './stringUtils';
import { store } from '../redux/store';
import { RootState } from '../redux/reducers';
import Language from '../types/language';
import dateUtils from './dateUtils';

const ANSWER_WEIGHTS = {
  [EventParticipationAnswer.PENDING]: 0,
  [EventParticipationAnswer.NO]: 0,
  [EventParticipationAnswer.MAYBE]: 1,
  [EventParticipationAnswer.YES]: 2,
};

function scoreDateOption(dateOption: DateOption): number {
  return dateOption.answers.map((a) => ANSWER_WEIGHTS[a.answer]).reduce((a, b) => a + b, 0);
}

function calculatePercentage(dateOption: DateOption): number {
  return (
    (scoreDateOption(dateOption) /
      dateOption.answers.filter((a) => a.answer !== EventParticipationAnswer.PENDING).length /
      ANSWER_WEIGHTS[EventParticipationAnswer.YES]) *
    100
  );
}

function sortDateOptions(options: DateOption[], type: 'score' | 'time' = 'score'): DateOption[] {
  const newOptions = [...options];
  return newOptions.sort((a, b) =>
    type === 'score'
      ? scoreDateOption(b) - scoreDateOption(a)
      : new Date(a.startTime).getTime() - new Date(b.startTime).getTime(),
  );
}

function sortEvents(events: Event[]): Event[] {
  const newEvents = [...events];
  return newEvents.sort((a, b) => {
    const aDate = a.dateOptions?.length ? a.dateOptions[0].startTime : a.startTime;
    const bDate = b.dateOptions?.length ? b.dateOptions[0].startTime : b.startTime;
    return new Date(aDate).getTime() - new Date(bDate).getTime();
  });
}

function formatDate<T extends { startTime: Date; endTime?: Date }>(
  { startTime, endTime }: T,
  type: 'date' | 'time' = 'date',
): string {
  const { language } = (store.getState() as RootState).application;

  const startDate = new Date(startTime);
  const endDate = endTime ? new Date(endTime) : undefined;

  const locale = language === Language.EN ? enUS : nl;
  const formatter = type === 'date' ? 'EEEEEE. d LLLL' : 'HH:mm';
  const dateDiff = endDate ? dateUtils.calculateDayDifference(startDate, endDate) : undefined;

  return `${capitalizeWords(
    format(
      startTime,
      startDate.getMonth() === endDate?.getMonth() ? formatter.replace(' LLLL', '') : formatter,
      { locale },
    ),
  )}${endTime ? ` - ${capitalizeWords(format(endTime, formatter, { locale }))}` : ''} ${
    dateDiff && dateDiff > 0 && type === 'time' ? `(+${+dateDiff})` : ''
  }`;
}

export default {
  ANSWER_WEIGHTS,
  scoreDateOption,
  calculatePercentage,
  sortDateOptions,
  formatDate,
  sortEvents,
};