import React, { useEffect, useRef, useState } from 'react';
import { FaPaperPlane } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { AiOutlinePlus } from 'react-icons/ai';
import classNames from 'classnames';
import ModalLayout from '../layouts/ModalLayout';
import ModalHeader from '../misc/ModalHeader';
import { isValidEmail } from '../../../utils/stringUtils';
import useBusinessTranslation from '../../../hooks/useBusinessTranslation';
import CancelSaveButton from '../misc/CancelSaveButton';
import Button from '../buttons/Button';
import { RootState } from '../../../redux/reducers';
import usePersonalConnections from '../../../hooks/account/usePersonalConnections';
import ConnectionsList from '../misc/ConnectionsList';
import connectionService from '../../../services/connectionService';
import userService from '../../../services/userService';
import SearchBar from '../forms/SearchBar';
import adminService from '../../../services/adminService';
import { AdminRole } from '../../../types/business';
import useAdmins from '../../../hooks/business/useAdmins';

interface AddAdminModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export default function AddAdminModal({ open, setOpen }: AddAdminModalProps): JSX.Element {
  const { t } = useBusinessTranslation();
  const connections = useSelector((state: RootState) => state.connections);
  const personalConnections = usePersonalConnections();
  const adminIds = useAdmins()
    .filter((a) => a.userId)
    .map((a) => a.userId);

  const [emails, setEmails] = useState<string[]>([]);
  const [currentEmail, setCurrentEmail] = useState<string>('');
  const [userIds, setUserIds] = useState<string[]>([]);
  const [mode, setMode] = useState<'BUSINESS' | 'PERSONAL'>('BUSINESS');
  const [search, setSearch] = useState<string>('');
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!connections.length) connectionService.getConnections();
    if (!personalConnections.length) userService.getConnections();
  }, []);

  useEffect(() => {
    setEmails([]);
    setUserIds([]);
  }, [open]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentEmail(event.target.value);
  };

  const handleInputKeyDown = async (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === ' ' || event.key === 'Enter') {
      event.preventDefault(); // Prevent space from being typed
      const trimmedEmail = currentEmail.trim();
      if (!trimmedEmail) return;
      if (emails.includes(trimmedEmail)) {
        toast.error(t('component.modal.addAdmin.error.emailAdded'));
        return;
      }
      if (trimmedEmail !== '' && isValidEmail(trimmedEmail)) {
        setEmails([...emails, trimmedEmail]);
        setCurrentEmail('');
      } else {
        toast.error(t('component.modal.addAdmin.error.invalidEmail'));
      }
    } else if (event.key === 'Backspace' && currentEmail === '' && emails.length > 0) {
      const newEmails = [...emails];
      const removedEmail = newEmails.pop();
      setCurrentEmail(removedEmail || '');
      setEmails(newEmails);
    }

    const container = containerRef.current;
    if (container) {
      container.scroll({
        left: container.scrollWidth,
        behavior: 'smooth',
      });
    }
  };

  const handleRemoveEmail = (index: number) => {
    const updatedEmails = emails.filter((_, i) => i !== index);
    setEmails(updatedEmails);
  };

  const handleSubmit = async () => {
    await Promise.all([
      adminService.inviteByUserIds(
        userIds.map((id) => +id),
        AdminRole.OWNER,
      ),
      adminService.sendAdminInvites(emails, AdminRole.OWNER),
    ]);
    toast.success(t('component.modal.addAdmin.sent', { count: userIds.length + emails.length }));
    setOpen(false);
  };

  return (
    <ModalLayout
      open={open}
      setOpen={setOpen}
      closeButton
      className="bg-secondary-50 min-w-[450px] flex flex-col w-fit pb-4 pt-5 mx-6 max-w-[650px] z-50 rounded-[20px] py-6 px-10 gap-[20px]">
      <ModalHeader
        icon={FaPaperPlane}
        title={t('component.modal.addAdmin.title')}
        subtitle={t('component.modal.addAdmin.subtitle')}
      />

      <div className="border-b border-primary-300 w-full" />

      <h2 className="font-serif text-[22px] leading-[24px]">
        {t('component.modal.addAdmin.emailTitle')}
      </h2>
      <div className="flex flex-col w-full">
        <p className="text-sm font-medium ml-3">{t('component.modal.addAdmin.emails')}</p>
        <div className="flex  rounded-full px-4 border border-primary h-10 bg-secondary-50 no-scrollbar md:max-w-full overflow-x-scroll w-full items-center pr-[100px] relative text-gray-900">
          <div
            ref={containerRef}
            className="flex overflow-x-scroll w-full items-center mr-[20px] no-scrollbar">
            {emails.map((email, index) => (
              <div
                key={index}
                className="flex items-center  relative group bg-secondary-200 text-primary-900 text-sm rounded-md mr-1 px-2 py-1">
                {email}
                <div
                  className="ml-1 rounded-r-md bg-secondary-200  items-center flex transition-all shadow-3xl h-full group-hover:opacity-100 opacity-0 right-0 cursor-pointer absolute"
                  onClick={() => handleRemoveEmail(index)}>
                  <AiOutlinePlus className="h-5 w-5 text-primary-900 rotate-45" />
                </div>
              </div>
            ))}
            <input
              className="!px-0 border !rounded-none !border-transparent !w-full !min-w-[200px] h-10  !focus:border-transparent !focus:ring-0"
              type="text"
              value={currentEmail}
              onChange={handleInputChange}
              onKeyDown={handleInputKeyDown}
            />
          </div>
        </div>
        <p className="text-sm flex w-full justify-end px-3">
          {t('component.modal.addAdmin.instruction')}
        </p>
      </div>

      <div className="border-b border-primary-300 w-full" />

      <h2 className="font-serif text-[22px] leading-[24px]">
        {t('component.modal.addAdmin.connectionsTitle')}
      </h2>

      <div className="w-full flex gap-4 items-center justify-center">
        <Button
          onClick={() => setMode('BUSINESS')}
          className={classNames({ 'bg-primary-300': mode === 'BUSINESS' })}>
          {t('component.modal.addAdmin.businessButton')}
        </Button>
        <Button
          onClick={() => setMode('PERSONAL')}
          className={classNames({ 'bg-primary-300': mode === 'PERSONAL' })}>
          {t('component.modal.addAdmin.personalButton')}
        </Button>
      </div>
      <SearchBar search={search} setSearch={setSearch} />
      <ConnectionsList
        selected={userIds}
        setSelected={setUserIds}
        hasSearch={false}
        className="border-none py-0"
        search={search}
        connections={
          mode === 'BUSINESS'
            ? connections
                .filter((c) => c.userId)
                .map(({ userId, fields: { FIRST_NAME, LAST_NAME } }) => ({
                  id: userId,
                  alias: `${FIRST_NAME}+${LAST_NAME}`,
                  disabled: adminIds.includes(userId),
                }))
            : personalConnections.map(({ userId, firstName, lastName }) => ({
                id: userId,
                alias: `${firstName}+${lastName}`,
                disabled: adminIds.includes(userId),
              }))
        }
      />

      <div className="border-b border-primary-300 w-full" />

      <div className="flex flex-col sm:flex-row sm:items-center items-end gap-4 sm:justify-between">
        <p>
          {userIds.length + emails.length} {t('component.modal.addAdmin.selected')}
        </p>
        <CancelSaveButton
          buttonText={t('component.modal.addAdmin.invite')}
          onCancel={() => setOpen(false)}
          disabled={emails.length === 0 && userIds.length === 0}
          onSave={handleSubmit}
        />
      </div>
    </ModalLayout>
  );
}
