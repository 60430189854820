import React from 'react';
import { IconType } from 'react-icons';
import IconBadge from '../icons/IconBadge';

interface ModalHeaderProps {
  icon: IconType;
  title: string;
  subtitle?: string;
}
export default function ModalHeader({ icon, title, subtitle }: ModalHeaderProps): JSX.Element {
  return (
    <div className="flex gap-[10px] ">
      <IconBadge icon={icon} />

      <div className="flex flex-col w-full ">
        <h1 className="text-[28px] leading-[32px] font-serif">{title}</h1>
        <p>{subtitle}</p>
      </div>
    </div>
  );
}
