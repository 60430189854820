import React, { useEffect, useLayoutEffect, useState } from 'react';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import {
  BusinessInvite,
  CustomFieldResponses,
  CustomFieldType,
  CustomMultipleChoiceField,
} from '../../../services/model/inviteService.model';
import { createEmptyBusinessInvite } from '../../../utils/formatUtils';
import { ProfileDataType, ProfileField } from '../../../types/Profile';
import { isCommunity, isMoments } from '../../../constants';
import Button from '../../components/buttons/Button';
import VeraPanel from '../../components/misc/VeraPanel';
import StepIndicator from '../../components/misc/StepIndicator';
import InviteForm from '../../components/forms/InviteForm';
import UserDataForm from '../../components/forms/userData/UserDataForm';
import useSelectedBusiness from '../../../hooks/business/useSelectedBusiness';
import InvitationLink from '../../components/misc/InvitationLink';
import inviteService from '../../../services/inviteService';
import adminService from '../../../services/adminService';
import useNavigateAfterBusinessChange from '../../../hooks/effects/useNavigateAfterBusinessChange';
import { filterCustomLabelFields } from '../../../utils/filterUtils';
import CustomFieldsForm from '../../components/forms/CustomFieldsForm';
import userService from '../../../services/userService';

export default function InviteLinkPage() {
  const location = useLocation();
  const navigate = useNavigate();
  useNavigateAfterBusinessChange();

  useEffect(() => {
    if (!location.state) navigate('/dashboard');
  }, []);

  if (!location.state) return <></>;
  return location.state.view === 'SETUP' ? <InviteLinkSetup /> : <LinkView />;
}

function InviteLinkSetup() {
  const { t } = useTranslation();
  const business = useSelectedBusiness();

  const [invite, setInvite] = useState<BusinessInvite>(createEmptyBusinessInvite());
  const [sharedData, setSharedData] = useState<ProfileField[]>([]);
  const [customFieldResponses, setCustomFieldResponses] = useState<CustomFieldResponses>([]);
  const [step, setStep] = useState<number>(business?.inviteFields ? (isMoments ? 2 : 1) : 0);
  const { message, customFields } = invite;

  const steps = [
    <InviteView invite={invite} setInvite={setInvite} />,
    <SharedDataView
      sharedData={sharedData}
      setSharedData={setSharedData}
      customFieldResponses={customFieldResponses}
      setCustomFieldResponses={setCustomFieldResponses}
      invite={business?.inviteFields}
    />,
    <LinkView goBack={() => setStep(step - 1)} />,
  ].filter(Boolean);

  useEffect(() => {
    if (!business?.inviteFields) return;
    adminService.getSharedData().then(setSharedData);
    inviteService.fetchInvite().then(setInvite);
  }, []);

  useEffect(() => {
    // remove all removed custom fields
    setCustomFieldResponses(
      (business?.inviteFields?.customFields ?? []).reduce((acc, field) => {
        acc[field.customFieldId!] = customFieldResponses[field.customFieldId!] ?? '';
        return acc;
      }, {} as CustomFieldResponses),
    );
  }, [JSON.stringify(business?.inviteFields?.customFields)]);

  const handleSubmit = async (): Promise<void> => {
    if (!message) {
      toast.error(t('toast.error.personalMessage'));
      return;
    }
    const labelFields = filterCustomLabelFields(customFields);
    if (
      labelFields.some((field): boolean => !field.label) ||
      customFields
        .filter((x) => x.type === CustomFieldType.MULTIPLE_CHOICE)
        .map((x) => (x as CustomMultipleChoiceField).options)
        .flat()
        .some((option): boolean => !option)
    ) {
      toast.error(t('toast.error.allFields'));
      return;
    }
    if (
      Array.from(new Set(labelFields.map((field) => field.label))).length !== labelFields.length
    ) {
      toast.error(t('toast.error.noTwoSame'));
      return;
    }

    await Promise.all([
      business?.inviteFields
        ? inviteService.updateInvite(invite)
        : inviteService.createInvite(invite),
      isMoments && adminService.updateSharedData(sharedData.map((f) => +f.id!)),
    ]);
    if (isMoments && !business?.inviteFields) await inviteService.createInviteLink();
    setStep((prev) => prev + 1);
  };

  const handleCommunityDataSave = () => {
    if (!business?.inviteFields) return;
    const { mandatoryFields } = business.inviteFields;
    const mandatoryCustom = business.inviteFields.customFields.filter((f) => f.mandatory);
    if (
      mandatoryCustom.some((f) => !customFieldResponses[f.customFieldId!]) ||
      mandatoryFields.some((f) => !sharedData.find((d) => d.dataType === f))
    ) {
      toast.error(t('toast.error.mandatoryStar'));
      return;
    }
    userService
      .updateBusinessConnection(business.businessId!, {
        propertyIds: sharedData.map((f) => f.id!),
        customFieldResponses,
        dataForMembers: sharedData.map((f) => f.id!),
      })
      .then(() => setStep(step + 1));
  };

  return (
    <>
      <StepIndicator
        steps={steps.length}
        currentStep={step}
        onStepChange={setStep}
        className="mb-6"
      />
      {steps[step]}
      {step < steps.length - 1 && (
        <div className="flex w-full justify-end gap-2 py-4 mt-4 border-t border-primary-300">
          {step > 0 && (
            <Button variant="tertiary" onClick={() => setStep(step - 1)}>
              <FaArrowLeft className="w-5 h-5" />
              {t('general.back')}
            </Button>
          )}
          {isMoments && step === 0 && (
            <Button variant="primary" onClick={() => setStep(step + 1)}>
              {t('general.continue')}
              <FaArrowRight className="w-5 h-5" />
            </Button>
          )}
          {((isMoments && step === 1) || (isCommunity && step === 0)) && (
            <Button variant="primary" onClick={handleSubmit}>
              {business?.inviteFields ? t('general.save') : t('general.create')}
              <FaArrowRight className="w-5 h-5" />
            </Button>
          )}
          {isCommunity && step === 1 && (
            <Button variant="primary" onClick={handleCommunityDataSave}>
              {t('general.save')}
              <FaArrowRight className="w-5 h-5" />
            </Button>
          )}
        </div>
      )}
    </>
  );
}

interface SharedDataViewProps {
  sharedData: ProfileField[];
  setSharedData: (sharedData: ProfileField[]) => void;
  customFieldResponses: CustomFieldResponses;
  setCustomFieldResponses: (customFieldResponses: CustomFieldResponses) => void;
  invite: BusinessInvite | undefined;
}

function SharedDataView({
  sharedData,
  setSharedData,
  customFieldResponses,
  setCustomFieldResponses,
  invite,
}: SharedDataViewProps): JSX.Element {
  const { t } = useTranslation();
  const { customFields, nonRequestedFields, mandatoryFields } = invite ?? {};

  return (
    <VeraPanel
      title={t('page.inviteLink.sharedData.title')}
      subtitle={t('page.inviteLink.sharedData.subtitle')}>
      <UserDataForm
        selectedData={sharedData}
        setSelectedData={setSharedData}
        onlyBirthDate={isMoments || !nonRequestedFields?.includes(ProfileDataType.BIRTHDATE)}
        hasSelectAll={isMoments}
        {...(isCommunity
          ? {
              nonRequestedFields: [
                ...(nonRequestedFields ?? []),
                ProfileDataType.MOMENT,
                ProfileDataType.SOCIAL,
                ProfileDataType.BUSINESSNAME,
              ],
              mandatoryFields,
              onePerDataType: true,
            }
          : {})}
      />
      {isCommunity && (
        <CustomFieldsForm
          includeMandatory
          fields={customFields ?? []}
          response={customFieldResponses}
          setResponse={setCustomFieldResponses}
        />
      )}
    </VeraPanel>
  );
}

interface InviteViewProps {
  invite: BusinessInvite;
  setInvite: (invite: BusinessInvite) => void;
}

function InviteView({ invite, setInvite }: InviteViewProps): JSX.Element {
  const { t } = useTranslation();
  return (
    <VeraPanel
      title={t('page.inviteLink.invite.title')}
      subtitle={t('page.inviteLink.invite.subtitle')}>
      <InviteForm invite={invite} setInvite={setInvite} />
    </VeraPanel>
  );
}

interface LinkViewProps {
  goBack?: () => void;
}

function LinkView({ goBack }: LinkViewProps) {
  const business = useSelectedBusiness();
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    if (isMoments && !business?.inviteFields?.friendlyId) inviteService.createInviteLink();
  }, []);

  return (
    <>
      <div className="mx-auto h-full flex-1 my-auto flex w-full md:w-[70%] md:flex-row flex-col items-center gap-2 md:gap-0 justify-center ">
        <img
          alt="Vera"
          src={`${process.env.PUBLIC_URL}/assets/figures/party_share.svg`}
          className="w-[40%] "
        />
        <div className="flex flex-col w-full gap-2">
          <h1 className="text-3xl font-serif font-semibold">{t('page.inviteLink.title')}</h1>
          <InvitationLink />
        </div>
      </div>
      <div className="flex w-full justify-end gap-2 py-4 mt-4 border-t border-primary-300">
        {goBack && (
          <Button variant="tertiary" onClick={goBack}>
            <FaArrowLeft className="w-5 h-5" />
            {t('general.back')}
          </Button>
        )}
        <Button variant="primary" onClick={() => navigate('/dashboard')}>
          {t('page.inviteLink.toDashboard')}
          <FaArrowRight className="w-5 h-5" />
        </Button>
      </div>
    </>
  );
}
