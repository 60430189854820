import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import Joyride, { CallBackProps, Step } from 'react-joyride';
import { twMerge } from 'tailwind-merge';
import useBusinessTranslation from '../../../hooks/useBusinessTranslation';
import { setOnboarding } from '../../../redux/slices/applicationSlice';
import { COLOR_PRIMARY_300, COLOR_PRIMARY_900, isCommunity } from '../../../constants';
import { RootState } from '../../../redux/reducers';
import adminService from '../../../services/adminService';

export default function OnboardingRide() {
  const dispatch = useDispatch();
  const { t } = useBusinessTranslation();
  const location = useLocation();
  const [step, setStep] = React.useState<number | undefined>(undefined);
  const { onboardingStep } = useSelector((state: RootState) => state.application);

  useEffect(() => {
    setTimeout(() => setStep(onboardingStep), 50);
  }, [onboardingStep]);

  useEffect(() => {
    dispatch(setOnboarding(undefined));
  }, [location.pathname]);

  const handleJoyRide = (e: CallBackProps) => {
    if (e.action === 'reset' || e.action === 'close' || e.action === 'stop') {
      dispatch(setOnboarding(undefined));
    } else if (e.action === 'next' && e.type === 'tour:end') {
      adminService.setFinishedOnboarding();
    } else if (e.action === 'next' && e.type === 'step:after') dispatch(setOnboarding(e.index + 1));
  };

  const steps = [
    getStep({
      target: '#root',
      title: t('onboarding.init.title'),
      subtitle: t('onboarding.init.subtitle'),
      img: 'assets/figures/phone.svg',
      placement: 'center',
    }),
    getStep({
      target: 'div:not(.hidden) > #overview',
      title: t('onboarding.overview.title'),
      subtitle: t('onboarding.overview.subtitle'),
      img: 'assets/figures/heart.svg',
      imgClassName: '-bottom-24',
    }),
    getStep({
      target: 'div:not(.hidden) > #requested-data',
      title: t('onboarding.requested.title'),
      subtitle: t('onboarding.requested.subtitle'),
      img: 'assets/figures/question.svg',
      imgClassName: 'w-[80px] -bottom-32 -right-10',
    }),
    getStep({
      target: '#communication',
      title: t('onboarding.communication.title'),
      subtitle: t('onboarding.communication.subtitle'),
      img: 'assets/figures/phone.svg',
    }),
    isCommunity &&
      getStep({
        target: '#events',
        title: t('onboarding.events.title'),
        subtitle: t('onboarding.events.subtitle'),
        img: 'assets/figures/agenda.svg',
      }),
    getStep({
      target: '#settings',
      title: t('onboarding.settings.title'),
      subtitle: t('onboarding.settings.subtitle'),
      img: 'assets/figures/mess.svg',
      imgClassName: '-bottom-32 w-[140px]',
    }),
    getStep({
      target: '#invite-button',
      title: t('onboarding.invite.title'),
      subtitle: t('onboarding.invite.subtitle'),
      img: 'assets/figures/phone.svg',
      placement: 'left',
      imgClassName: '-bottom-36',
      spotlightClicks: true,
    }),
  ].filter(Boolean) as Step[];

  if (step === undefined) return null;

  return (
    <Joyride
      callback={handleJoyRide}
      steps={steps}
      hideCloseButton
      stepIndex={step}
      continuous
      hideBackButton
      disableOverlayClose
      disableScrollParentFix
      showProgress
      locale={{
        next: t('general.next'),
        skip: t('onboarding.skip'),
        last: t('onboarding.last'),
      }}
      run={step !== undefined && !isMobile}
      floaterProps={{ styles: { arrow: { color: COLOR_PRIMARY_300 } } }}
      styles={{
        overlay: { opacity: 0 },
        tooltip: {
          backgroundColor: COLOR_PRIMARY_300,
          borderRadius: '20px',
          color: COLOR_PRIMARY_900,
        },
      }}
    />
  );
}

type StepProps = {
  title: string;
  subtitle: string;
  img: string;
  imgClassName?: string;
} & Omit<Step, 'content' | 'disableBeacon'>;

function getStep({
  title,
  subtitle,
  img,
  imgClassName = '',
  placement = 'right',
  ...props
}: StepProps): Step {
  return {
    content: (
      <div
        id="onboarding-conainer"
        className="flex flex-col gap-2 text-start items-start w-full relative h-full flex-1">
        <h1 className="font-serif text-[32px] leading-[32px]">{title}</h1>
        <p>{subtitle}</p>
        <img
          src={img}
          className={twMerge('absolute -right-16 -bottom-32 w-[120px]', imgClassName)}
          alt="Vera"
        />
      </div>
    ),
    disableBeacon: true,
    placement,
    ...props,
  };
}
