import { FaEnvelope, FaPlusCircle, FaSms, FaTag, FaUserPlus } from 'react-icons/fa';
import { FaLink, FaTicket, FaFileImport } from 'react-icons/fa6';
import { BiXCircle } from 'react-icons/bi';
import { FiMail } from 'react-icons/fi';
import { HiTrash } from 'react-icons/hi';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import ConnectionsTable from '../tables/ConnectionsTable';
import SearchBar from '../forms/SearchBar';
import { Connection, ConnectionType } from '../../../redux/slices/connectionsSlice';
import WarningModal, { SimpleWarningModalProps } from '../modals/WarningModal';
import TagButton from '../buttons/TagButton';
import LocalContactModal from '../modals/LocalContactModal';
import { RootState } from '../../../redux/reducers';
import useSelectedBusiness from '../../../hooks/business/useSelectedBusiness';
import { deleteConnections } from '../../../utils/connectionUtils';
import Button from '../buttons/Button';
import OptionsButton from '../buttons/OptionsButton';
import { OnboardingStep, OptionsButtonOption } from '../../../types/misc';
import useBusinessTranslation from '../../../hooks/useBusinessTranslation';
import { isMoments } from '../../../constants';
import PersonalMomentConnectionModal from '../modals/PersonalMomentConnectionModal';
import usePersonalConnections from '../../../hooks/account/usePersonalConnections';
import { PendingBadge, UnverifiedBadge, VerifiedBadge } from '../icons/ConnectionTypeBadge';
import LocalContactInviteModal from '../modals/LocalContactInviteModal';
import QuestionCircle from '../misc/QuestionCircle';
import adminService from '../../../services/adminService';
import InviteConnectionModal from '../modals/InviteConnectionModal';

/**
 * A panel component that displays a list of clients and a searchbar.
 */
export default function ClientListPanel(): JSX.Element {
  const connections = useSelector((state: RootState) => state.connections);
  const personalConnections = usePersonalConnections();
  const tags = useSelector((state: RootState) => state.tags);
  const localContacts = useSelector((state: RootState) => state.connections).filter(
    (c: Connection) => c.type === ConnectionType.LOCAL_CONTACT && c.fields.EMAIL && !c.inviteSent,
  );
  const { onboardingStep } = useSelector((state: RootState) => state.application);
  const { t } = useBusinessTranslation();

  const [search, setSearch] = useState('');
  const [selectedConnections, setSelectedConnections] = useState<Connection[]>([]);
  const [filterTags, setFilterTags] = useState<string[]>([]);
  const [showPopUp, setShowPopUp] = useState(false);
  const [isMobile, setMobile] = React.useState<boolean>(window.innerWidth < 1024);
  const [addLocalContactModalOpen, setAddLocalContactModalOpen] = React.useState(false);
  const [inviteConnectionModalOpen, setInviteConnectionModalOpen] = React.useState(false);

  const [inviteMomentModalOpen, setInviteMomentModalOpen] = React.useState(false);
  const [localContactInviteModalOpen, setLocalContactInviteModalOpen] = React.useState(false);

  const business = useSelectedBusiness();

  const [warningModal, setWarningModal] = React.useState<SimpleWarningModalProps>({
    open: false,
    title: '',
    description: '',
    button: '',
    cancelClassName: 'hidden',
    onClose: () => {},
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    setSelectedConnections(
      selectedConnections
        .map((x) => connections.find((y) => y.id === x.id && y.type === x.type))
        .filter((x) => x) as Connection[],
    );
  }, [connections]);

  useEffect(() => {
    setFilterTags(
      filterTags.filter((tag): boolean => tags.map((x): string => x.tagId).includes(tag)),
    );
  }, [tags]);

  const selectedConnectionsWithAddresses = selectedConnections.filter(
    (x): boolean => (x.fields as any).ADDRESS,
  );
  const selectedConnectionsWithEmails = selectedConnections.filter(
    (x): boolean => (x.fields as any).EMAIL,
  );
  const selectedConnectionsWithPhonenumbers = selectedConnections.filter(
    (x): boolean => (x.fields as any).PHONENUMBER,
  );

  useEffect((): void => {
    window.addEventListener('resize', (): void => setMobile(window.innerWidth < 1024));
  }, []);

  const filterSearch = (item: any): boolean => {
    return (
      (item.fields.FIRST_NAME.toLowerCase().includes(search.toLowerCase()) ||
        item.fields.LAST_NAME.toLowerCase().includes(search.toLowerCase())) &&
      (!filterTags.length ||
        filterTags.every((filterTag): boolean => item.tags.includes(filterTag)))
    );
  };

  /**
   * Extracts the headers from the data, makes the spaced from camelcase.
   *
   * @param {any[]} data - The data to extract the headers from.
   * @returns {string[]} - The headers.
   */
  const extractHeaders = (data: any[]): string[] => {
    if (data.length === 0) {
      return []; // If data array is empty, return empty array
    }

    const headersSet = data.reduce((acc, curr) => {
      const fields = Object.keys(curr.fields);
      fields.forEach((field) => acc.add(field));
      return acc;
    }, new Set<string>(Object.keys(data[0].fields)));

    return Array.from(headersSet);
  };

  const handleCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    connection: Connection,
  ): void => {
    const { checked } = event.target;
    if (checked) {
      setSelectedConnections([...selectedConnections, connection]);
      setShowPopUp(true);
    } else {
      setSelectedConnections(selectedConnections.filter((c): boolean => connection !== c));
    }
  };

  const handleFilterTagClick = (id: string): void => {
    if (!filterTags.includes(id)) {
      setFilterTags([...filterTags, id]);
    } else {
      setFilterTags(filterTags.filter((tag): boolean => id !== tag));
    }
  };

  const selectAllClick = (selectAll: boolean): void => {
    if (!selectAll) {
      setSelectedConnections([]);
    } else {
      setShowPopUp(true);
      setSelectedConnections(connections.filter(filterSearch));
    }
  };

  useLayoutEffect((): void => {
    if (selectedConnections.length === 0) {
      setShowPopUp(false);
    }
  }, [selectedConnections]);

  const handleDelete = async (): Promise<void> => {
    const promise = deleteConnections(selectedConnections.map(({ id, type }) => ({ id, type })));

    await toast.promise(promise, {
      pending: 'Deleting connections...',
      success: 'Connections deleted!',
      error: 'Failed to delete connections',
    });
    setSelectedConnections([]);
  };

  const navigateToAddressLabelPage = (): void => {
    navigate('/payment/address-label', {
      state: {
        connections: selectedConnectionsWithAddresses,
        connectionsTotal: connections.length,
      },
    });
  };

  const navigateToPostcardPage = (): void => {
    navigate('/payment/postcard', {
      state: {
        connections: selectedConnectionsWithAddresses,
        connectionsTotal: connections.length,
      },
    });
  };

  const navigateToEmailPage = (): void => {
    navigate('/communication/email', {
      state: { connections: selectedConnectionsWithEmails },
    });
  };

  const navigateToSmsPage = (): void => {
    navigate('/communication/sms', {
      state: { connections: selectedConnectionsWithPhonenumbers },
    });
  };

  const handleAddressLabels = (): void => {
    if (selectedConnectionsWithAddresses.length === 0)
      setWarningModal({
        title: t('warning.selection.addressLabel'),
        description: t('warning.addressMissing.allSelected'),
        button: t('general.close'),
        cancelClassName: 'hidden',
        onClose: () => {},
        open: true,
      });
    else if (selectedConnections.length !== selectedConnectionsWithAddresses.length)
      setWarningModal({
        title: t('warning.selection.addressLabel'),
        description: t('warning.addressMissing.some'),
        button: t('general.continue'),
        cancelClassName: 'hidden',
        onClose: navigateToAddressLabelPage,
        open: true,
      });
    else navigateToAddressLabelPage();
  };

  const handlePostcards = (): void => {
    if (selectedConnectionsWithAddresses.length === 0)
      setWarningModal({
        title: t('warning.selection.card'),
        description: t('warning.addressMissing.allSelected'),
        button: t('general.close'),
        cancelClassName: 'hidden',
        onClose: () => {},
        open: true,
      });
    else if (selectedConnections.length !== selectedConnectionsWithAddresses.length)
      setWarningModal({
        title: t('warning.selection.card'),
        description: t('warning.addressMissing.some'),
        button: t('general.continue'),
        onClose: navigateToPostcardPage,
        open: true,
      });
    else navigateToPostcardPage();
  };

  const handleEmail = (): void => {
    if (selectedConnectionsWithEmails.length === 0) {
      setWarningModal({
        title: t('warning.selection.email'),
        description: t('warning.emailMissing.allSelected'),
        button: t('general.close'),
        open: true,
        onClose: (): void => {},
      });
    } else if (selectedConnectionsWithEmails.length < selectedConnections.length) {
      setWarningModal({
        title: t('warning.selection.email'),
        description: t('warning.emailMissing.some'),
        button: t('general.continue'),
        open: true,
        onClose: navigateToEmailPage,
      });
    } else navigateToEmailPage();
  };

  const handleSendSMS = (): void => {
    if (selectedConnectionsWithPhonenumbers.length === 0) {
      setWarningModal({
        title: t('warning.selection.sms'),
        description: t('warning.phoneNumberMissing.allSelected'),
        button: t('general.close'),
        cancelClassName: 'hidden',
        open: true,
        onClose: (): void => {},
      });
    } else if (selectedConnectionsWithPhonenumbers.length < selectedConnections.length) {
      setWarningModal({
        title: t('warning.selection.sms'),
        description: t('warning.phoneNumberMissing.some'),
        button: t('general.continue'),
        open: true,
        onClose: navigateToSmsPage,
      });
    } else navigateToSmsPage();
  };

  const options = [
    {
      icon: FaPlusCircle,
      name: t('page.dashboard.clientList.addContact.manual.title'),
      description: t('page.dashboard.clientList.addContact.manual.subtitle'),
      onClick: () => setAddLocalContactModalOpen(true),
    },
    {
      icon: FaLink,
      name: t('page.dashboard.clientList.addContact.link.title'),
      description: t('page.dashboard.clientList.addContact.link.subtitle'),
      onClick: () => navigate('/invite-link', { state: { view: 'VIEW' } }),
    },

    isMoments &&
    personalConnections.filter((c) => !connections.map((con) => con.userId).includes(c.userId))
      .length
      ? {
          icon: FaUserPlus,
          name: t('page.dashboard.clientList.addContact.moment.title'),
          description: t('page.dashboard.clientList.addContact.moment.subtitle'),
          onClick: () => setInviteMomentModalOpen(true),
        }
      : {
          icon: FaUserPlus,
          name: t('page.dashboard.clientList.addContact.connection.title'),
          description: t('page.dashboard.clientList.addContact.connection.subtitle'),
          onClick: () => setInviteConnectionModalOpen(true),
        },
  ].filter(Boolean) as OptionsButtonOption[];

  return (
    <>
      <div className="flex flex-col lg:flex-row justify-between gap-4">
        <div
          className={classNames('mb-4 flex flex-col', {
            'blur-sm opacity-80': onboardingStep !== undefined,
          })}>
          <h1 className="text-3xl font-semibold font-serif">
            {t('page.dashboard.clientList.title')}
          </h1>
          <p className="text-lg font-semibold py-1">{`${connections.length} ${t(
            'general.total',
          )}`}</p>
          <div className="flex gap-4 mb-5 md:flex-row md:items-center flex-col">
            <div className="flex gap-1 items-center">
              <VerifiedBadge />
              {
                connections.filter((c) =>
                  [
                    ConnectionType.B2C_CONNECTION,
                    ConnectionType.PERSONAL_MOMENT_CONNECTION,
                  ].includes(c.type),
                ).length
              }{' '}
              {t('component.verifiedDisplay.verified')}
            </div>
            <div className="flex gap-1 items-center">
              <PendingBadge />
              {
                connections.filter((c) => c.type === ConnectionType.LOCAL_CONTACT && c.inviteSent)
                  .length
              }{' '}
              {t('component.verifiedDisplay.pending')}
            </div>
            <div className="flex gap-1 items-center">
              <UnverifiedBadge />
              {
                connections.filter((c) => c.type === ConnectionType.LOCAL_CONTACT && !c.inviteSent)
                  .length
              }{' '}
              {t('component.verifiedDisplay.unverified')}
              <QuestionCircle
                className="md:-top-1 md:left-[150%] -left-[800%] bottom-[150%] flex flex-col gap-2 px-4 py-3 rounded-[6px] w-[350px] cursor-pointer"
                arrowClassName="hidden md:block rotate-45 top-2 -left-1">
                {t('component.verifiedDisplay.tooltip')}
                {localContacts.length > 0 && (
                  <Button variant="secondary" onClick={() => setLocalContactInviteModalOpen(true)}>
                    {t('component.verifiedDisplay.tooltipButton')}
                  </Button>
                )}
              </QuestionCircle>
            </div>
          </div>
          <SearchBar search={search} setSearch={setSearch} className="md:w-80 lg:w-96" />
        </div>
        {(onboardingStep !== undefined || !business?.inviteFields) && (
          <Link to="/invite-link" state={{ view: business?.inviteFields ? 'VIEW' : 'SETUP' }}>
            <Button
              id="invite-button"
              variant="primary"
              className={classNames({
                'shadow-[0_0_16.2px_2px_#92A2EC] border-primary-300 border-2':
                  onboardingStep === OnboardingStep.INVITE,
                'blur-sm opacity-80': onboardingStep && onboardingStep !== OnboardingStep.INVITE,
              })}
              onClick={adminService.setFinishedOnboarding}>
              <FaUserPlus className="h-5 w-5" />
              {t('page.dashboard.clientList.addContact.title')}
            </Button>
          </Link>
        )}
        {business?.inviteFields && onboardingStep === undefined && (
          <OptionsButton options={options} containerClassName="left-0 sm:right-0 sm:left-auto">
            <Button variant="primary">
              <FaUserPlus className="h-5 w-5" />
              {t('page.dashboard.clientList.addContact.title')}
            </Button>
          </OptionsButton>
        )}
      </div>
      <div
        className={classNames(' flex flex-col w-full overflow-x-scroll h-fit', {
          'blur-sm opacity-80': onboardingStep !== undefined,
        })}>
        <ConnectionsTable
          headers={extractHeaders(
            connections.length > 0
              ? connections
              : [
                  {
                    fields: {
                      Naam: 'Default',
                      Telefoonnummer: 'Default',
                      Emailadres: 'Default',
                      Adres: 'Default',
                    },
                  },
                ],
          )}
          data={connections.filter(filterSearch)}
          tags={tags}
          filteredTags={filterTags}
          onTagClick={handleFilterTagClick}
          handleCheckboxChange={handleCheckboxChange}
          selectAllClick={selectAllClick}
          selectedConnections={selectedConnections}
        />
      </div>

      {showPopUp && (
        <div className="flex flex-col sm:flex-row items-center justify-center rounded-xl popup-bar mb-2 bg-secondary-200 h-fit-content mx-auto py-3 shadow-xl border-primary-900 border w-fit">
          <div className="flex flex-wrap sm:flex-nowrap items-center flex-col lg:flex-row">
            <div className="border-b pb-2 border-gray-400 w-full items-center flex justify-between mb-2 lg:border-0 lg:pb-0 lg:w-fit lg:mb-0">
              <span className="px-4 inline-block  border-primary-900">{`${selectedConnections.length} geselecteerd`}</span>
              {isMobile && (
                <button
                  type="button"
                  data-testid="small-x-button"
                  className="ml-4 font-semibold items-center"
                  onClick={(): void => setShowPopUp(false)}>
                  <BiXCircle className="w-5 h-5 mr-2 icon" />
                </button>
              )}
            </div>
            <div className="grid grid-cols-2 lg:flex lg:flex-row lg:border-x border-black sm:grid-cols-3 gap-2 px-4">
              <button
                onClick={handleSendSMS}
                type="button"
                className="flex font-semibold items-center text-sm">
                <FaSms className="w-5 h-5 mr-2 icon" />
                <span className="whitespace-nowrap inline">
                  {t('page.dashboard.quickAction.sendSms')}
                </span>
              </button>
              <button
                onClick={handleEmail}
                type="button"
                className=" flex font-semibold items-center text-sm">
                <FiMail className="w-5 h-5 mr-2 icon" />
                <span className="whitespace-nowrap inline">
                  {t('page.dashboard.quickAction.sendEmail')}
                </span>
              </button>
              <button
                type="button"
                data-testid="postcard-button"
                className="flex font-semibold items-center text-sm"
                onClick={handlePostcards}>
                <FaEnvelope className="w-5 h-5 mr-2 icon" />
                <span className="whitespace-nowrap inline">
                  {t('page.dashboard.quickAction.sendCard')}
                </span>
              </button>
              <button
                type="button"
                data-testid="address-label-button"
                className="flex font-semibold items-center text-sm"
                onClick={handleAddressLabels}>
                <FaTicket className="w-5 h-5 mr-2" />
                <span className="whitespace-nowrap inline">
                  {t('page.dashboard.quickAction.orderAddressLabels')}
                </span>
              </button>
              <TagButton connections={selectedConnections}>
                <div className=" flex font-semibold lg:mt-2 items-center text-sm">
                  <FaTag className="w-5 h-5 mr-2 icon" />
                  <span className="whitespace-nowrap inlines">
                    {t('page.dashboard.quickAction.addTag')}
                  </span>
                </div>
              </TagButton>
              <button
                onClick={(): void =>
                  setWarningModal({
                    title: t('warning.deleteConnections.title'),
                    description: t('warning.deleteConnections.description'),
                    button: t('warning.deleteConnections.button'),
                    onClose: handleDelete,
                    open: true,
                    saveClassName: 'bg-red-500',
                  })
                }
                type="button"
                data-testid="delete-connections"
                className="flex font-semibold items-center text-sm lg:mt-0 -mt-2 text-red-500">
                <HiTrash className="w-5 h-5 mr-2 icon" />
                <span className="whitespace-nowrap inline">
                  {t('page.dashboard.quickAction.delete')}
                </span>
              </button>
            </div>
            {!isMobile && (
              <button
                type="button"
                data-testid="x-button"
                className="ml-4 flex font-semibold items-center"
                onClick={(): void => setShowPopUp(false)}>
                <BiXCircle className="w-5 h-5 mr-2 icon" />
              </button>
            )}
          </div>
        </div>
      )}
      <WarningModal
        {...warningModal!}
        setOpen={(open: boolean): void => setWarningModal({ ...warningModal, open })}
      />
      {localContacts.length > 0 && (
        <LocalContactInviteModal
          open={localContactInviteModalOpen}
          setOpen={setLocalContactInviteModalOpen}
        />
      )}
      {business?.inviteFields && (
        <LocalContactModal open={addLocalContactModalOpen} setOpen={setAddLocalContactModalOpen} />
      )}
      {isMoments ? (
        <PersonalMomentConnectionModal
          open={inviteMomentModalOpen}
          setOpen={setInviteMomentModalOpen}
        />
      ) : (
        <InviteConnectionModal
          open={inviteConnectionModalOpen}
          setOpen={setInviteConnectionModalOpen}
        />
      )}
    </>
  );
}
