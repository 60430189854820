import React, { useState } from 'react';
import { FaChevronDown } from 'react-icons/fa6';

export interface FaqDropdownProps {
  question: string;
  answer: string;
}
export default function FaqDropdown({ question, answer }: FaqDropdownProps): JSX.Element {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <div
      key={question}
      className="w-full border border-secondary-200 bg-secondary-50 cursor-pointer shadow-lg rounded-xl px-6 py-4 pb-4 overflow-hidden transition-max-h duration-500 ease-in"
      onClick={() => setOpen(!open)}>
      <div className="flex justify-between ">
        <h3 className="text-lg font-bold">{question}</h3>
        <div className="mt-2 ml-4 w-fit">
          <FaChevronDown
            className={`w-4 h-4 transform transition-transform duration-300 ${
              open ? 'rotate-180' : ''
            }`}
          />
        </div>
      </div>
      <p
        className={`text-sm  overflow-hidden transition-[max-height]  ${
          open
            ? 'max-h-[2000px] ease-[ease-in-out] duration-1000'
            : 'max-h-0 ease-[cubic-bezier(0,1,0,1)] duration-500'
        } `}>
        {answer}
      </p>
    </div>
  );
}
