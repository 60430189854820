import React, { ComponentProps } from 'react';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import {
  COLOR_PRIMARY,
  COLOR_PRIMARY_300,
  COLOR_PRIMARY_900,
  COLOR_SECONDARY_50,
  GOOGLE_PLACES_API_KEY,
} from '../../../constants';
import httpService from '../../../services/httpService';

type AutoCompleteLocationInputProps = {
  location: string;
  setLocation: (location: { location: string; locationUrl: string | undefined }) => void;
} & ComponentProps<typeof GooglePlacesAutocomplete>;

export default function AutoCompleteLocationInput({
  location,
  setLocation,
  ...props
}: AutoCompleteLocationInputProps) {
  const handleClick = (name: string | undefined, placeId: string | undefined) => {
    if (!placeId) {
      setLocation({ location: name || '', locationUrl: undefined });
      return;
    }
    httpService
      .get(`/location/${placeId}`)
      .then(({ data }) => {
        setLocation({ location: name || '', locationUrl: data.result.url ?? undefined });
      })
      .catch(() => {
        setLocation({ location: name || '', locationUrl: undefined });
      });
  };

  return (
    <GooglePlacesAutocomplete
      apiKey={GOOGLE_PLACES_API_KEY}
      debounce={300}
      minLengthAutocomplete={3}
      selectProps={{
        inputId: 'location-input',
        inputValue: location,
        onInputChange: (l, a) => {
          if (a.action !== 'input-change') return;
          setLocation({ location: l, locationUrl: undefined });
        },
        onChange: (value) => handleClick(value?.label, value?.value.place_id),
        className: 'w-full',
        components: { DropdownIndicator: () => null, IndicatorSeparator: () => null },
        placeholder: location,
        styles: {
          control: (baseStyles: any, state: any) => ({
            ...baseStyles,
            borderColor: state.isFocused ? COLOR_PRIMARY_300 : COLOR_PRIMARY,
            borderRadius: '9999px',
            color: COLOR_PRIMARY_900,
            backgroundColor: COLOR_SECONDARY_50,
            boxShadow: 'none',
            height: '40px',
            '&:hover': {
              borderColor: state.isFocused ? COLOR_PRIMARY_300 : COLOR_PRIMARY,
            },
          }),
          option: (baseStyles: any, { isSelected }) => {
            return {
              ...baseStyles,
              backgroundColor: isSelected ? COLOR_PRIMARY : COLOR_SECONDARY_50,
              color: isSelected ? COLOR_SECONDARY_50 : COLOR_PRIMARY_900,
              '&:hover': {
                backgroundColor: isSelected ? COLOR_PRIMARY_300 : COLOR_PRIMARY_300,
                color: COLOR_PRIMARY_900,
              },
              minHeight: '40px',
              height: 'fit-content',
            };
          },
        },
      }}
      {...props}
    />
  );
}
