import React, { useEffect, useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { StripeElementsOptions, loadStripe } from '@stripe/stripe-js';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import CheckoutForm from '../../components/forms/CheckoutForm';
import { Address, ProfileDataType } from '../../../types/Profile';
import orderService from '../../../services/orderService';
import AddressPicker from '../../components/forms/AddressPicker';
import { RootState } from '../../../redux/reducers';
import ProfileModal from '../../components/modals/ProfileModal';
import { Connection } from '../../../redux/slices/connectionsSlice';
import { capitalizeFirstLetter } from '../../../utils/stringUtils';
import ChangeSelectionModal from '../../components/modals/ChangeSelectionModal';
import { ConnectionIdentifier, Discount } from '../../../types/misc';
import {
  filterB2CIds,
  filterLocalContactIds,
  filterPersonalMomentConnectionIds,
} from '../../../utils/connectionUtils';
import useProfileFields from '../../../hooks/account/useProfileFields';
import DiscountInput from '../../components/misc/DiscountInput';
import useNavigateAfterBusinessChange from '../../../hooks/effects/useNavigateAfterBusinessChange';

const STRIPE_KEY: string =
  process.env.NODE_ENV === 'development'
    ? 'pk_test_51L3FClIa3xVgOWHI4HnJjRmYWMe6aLTLQMc3TlK7iEu4qvQwDTCF52UIeSIYkbTJjn50ZBM0CVJomsKLfXFWYYXw00fFxrK58D'
    : 'pk_live_51L3FClIa3xVgOWHIvrqdlBQcO3b07IRorN4RNYAOnNm1dfhEurexzXueTAIhdvuxZi1Sb5HElmXxU13cZMQU941M00riqzSlib';

const stripePromise = loadStripe(STRIPE_KEY);

export default function AddressLabelPage(): JSX.Element {
  useNavigateAfterBusinessChange();
  const location = useLocation();
  const { t } = useTranslation();
  const connectionsTotal = useSelector((state: RootState) => state.connections).length;

  const [connections, setConnections] = useState<ConnectionIdentifier[]>(
    location?.state?.connections.map(({ id, type }: Connection) => ({ id, type })) || [],
  );
  const [numSheets, setNumSheets] = useState<number>(1);
  const [clientSecret, setClientSecret] = useState<string | undefined>(
    location?.state?.clientSecret,
  );
  const [selectionOpen, setSelectionOpen] = useState<boolean>(false);
  const [selectedAddress, setSelectedAddress] = useState<Address | null>(null);
  const [disabledForm, setDisabledForm] = useState<boolean>(true);
  const [showProfileModal, setShowProfileModal] = useState<boolean>(false);
  const [discount, setDiscount] = useState<Discount | undefined>(undefined);

  const numLabels = numSheets * connections.length;
  const addresses = useProfileFields().filter(
    (field) => field.dataType === ProfileDataType.ADDRESS,
  );

  useEffect((): void => {
    const fetchClientSecret = async (): Promise<void> => {
      if (clientSecret) return;
      const response = await orderService.createPaymentIntent(600);
      setClientSecret(response);
    };
    fetchClientSecret();
  }, []);

  useEffect((): void => {
    if (numLabels === 0 || !selectedAddress) setDisabledForm(true);
    else setDisabledForm(false);
  }, [numLabels, selectedAddress]);

  const options = {
    appearance: {
      theme: 'stripe',
    },
    clientSecret,
  } as StripeElementsOptions;

  const labelPrice = 15 * numLabels;

  const paymentOverview = (
    <>
      {' '}
      <h2 className="mb-2  md:text-3xl md:font-bold text-3xl font-serif font-semibold">
        {t('page.payment.overview')}
      </h2>
      <div className="">
        <p>{t('page.payment.addressLabel.totalCost')}</p>
        <div className="border-y border-primary-900 py-4 my-4 text-gray-500 font-medium">
          <p>
            {Number.isNaN(numSheets) ? '-' : numLabels}{' '}
            {t('page.payment.addressLabel.label', { count: numLabels })}
          </p>
          <div className="justify-between flex ml-6">
            <p>{t('page.payment.addressLabel.fixedPrice')}</p>
            <p>€6.00</p>
          </div>
          <div className="justify-between flex ml-6">
            <p>
              {Number.isNaN(numSheets) ? '-' : numLabels}{' '}
              {t('page.payment.addressLabel.label', { count: numLabels })}
            </p>
            <p>€{Number.isNaN(numSheets) ? '-' : (labelPrice / 100).toFixed(2)}</p>
          </div>
          <div className="justify-between flex text-primary pt-2">
            <p>{t('page.payment.discount')}</p>
            <p>
              €
              {Number.isNaN(numSheets)
                ? '-'
                : (((600 + labelPrice) * (discount?.amount ?? 0)) / 100).toFixed(2)}
            </p>
          </div>
          <DiscountInput className=" mt-4" discount={discount} setDiscount={setDiscount} />
        </div>
        <div className="justify-between flex font-medium text-lg">
          <p>{capitalizeFirstLetter(t('general.total'))}</p>
          <p>
            €
            {Number.isNaN(numSheets)
              ? '-'
              : (((600 + labelPrice) * (1 - (discount?.amount ?? 0))) / 100).toFixed(2)}
          </p>
        </div>
      </div>
    </>
  );

  return (
    <>
      <div className=" mx-auto grid grid-cols-1 md:grid-cols-8  gap-6">
        <div className="grid grid-cols-1 gap-6 md:col-span-5">
          {/* Information */}
          <div data-testid="address-label-info" className="bg-secondary-200 rounded-2xl p-4 h-fit">
            <h2 className="mb-2 pl-2 text-3xl font-semibold font-serif">
              {t('page.payment.addressLabel.title')}
            </h2>
            <div className="pl-2">
              <p>{t('page.payment.addressLabel.subtitle')}</p>
              <ul className="list-disc list-inside mt-4">
                <li>{t('page.payment.addressLabel.fixedPricePerOrder')}</li>
                <li>{t('page.payment.addressLabel.pricePerLabel')}</li>
              </ul>
            </div>
          </div>

          {/* Payment selection */}
          <div data-testid="address-label-selection" className=" rounded-2xl h-fit">
            <div className="flex justify-between mb-2 pl-2 ">
              <span className="flex">
                <p className="text-primary font-semibold">{connections.length}</p>/
                {connectionsTotal}
                {t('page.payment.selected')}
              </span>
              <p
                data-testid="edit-selection"
                onClick={(): void => setSelectionOpen(true)}
                className="underline cursor-pointer">
                {t('page.payment.editSelection')}
              </p>
            </div>

            <div className="border-y border-secondary-200 py-6 my-6 mb-2 pl-2 flex flex-col sm:flex-row">
              {/* example address label */}
              <div className="pr-4 sm:w-1/2">
                <h2 className="text-2xl font-semibold mb-2">
                  {t('page.payment.addressLabel.yourLabels')}
                </h2>
                <p className="mb-2">{t('page.payment.addressLabel.exampleLabel')}</p>
                <div className="rounded-lg shadow-md border bg-secondary-200 border-secondary-200 w-full p-4 min-w-[220px]">
                  <p className="font-semibold">John Doe</p>
                  <p>Sesame Street 33</p>
                  <p>9999 London</p>
                  <p>United Kingdom</p>
                </div>
              </div>

              {/* Choosing number of address labels */}
              <div className="w-1/2">
                <p className="mb-2 mt-4 sm:mt-10">
                  {t('page.payment.addressLabel.numberOfLabels')}
                </p>
                <p className="text-sm text-primary-900">
                  {t('page.payment.addressLabel.totalLabelsPerContact')}
                </p>
                <div className="flex w-full items-center">
                  <div className="relative mb-3" data-te-input-wrapper-init>
                    <input
                      type="number"
                      data-testid="num-sheets-input"
                      className="border-primary-900 bg-secondary-50 text-primary-900 w-20 mt-2 rounded-lg"
                      id="exampleFormControlInputNumber"
                      onChange={(e): void => {
                        setNumSheets(Math.max(1, parseInt(e.target.value, 10)));
                      }}
                      value={numSheets}
                      min="1"
                    />
                  </div>
                  <p className="px-2 text-sm text-primary-900">x {connections.length}</p>
                  {/* <FaQuestionCircle className="w-3 h-3 fill-black text-white font-bold" /> */}
                </div>
                <div className="flex justify-between items-center border-t pt-4 mt-4 border-secondary-200">
                  <p>{t('page.payment.addressLabel.totalLabels')}</p>
                  <p>{Number.isNaN(numSheets) ? '-' : numSheets * connections.length}</p>
                </div>
              </div>
            </div>
            <div data-testid="address-label-address" className="py-6">
              <div className="flex items-center">
                <FaMapMarkerAlt className="h-6 w-6 text-primary-900 mr-1 " />
                <h2 className="text-2xl font-semibold">{t('page.payment.yourAddress')}</h2>
              </div>

              {addresses.length > 0 ? (
                <p>
                  {t('page.payment.addressLabel.addressSend')}
                  <span
                    className="underline cursor-pointer hover:font-semibold"
                    onClick={() => setShowProfileModal(true)}>
                    {t('page.payment.add')}
                  </span>
                  .
                </p>
              ) : (
                <p>
                  <span
                    className="underline cursor-pointer hover:font-semibold"
                    onClick={() => setShowProfileModal(true)}>
                    {t('page.payment.addAddress')}
                  </span>
                </p>
              )}
              <ProfileModal
                open={showProfileModal}
                setOpen={setShowProfileModal}
                type={ProfileDataType.ADDRESS}
                modalType="CREATE"
              />
              <AddressPicker
                setSelectedAddress={setSelectedAddress}
                selectedAddress={selectedAddress}
              />
            </div>

            <div className="border-t md:hidden pt-6 border-secondary-200 mt-6 mb-2 pl-2 flex flex-col">
              {paymentOverview}
            </div>

            <div data-testid="address-label-payment" className="py-6">
              <h2 className="text-2xl font-semibold border-t border-secondary-200 pt-6">
                {t('page.payment.paymentMethod')}
              </h2>
              <p>{t('page.payment.paymentMethodQuestion')}</p>
              {clientSecret && stripePromise && (
                <Elements options={options} stripe={stripePromise}>
                  <CheckoutForm
                    clientSecret={clientSecret}
                    disabled={disabledForm}
                    setDisabled={setDisabledForm}
                    onSubmit={async (): Promise<void> => {
                      await orderService.placeAddressLabelOrder(
                        filterB2CIds(connections),
                        filterLocalContactIds(connections),
                        filterPersonalMomentConnectionIds(connections),
                        numSheets,
                        selectedAddress!,
                        clientSecret,
                        discount?.code || '',
                      );
                    }}
                  />
                </Elements>
              )}
            </div>
          </div>
        </div>

        {/* Overview of the costs */}
        <div
          data-testid="postcard-overview"
          className="bg-secondary-200 hidden md:flex md:flex-col rounded-2xl p-4 px-6 md:col-span-3 h-fit sticky top-[105px] bottom-[500px]">
          {paymentOverview}
        </div>
      </div>
      <ChangeSelectionModal
        open={selectionOpen}
        setOpen={setSelectionOpen}
        selection={connections}
        setSelection={setConnections}
        type="address"
      />
    </>
  );
}
