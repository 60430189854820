import React from 'react';
import useBusinessTranslation from '../useBusinessTranslation';
import { CommunityType, MomentType } from '../../types/business';
import { isMoments } from '../../constants';

export default function useLandingPageData() {
  const { t } = useBusinessTranslation();

  const faq = ['free', 'device', 'privacy'];
  const steps = isMoments
    ? ['collect', 'organize', 'communicate']
    : ['import', 'invite', 'organize', 'communicate', 'contact'];

  const descriptions = isMoments
    ? {
        [MomentType.BIRTH]: [
          t('page.landing.usecase.case.collectAddress'),
          t('page.landing.usecase.case.inviteWithPartner'),
          t('page.landing.usecase.case.printLabels'),
          t('page.landing.usecase.case.shareAddress'),
        ],

        [MomentType.WEDDING]: [
          t('page.landing.usecase.case.inviteGuests'),
          t('page.landing.usecase.case.dayEvening'),
          t('page.landing.usecase.case.labels'),
          t('page.landing.usecase.case.weddingAdmin'),
          t('page.landing.usecase.case.templates'),
          t('page.landing.usecase.case.shareInformation'),
        ],

        [MomentType.FUNERAL]: [
          t('page.landing.usecase.case.inviteGuests'),
          t('page.landing.usecase.case.distinguishGuests'),
          t('page.landing.usecase.case.admins'),
          t('page.landing.usecase.case.shareInformation'),
        ],
      }
    : {
        [CommunityType.BUSINESS]: [
          t('page.landing.usecase.case.contactsAndAdmins'),
          t('page.landing.usecase.case.emailAndSMS'),
          t('page.landing.usecase.case.upToDateInformation'),
          t('page.landing.usecase.case.birthDayCard'),
          t('page.landing.usecase.case.businessCard'),
        ],
        [CommunityType.STUDENT]: [
          t('page.landing.usecase.case.contactsAndAdmins'),
          t('page.landing.usecase.case.studentClubs'),
          t('page.landing.usecase.case.memberList'),
          t('page.landing.usecase.case.whatsapp'),
          t('page.landing.usecase.case.memberContactStudent'),
          t('page.landing.usecase.case.informationChange'),
        ],

        [CommunityType.SPORT]: [
          t('page.landing.usecase.case.contactsAndAdmins'),
          t('page.landing.usecase.case.memberListSport'),
          t('page.landing.usecase.case.whatsapp'),
          t('page.landing.usecase.case.memberContactSport'),
          t('page.landing.usecase.case.sportWeekend'),
        ],
      };
  return { faq, steps, descriptions };
}
