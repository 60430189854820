import classNames from 'classnames';
import React, { useState } from 'react';
import { FaChevronDown } from 'react-icons/fa6';
import { IconType } from 'react-icons';
import { twMerge } from 'tailwind-merge';
import { BUTTON_STYLE, BUTTON_STYLE_COLLAPSE, LABEL_STYLE, LABEL_STYLE_COLLAPSE } from './style';
import Icon from '../../../icons/Icon';
import HoverButton from '../../../buttons/HoverButton';

interface DropDownItemProps {
  icon?: IconType;
  name: string;
  children?: React.ReactNode;
  id?: string;
  open?: boolean;
  duration?: number;
}

export default function DropdownItem({
  icon,
  name,
  children,
  id = '',
  open: openProp,
  duration,
}: DropDownItemProps): JSX.Element | null {
  const [open, setOpen] = useState(false);

  return (
    <div className="flex flex-col w-full">
      <HoverButton clickable={false} className="w-full">
        <HoverButton.Button>
          <div
            id={id}
            className={twMerge(BUTTON_STYLE, BUTTON_STYLE_COLLAPSE, 'justify-between')}
            onClick={openProp === undefined ? () => setOpen(!open) : () => {}}>
            <div className="flex gap-2">
              <Icon icon={icon} className="w-5 h-5" />
              <p className={twMerge(LABEL_STYLE, LABEL_STYLE_COLLAPSE, 'font-semibold')}>{name}</p>
            </div>
            <FaChevronDown
              className={classNames('w-4 h-4 transition-all lg:group-[.collapsed]:hidden', {
                'rotate-180': openProp ?? open,
              })}
            />
          </div>
        </HoverButton.Button>
        <HoverButton.Div className="transition-50 hidden group-[.open]:hidden rounded-[6px] w-[250px] lg:flex flex-col left-20 top-0 px-3 py-2 bg-primary-900 ">
          {children}
          <div className="h-[15px]  w-[15px] rotate-45 bg-primary-900 absolute top-[15%] -translate-y-[50%] -left-1 rounded-[2px]" />
        </HoverButton.Div>
      </HoverButton>
      <div
        style={{ transitionDuration: `${duration ?? 500}ms` }}
        className={classNames('transition-all', {
          'lg:group-[.open]:py-2': openProp ?? open,
        })}>
        <div
          style={{ transitionDuration: `${duration ?? (openProp ?? open ? 1000 : 500)}ms` }}
          className={classNames(
            'ml-4 lg:group-[.collapsed]:hidden flex flex-col gap-2 h-fit overflow-hidden lg:overflow-auto lg:group-[.open]:overflow-hidden',
            {
              'max-h-[0px] ease-[cubic-bezier(0,1,0,1)]':
                openProp === undefined ? !open : !openProp,
              'max-h-[2000px] ease-[ease-in-out]': openProp ?? open,
            },
          )}>
          {children}
        </div>
      </div>
    </div>
  );
}
