import React from 'react';
import { twMerge } from 'tailwind-merge';

interface StepIndicatorProps {
  steps: number;
  currentStep: number;
  onStepChange: (step: number) => void;
  className?: string;
}

function StepIndicator({
  steps,
  currentStep,
  onStepChange,
  className = '',
}: StepIndicatorProps): JSX.Element {
  const handleStepClick = (step: number): void => {
    if (step < currentStep) {
      onStepChange(step);
    }
  };

  return (
    <div className={twMerge('flex items-center', className)}>
      {Array.from({ length: steps * 2 - 1 }).map(
        (_, index): JSX.Element =>
          index % 2 === 0 ? (
            <div
              key={index}
              className={`w-4 h-4 rounded-full shrink-0 ${
                currentStep === index / 2
                  ? 'w-6 h-6 border-[6px] border-secondary bg-secondary-50 rounded-full'
                  : currentStep > 0 && index / 2 < currentStep
                  ? 'w-4 h-4 rounded-full bg-secondary shrink-0'
                  : 'bg-secondary-200'
              } ${
                index / 2 < currentStep
                  ? 'cursor-pointer hover:bg-vera-green-darker hover:transition'
                  : ''
              }`}
              onClick={(): void => handleStepClick(index / 2)}
            />
          ) : (
            <div
              key={index}
              className={`h-[1px] ${
                index / 2 <= currentStep ? 'bg-secondary' : 'bg-secondary-200'
              } w-full`}
            />
          ),
      )}
    </div>
  );
}

export default StepIndicator;
