import React from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import DashboardPage from '../views/pages/DashboardPage';
import LoginPage from '../views/pages/auth/LoginPage';
import AuthenticatedRoute from './AuthenticatedRoute';
import NotFoundPage from '../views/pages/NotFoundPage';
import NavBar from '../views/components/layouts/NavBar';
import MagicLoginPage from '../views/pages/auth/MagicLoginPage';
import AddressLabelPage from '../views/pages/payment/AddressLabelPage';
import PaymentCompletionPage from '../views/pages/payment/PaymentCompletionPage';
import PostcardPage from '../views/pages/payment/PostcardPage';
import Footer from '../views/components/layouts/Footer';
import BusinessOverviewPage from '../views/pages/BusinessOverviewPage';
import SelectedBusinessRoute from './SelectedBusinessRoute';
import GeneralSettingsPage from '../views/pages/settings/GeneralSettingsPage';
import RequestedDataPage from '../views/pages/settings/RequestedDataPage';
import AcceptAdminInvitePage from '../views/pages/AcceptAdminInvitePage';
import AdminOverviewPage from '../views/pages/settings/AdminOverviewPage';
import useAuth from '../hooks/account/useAuth';
import ProfilePage from '../views/pages/ProfilePage';
import OnboardingPage from '../views/pages/flows/OnboardingPage';
import LandingPage from '../views/pages/info/LandingPage';
import AboutPage from '../views/pages/info/AboutPage';
import ApplicationLayout from '../views/components/layouts/ApplicationLayout/ApplicationLayout';
import { isCommunity, isMoments } from '../constants';
import SharedDataPage from '../views/pages/settings/SharedDataPage';
import InviteLinkPage from '../views/pages/flows/InviteLinkPage';
import EventsPage from '../views/pages/agenda/EventsPage';
import EventReportsPage from '../views/pages/agenda/EventReportsPage';
import EmailPage from '../views/pages/communication/EmailPage';
import SmsPage from '../views/pages/communication/SmsPage';
import CreateEventPage from '../views/pages/agenda/CreateEventPage';
import EventDetailsPage from '../views/pages/agenda/EventDetailsPage';
import UploadExcelPage from '../views/pages/settings/UploadExcelPage';
import SubscriptionsPage from '../views/pages/payment/SubscriptionsPage';
import { EventViewType } from '../types/event';

export default function Router(): JSX.Element {
  const auth = useAuth();
  const location = useLocation().pathname;

  return (
    <>
      {(!auth || location === '/admin/invite') && location !== '/onboarding' && <NavBar />}
      <Routes>
        <Route path="login" element={<LoginPage />} />
        <Route path="onboarding" element={<OnboardingPage />} />
        <Route path="magic" element={<MagicLoginPage />} />
        <Route path="/admin/invite" element={<AcceptAdminInvitePage />} />
        <Route path="/about" element={<AboutPage />} />
        {!auth && <Route path="" element={<LandingPage />} />}
        <Route path="" element={<AuthenticatedRoute />}>
          <Route path="" element={<ApplicationLayout />}>
            <Route path="" element={<BusinessOverviewPage />} />
            <Route path="/profile" element={<ProfilePage />} />
            <Route path="" element={<SelectedBusinessRoute />}>
              <Route path="/dashboard" element={<DashboardPage />} />
              <Route path="/settings" element={<GeneralSettingsPage />} />
              <Route path="/invite-link" element={<InviteLinkPage />} />
              <Route path="/settings/requested-data" element={<RequestedDataPage />} />
              {isCommunity && (
                <Route path="/settings/subscriptions" element={<SubscriptionsPage />} />
              )}
              {isMoments && <Route path="/settings/shared" element={<SharedDataPage />} />}
              <Route path="/settings/upload-excel" element={<UploadExcelPage />} />
              <Route path="/settings/admins" element={<AdminOverviewPage />} />
              <Route path="/payment/address-label" element={<AddressLabelPage />} />
              <Route path="/payment/postcard" element={<PostcardPage />} />
              <Route path="/payment/completion" element={<PaymentCompletionPage />} />
              {isCommunity && (
                <>
                  {[EventViewType.REGULAR, EventViewType.DATE_PICKER].map((t) => {
                    const base = t === EventViewType.REGULAR ? '/events' : '/events/date-picker';
                    return (
                      <>
                        <Route path={base} element={<EventsPage type={t} />} />
                        <Route path={`${base}/create`} element={<CreateEventPage type={t} />} />
                        <Route path={`${base}/details`} element={<EventDetailsPage type={t} />} />
                      </>
                    );
                  })}
                  <Route path="events/report" element={<EventReportsPage />} />
                </>
              )}
              <Route path="/communication/email" element={<EmailPage />} />
              <Route path="/communication/sms" element={<SmsPage />} />
            </Route>
          </Route>
          <Route path="*" element={<NotFoundPage />} />
        </Route>
      </Routes>
      {(!auth || location === '/admin/invite') && location !== '/onboarding' && <Footer />}
    </>
  );
}
