import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Event, EventParticipationAnswer } from '../../types/event';

export interface EventState {
  eventMap: Record<number, Event[]>;
}
const initialState: EventState = {
  eventMap: {},
};

const eventSlice = createSlice({
  name: 'event',
  initialState,
  reducers: {
    createEvent: (state, action: PayloadAction<{ businessId: number; event: Event }>): void => {
      const { businessId, event } = action.payload;
      if (!state.eventMap[businessId]) {
        state.eventMap[businessId] = [];
      }
      state.eventMap[businessId].push(event);
    },

    setEvents(state, action: PayloadAction<{ businessId: number; events: Event[] }>): void {
      const { businessId, events } = action.payload;
      if (!state.eventMap[businessId]) {
        state.eventMap[businessId] = [];
      }
      state.eventMap[businessId] = events;
    },

    deleteEvent(state, action: PayloadAction<{ businessId: number; eventId: number }>): void {
      const { businessId, eventId } = action.payload;
      state.eventMap[businessId] = state.eventMap[businessId].filter(
        (event) => event.id !== eventId,
      );
    },

    updateEvent(state, action: PayloadAction<{ businessId: number; event: Event }>): void {
      const { businessId, event } = action.payload;
      state.eventMap[businessId] = state.eventMap[businessId].map((e) =>
        e.id === event.id ? event : e,
      );
      if (!state.eventMap[businessId].find((e) => e.id === event.id))
        state.eventMap[businessId].push(event);
    },

    setAttendance(
      state,
      action: PayloadAction<{
        businessId: number;
        participantId: number;
        answer: EventParticipationAnswer;
      }>,
    ) {
      const { businessId, participantId, answer } = action.payload;
      if (!state.eventMap[businessId]) state.eventMap[businessId] = [];
      state.eventMap[businessId] = state.eventMap[businessId].map((e) => ({
        ...e,
        participants: e.participants.map((p) => (p.id === participantId ? { ...p, answer } : p)),
      }));
    },
  },
});

export const { createEvent, setEvents, deleteEvent, updateEvent, setAttendance } =
  eventSlice.actions;
const eventReducer = eventSlice.reducer;
export default eventReducer;
