import React from 'react';
import { FaCalendar, FaEnvelope, FaMapMarkerAlt, FaPhoneAlt } from 'react-icons/fa';
import { AiOutlinePlus } from 'react-icons/ai';
import { HiTrash } from 'react-icons/hi';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { FaTicket } from 'react-icons/fa6';
import Slider from '../misc/Slider';
import {
  BusinessInvite,
  CustomField,
  CustomFieldType,
  CustomLabelField,
  CustomMultipleChoiceField,
} from '../../../services/model/inviteService.model';
import { filterCustomLabelFields } from '../../../utils/filterUtils';
import QuestionCircle from '../misc/QuestionCircle';
import { capitalizeFirstLetter } from '../../../utils/stringUtils';
import { profileDataTypeIcons } from '../../../utils/iconUtils';
import Button from '../buttons/Button';
import { ProfileDataType } from '../../../types/Profile';
import { isCommunity, isMoments } from '../../../constants';

interface InviteFormProps {
  invite: BusinessInvite;
  setInvite: (invite: BusinessInvite) => void;
}

export default function InviteForm({ invite, setInvite }: InviteFormProps): JSX.Element {
  const { optionalFields, mandatoryFields, customFields, nonRequestedFields } = invite;
  const selectedFields = [...optionalFields, ...mandatoryFields];

  const { t } = useTranslation();

  const basicFields = [
    {
      field: t('dataType.phoneNumber'),
      icon: <FaPhoneAlt className="h-5 w-5" aria-hidden="true" />,
      type: ProfileDataType.PHONENUMBER,
    },
    {
      field: t('dataType.email'),
      icon: <FaEnvelope className="h-5 w-5" aria-hidden="true" />,
      type: ProfileDataType.EMAIL,
    },
    {
      field: t('dataType.address'),
      icon: <FaMapMarkerAlt className="h-5 w-5" aria-hidden="true" />,
      type: ProfileDataType.ADDRESS,
    },
    {
      field: t('dataType.birthDate'),
      icon: <FaCalendar className="h-5 w-5" aria-hidden="true" />,
      type: ProfileDataType.BIRTHDATE,
    },
  ];

  const handleBasicFieldSelect = (field: ProfileDataType): void => {
    if (selectedFields.includes(field)) {
      setInvite({
        ...invite,
        optionalFields: optionalFields.filter((f): boolean => f !== field),
        mandatoryFields: mandatoryFields.filter((f): boolean => f !== field),
        nonRequestedFields: [...nonRequestedFields, field],
      });
    } else {
      setInvite({
        ...invite,
        optionalFields: [...optionalFields, field],
        nonRequestedFields: nonRequestedFields.filter((f): boolean => f !== field),
      });
    }
  };

  const handleMandatoryBasicFieldSelect = (field: ProfileDataType): void => {
    if (mandatoryFields.includes(field)) {
      setInvite({
        ...invite,
        mandatoryFields: mandatoryFields.filter((f): boolean => f !== field),
        optionalFields: [...optionalFields, field],
      });
    } else {
      setInvite({
        ...invite,
        mandatoryFields: [...mandatoryFields, field],
        optionalFields: optionalFields.filter((f): boolean => f !== field),
        nonRequestedFields: nonRequestedFields.filter((f): boolean => f !== field),
      });
    }
  };

  const handleCommunicationNameSelect = (): void => {
    if (customFields.find((x) => x.type === CustomFieldType.COMMUNICATION_NAME)) {
      setInvite({
        ...invite,
        customFields: customFields.filter((x) => x.type !== CustomFieldType.COMMUNICATION_NAME),
      });
    } else {
      setInvite({
        ...invite,
        customFields: [
          ...customFields,
          {
            type: CustomFieldType.COMMUNICATION_NAME,
            mandatory: false,
          },
        ],
      });
    }
  };

  const handleMandatoryCommunicationNameSelect = (): void => {
    const field = customFields.find((x) => x.type === CustomFieldType.COMMUNICATION_NAME);
    if (!field) {
      setInvite({
        ...invite,
        customFields: [
          ...customFields,
          {
            type: CustomFieldType.COMMUNICATION_NAME,
            mandatory: true,
          },
        ],
      });
    } else {
      setInvite({
        ...invite,
        customFields: customFields.map((x) =>
          x.type === CustomFieldType.COMMUNICATION_NAME
            ? { ...field, mandatory: !field.mandatory }
            : x,
        ),
      });
    }
  };

  const handleCustomFieldAdd = (): void => {
    setInvite({
      ...invite,
      customFields: [
        ...invite.customFields,
        {
          type: CustomFieldType.TEXT,
          label: '',
          mandatory: false,
        },
      ],
    });
  };
  return (
    <div className="w-full z-10 rounded-lg h-a">
      <div className="flex flex-col gap-1 border-b border-primary-300 pb-4 mb-4">
        <div className="flex items-center mb-1">
          <p className="text-lg font-medium mr-1">{t('form.invite.personalMessage.title')}</p>

          <QuestionCircle className="w-[300px] right-0 md:left-0">
            {t('form.invite.personalMessage.explanation')}
          </QuestionCircle>
        </div>
        <textarea
          data-testid="postcard-text"
          className="h-36"
          placeholder={t('form.invite.personalMessage.placeholder')}
          onChange={(e) => setInvite({ ...invite, message: e.target.value })}
          value={invite?.message}
        />
      </div>

      {/* Basic fields */}
      <div className="">
        <div className="flex items-center justify-between">
          <p className="text-lg font-medium">{t('form.invite.basic.title')}</p>
          <p className="text-[11px]">{t('form.invite.mandatory')}</p>
        </div>

        <div className="flex w-full relative flex-col">
          {isMoments && (
            <BasicField
              field={{
                field: t('dataType.communicationName'),
                icon: <FaTicket className="h-5 w-5" aria-hidden="true" />,
              }}
              selected={!!customFields.find((x) => x.type === CustomFieldType.COMMUNICATION_NAME)}
              setSelected={handleCommunicationNameSelect}
              mandatory={
                !!customFields.find((x) => x.type === CustomFieldType.COMMUNICATION_NAME)?.mandatory
              }
              setMandatory={handleMandatoryCommunicationNameSelect}>
              <QuestionCircle className="w-[250px]">
                {t('form.invite.basic.communicationNameExplanation')}
              </QuestionCircle>
            </BasicField>
          )}
          {basicFields.map((field) => (
            <BasicField
              selected={selectedFields.includes(field.type)}
              setSelected={() => handleBasicFieldSelect(field.type)}
              mandatory={mandatoryFields.includes(field.type)}
              setMandatory={() => handleMandatoryBasicFieldSelect(field.type)}
              field={field}
            />
          ))}
          <div className="border-l border-secondary-200 h-full absolute right-16 top-0 transform -translate-x-1/2" />
        </div>
      </div>

      {/* Custom fields */}
      {isCommunity && (
        <div className="border-t mt-6 pt-4  lg:mb-0 border-secondary-200">
          <p className="text-lg font-medium mt-2">{t('form.invite.custom.title')}</p>
          {filterCustomLabelFields(customFields).length > 0 && (
            <div className="flex justify-between items-center mt-2 mb-2">
              <div className="flex items-center">
                <p className="text-[13px] mr-[110px] invisible sm:visible">
                  {t('form.invite.custom.typeQuestion')}
                </p>
                <p className="text-[13px] invisible sm:visible">
                  {t('form.invite.custom.question')}
                </p>
              </div>
              <p className="text-[11px]">{t('form.invite.mandatory')}</p>
            </div>
          )}
          <div className="relative">
            <CustomFields
              fields={customFields}
              setFields={(fields: CustomField[]): void =>
                setInvite({ ...invite, customFields: fields })
              }
            />
            <div className="border-l border-secondary-200 h-full absolute right-16 top-0 transform -translate-x-1/2" />
          </div>

          <Button variant="tertiary" onClick={handleCustomFieldAdd} className="mt-4 mb-6">
            <AiOutlinePlus className="h-5 w-5 text-black stroke-2 mr-2" />
            <p className="text-black font-medium">{t('general.add')}</p>
          </Button>
        </div>
      )}

      {/* Automatic responses */}
      {/* <div>
        <p className="text-lg font-medium">Wil je automatisch reacties accepteren?</p>
        <div className="flex items-center mt-2">
          <Slider
            className="mr-4"
            state={acceptAutomatically}
            handleToggle={() => setInvite({ ...invite, acceptAutomatically: !acceptAutomatically })}
          />
          {acceptAutomatically ? 'Yes' : 'No'}
        </div>
      </div> */}
    </div>
  );
}

interface BasicFieldProps {
  selected: boolean;
  setSelected: () => void;
  mandatory: boolean;
  setMandatory: () => void;
  field: {
    field: string;
    icon?: JSX.Element;
    type?: ProfileDataType;
  };
  children?: React.ReactNode;
}

function BasicField({
  selected,
  setSelected,
  mandatory,
  setMandatory,
  field,
  children,
}: BasicFieldProps): JSX.Element {
  return (
    <div key={field.field} className="flex justify-between items-center">
      <div
        className={classNames(
          'flex items-center justify-between cursor-pointer w-full transition-all rounded-lg px-2 py-[3px] mr-6 my-1',
          {
            'bg-secondary-200': selected,
          },
        )}
        onClick={setSelected}>
        <div className="flex items-center w-full gap-2">
          <div className="bg-secondary text-secondary-50 rounded-[6px] flex items-center justify-center p-1">
            {field.type ? profileDataTypeIcons[field.type] : field.icon}
          </div>
          <p className="text-sm whitespace-nowrap py-2">{capitalizeFirstLetter(field.field)}</p>
          {children}
        </div>
        <input
          type="checkbox"
          checked={selected}
          className="rounded-full border-secondary-200 m-1"
        />
      </div>
      <div>
        <Slider className="mr-4" state={mandatory} handleToggle={setMandatory} />
      </div>
    </div>
  );
}

interface CustomFieldsProps {
  fields: CustomField[];
  setFields: (fields: CustomField[]) => void;
}

function CustomFields({ fields, setFields }: CustomFieldsProps): JSX.Element {
  const { t } = useTranslation();

  const handleMandatoryCustomFieldSelect = (index: number): void => {
    const newCustomFields = [...fields];
    const updatedCustomField = { ...newCustomFields[index] };
    updatedCustomField.mandatory = !updatedCustomField.mandatory;
    newCustomFields[index] = updatedCustomField;
    setFields(newCustomFields);
  };

  const handleCustomFieldLabelChange = (index: number, value: string): void => {
    const newCustomFields = [...fields];
    const updatedCustomField = { ...newCustomFields[index] };
    (updatedCustomField as CustomLabelField).label = value;
    newCustomFields[index] = updatedCustomField;
    setFields(newCustomFields);
  };

  const handleCustomFieldTypeChange = (index: number, value: CustomFieldType): void => {
    const newCustomFields = [...fields];
    const field = newCustomFields[index] as CustomLabelField;
    if (value === CustomFieldType.TEXT) {
      newCustomFields[index] = {
        type: CustomFieldType.TEXT,
        label: field.label,
        mandatory: field.mandatory,
      };
    } else {
      newCustomFields[index] = {
        type: CustomFieldType.MULTIPLE_CHOICE,
        label: field.label,
        options: ['', ''],
        mandatory: field.mandatory,
      };
    }
    setFields(newCustomFields);
  };

  const handleCustomFieldDelete = (index: number): void => {
    const newCustomFields = [...fields];
    newCustomFields.splice(index, 1);
    setFields(newCustomFields);
  };

  const handleMultipleChoiceOptionAdd = (index: number): void => {
    const newCustomFields = [...fields];
    newCustomFields[index] = {
      ...newCustomFields[index],
      options: [...(newCustomFields[index] as CustomMultipleChoiceField).options, ''],
    } as CustomMultipleChoiceField;
    setFields(newCustomFields);
  };

  const handleMultipleChoiceOptionChange = (
    index: number,
    optionIndex: number,
    value: string,
  ): void => {
    const newCustomFields = [...fields];
    const newField = {
      ...newCustomFields[index],
      options: [...(fields[index] as CustomMultipleChoiceField).options],
    };
    newField.options[optionIndex] = value;
    newCustomFields[index] = newField;
    setFields(newCustomFields);
  };

  const handleMultipleChoiceOptionDelete = (index: number, optionIndex: number): void => {
    const newCustomFields = [...fields];
    const newField = {
      ...newCustomFields[index],
      options: [...(fields[index] as CustomMultipleChoiceField).options],
    };
    newField.options.splice(optionIndex, 1);
    newCustomFields[index] = newField;
    setFields(newCustomFields);
  };

  const renderField = (field: CustomField, fieldIndex: number): JSX.Element => {
    switch (field.type) {
      case CustomFieldType.TEXT:
        return (
          <div className="flex sm:items-center relative items-start mb-10 sm:mb-0 sm:mt-0">
            {/* Select question type */}
            <p className="text-[13px] absolute -top-6 left-3 sm:hidden">
              {t('form.invite.custom.type')}
            </p>
            <div className="flex flex-col sm:flex-row sm:mt-2 relative items-center w-full">
              <select
                value={field.type}
                onChange={(e) =>
                  handleCustomFieldTypeChange(fieldIndex, e.target.value as CustomFieldType)
                }
                className="rounded-full bg-secondary-50 text-primary-900 border-primary w-full flex-grow-0 sm:w-fit sm:ml-0 ml-4 sm:mt-0 focus:ring-0 focus:border-primary-300">
                <option value="TEXT" className="active:bg-secondary-200">
                  Text
                </option>
                <option value="MULTIPLE_CHOICE" className="active:bg-secondary-200">
                  Multiple choice
                </option>
              </select>
              {/* Label input */}
              <p className="text-[13px] absolute left-3 top-[50px] sm:hidden">
                {t('form.invite.custom.question')}
              </p>
              <div className="flex w-full sm:mt-0 relative mt-8">
                <input
                  type="text"
                  value={field.label}
                  onChange={(e) => handleCustomFieldLabelChange(fieldIndex, e.target.value)}
                  className="rounded-lg border-secondary-200 ml-2 w-full -mr-2 sm:mr-0"
                />
                <button
                  onClick={() => handleCustomFieldDelete(fieldIndex)}
                  className="sm:ml-2 w-fit absolute -right-16 bottom-2 sm:static"
                  type="button">
                  <HiTrash className="h-5 w-5 text-primary " />
                </button>
              </div>
            </div>

            {/* Delete field and mandatory slider */}

            <div className="w-1 h-1 border-r mx-4 -mt-2" />
            <div className="items-center flex sm:mt-0 mt-[10px]">
              <Slider
                className="mr-4"
                state={field.mandatory}
                handleToggle={() => handleMandatoryCustomFieldSelect(fieldIndex)}
              />
            </div>
          </div>
        );
      case CustomFieldType.MULTIPLE_CHOICE:
        return (
          <div className="flex relative items-start h-auto mb-10 sm:mb-0 -mt-[10px] sm:mt-0">
            <p className="text-[13px] absolute -top-[14px] left-3 sm:hidden">
              {t('form.invite.custom.type')}
            </p>
            <div className="w-full flex flex-col sm:flex-row items-start">
              <div className="justify-items-end justify-end flex flex-col mt-2 w-full sm:w-fit">
                {/* Select type */}
                <select
                  value={field.type}
                  onChange={(e) =>
                    handleCustomFieldTypeChange(fieldIndex, e.target.value as CustomFieldType)
                  }
                  className="rounded-full bg-secondary-50 text-primary-900 border-primary w-full flex-grow-0 sm:w-fit sm:ml-0 ml-4 sm:mt-0 focus:ring-0 focus:border-primary-300">
                  <option value="TEXT">Text</option>
                  <option value="MULTIPLE_CHOICE">Multiple choice</option>
                </select>
                <p className="sm:mx-2 sm:mt-5 sm:visible mx-0 mt-0 justify-end flex text-[13px] invisible">
                  {t('form.invite.custom.options')}:
                </p>
              </div>

              <div className="flex w-full flex-col gap-2 mr-2 sm:mr-0 sm:mt-0 -mt-2">
                {/* Label */}
                <p className="text-[13px] ml-3 -mb-1 sm:hidden">
                  {t('form.invite.custom.question')}
                </p>
                <div className="flex relative w-full sm:mt-2">
                  <input
                    type="text"
                    value={field.label}
                    onChange={(e) => handleCustomFieldLabelChange(fieldIndex, e.target.value)}
                    className="rounded-lg border-secondary-200 ml-2 w-full -mr-2 sm:mr-0"
                  />
                  <button
                    onClick={() => handleCustomFieldDelete(fieldIndex)}
                    className="ml-2 w-fit absolute -right-[65px] bottom-[9px] sm:static"
                    type="button">
                    <HiTrash className="h-5 w-5 text-primary" />
                  </button>
                </div>
                <p className=" text-[13px] ml-3 -mb-1 sm:hidden">
                  {t('form.invite.custom.options')}:
                </p>
                {/* Multiple choice options */}
                {(field as CustomMultipleChoiceField).options.map((option, optionIndex) => (
                  <div className="relative sm:mr-9">
                    <input
                      type="text"
                      onChange={(e) =>
                        handleMultipleChoiceOptionChange(fieldIndex, optionIndex, e.target.value)
                      }
                      value={option}
                      className="rounded-lg border-secondary-200 ml-2 w-full"
                    />
                    {/* Delete option button */}
                    {optionIndex > 1 && (
                      <button
                        onClick={() => handleMultipleChoiceOptionDelete(fieldIndex, optionIndex)}
                        type="button"
                        className="absolute inset-y-0 right-0 px-2 flex items-center">
                        <AiOutlinePlus className="h-5 w-5 text-primary-300 text-gray-400 rotate-45" />
                      </button>
                    )}
                  </div>
                ))}
              </div>
            </div>

            {/* Delete field and mandatory slider */}
            <div className="h-full mt-3 flex-col justify-between">
              <div className="flex py-2 items-start h-full">
                <div className="w-1 border-secondary-200 border-r mx-4 -mt-2 h-18" />
                <div className="items-center flex">
                  <Slider
                    className="mr-4"
                    state={field.mandatory}
                    handleToggle={() => handleMandatoryCustomFieldSelect(fieldIndex)}
                  />
                </div>
              </div>
            </div>
            {/* Add option button */}
            <button
              onClick={() => handleMultipleChoiceOptionAdd(fieldIndex)}
              className="ml-2 w-fit absolute bottom-2 sm:right-[90px] sm:bottom-[10px] right-4"
              type="button">
              <AiOutlinePlus className="h-5 w-5 stroke-2" />
            </button>
          </div>
        );
      default:
        return <></>;
    }
  };

  return <>{fields.map(renderField)}</>;
}
