import React, { useEffect, useState } from 'react';
import { FaShareNodes } from 'react-icons/fa6';
import { useTranslation } from 'react-i18next';
import ModalLayout from '../layouts/ModalLayout';
import InvitationLink from '../misc/InvitationLink';
import localContactService from '../../../services/localContactService';
import IconBadge from '../icons/IconBadge';

interface LocalContactInvitationLinkProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  connectionId: string;
}

export default function LocalContactInvitationLinkModal({
  open,
  setOpen,
  connectionId,
}: LocalContactInvitationLinkProps): JSX.Element {
  const [link, setLink] = useState<string>('');
  const { t } = useTranslation();

  useEffect(() => {
    if (!open) return;
    if (!link) localContactService.generateFriendlyId(+connectionId).then(setLink);
  }, [open]);

  return (
    <ModalLayout
      open={open}
      setOpen={setOpen}
      className="bg-secondary-50 min-w-[450px] flex flex-col pb-4 max-w-[600px] pt-5 mx-6 z-50 rounded-[20px] py-6 px-10">
      <div className="flex w-full mb-6 items-center">
        <IconBadge icon={FaShareNodes} />

        <div className="flex flex-col ml-4 w-full ">
          <h1 className="text-2xl font-serif">
            {t('component.modal.localContactInvitationLink.title')}
          </h1>
          <p>{t('component.modal.localContactInvitationLink.subtitle')}</p>
        </div>
      </div>
      <InvitationLink link={link} />
    </ModalLayout>
  );
}
