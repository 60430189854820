/* eslint-disable no-fallthrough */
/* eslint-disable default-case */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import Select from 'react-select';
import countryList from 'react-select-country-list';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import httpService from '../../../../services/httpService';
import useEffectAfterFirstRender from '../../../../hooks/effects/useEffectAfterFirstRender';
import { createEmptyField } from '../../../../utils/formatUtils';
import { Address, ProfileDataType, ProfileField } from '../../../../types/Profile';
import useDelayedEffect from '../../../../hooks/effects/useDelayedEffect';
import { IsValidField, isEmptyField } from '../../../../utils/validationUtils';
import { SelectOption } from '../../../../types/misc';
import {
  COLOR_PRIMARY,
  COLOR_PRIMARY_300,
  COLOR_PRIMARY_900,
  COLOR_SECONDARY_50,
} from '../../../../constants';

interface AddressInputProps {
  field: ProfileField;
  setField: (field: ProfileField) => void;
  canBeEmpty?: boolean;
  mandatory?: boolean;
  label?: boolean;
  onlyFull?: boolean;
  validate?: boolean;
}

interface AddressFetchParams {
  postCode: string;
  houseNumber: string;
  countryCode: string;
  houseNumberAddition?: string;
}

export default function AddressInput({
  field,
  setField,
  canBeEmpty = false,
  mandatory = false,
  label = false,
  onlyFull = false,
  validate = false,
}: AddressInputProps): JSX.Element {
  const [value, setValue] = useState<SelectOption>();
  const [address, setAddress] = useState<Address>();
  const [addressString, setAddressString] = useState<string>('');
  const [error, setError] = useState<string>('');
  const options = useMemo(() => countryList().getData(), []);

  useDelayedEffect(() => {
    if (!validate) return;
    if (isEmptyField(field)) {
      setError(canBeEmpty ? '' : t('invalid.empty'));
      return;
    }
    if (!IsValidField(field)) {
      setError(t('invalid.invalid'));
      return;
    }

    setError('');
  }, [field.address, validate]);

  const { t } = useTranslation();

  const changeHandler = (v: SelectOption) => {
    setValue(v);
  };

  const fetchAddress = async () => {
    if (
      !onlyFull &&
      (value?.value! === 'NL' || !value?.value) &&
      address?.postCode &&
      address?.houseNumber
    ) {
      let params: AddressFetchParams = {
        postCode: address.postCode,
        houseNumber: address.houseNumber,
        countryCode: 'NL',
      };
      if (address.houseNumberAddition)
        params = { ...params, houseNumberAddition: address.houseNumberAddition };

      const { data } = await httpService.get('/address', { params }).catch(() => {
        setAddressString('');
        return { data: undefined };
      });

      if (!data) return;

      setField({
        ...field,
        address: data.address,
      });
      setAddressString(data.display);
    }
  };

  useDelayedEffect(() => fetchAddress(), [address, value]);

  const addressEmpty = () => {
    if (!address) return true;
    return field.address?.countryCode === 'NL'
      ? address?.postCode === '' && address?.houseNumber === ''
      : address?.city === '' &&
          address?.street === '' &&
          address?.houseNumber === '' &&
          address?.houseNumberAddition === '' &&
          address?.postCode === '';
  };

  useEffect(() => {
    if (!addressEmpty()) {
      setField({
        ...field,
        address: {
          ...address,
          countryCode: value?.value || field?.address?.countryCode,
          country: value?.label || field?.address?.country,
        } as Address,
      });

      setAddressString('');
    }
  }, [address]);

  useEffectAfterFirstRender(() => {
    // needed fr clearing
    if (addressEmpty()) {
      const addressField = field.address;
      setValue(
        addressField?.country && addressField?.countryCode
          ? {
              label: addressField.country,
              value: addressField.countryCode,
            }
          : value,
      );

      setField({ ...field, address: createEmptyField(ProfileDataType.ADDRESS).address });
    }
  }, [address]);

  const runRef = useRef<boolean>(false); //needed to know whether form has been typed in before
  useEffect(() => {
    // needed for setting the value if passed a field with a country code
    if (!value && field.address?.countryCode) {
      setValue({
        label: countryList().getLabel(field.address.countryCode),
        value: field.address.countryCode,
      });
    }

    // needed for defaulting to NL on first render but not subsequent ones
    if (runRef.current) return;
    if (isEmptyField(field)) {
      setValue({ label: 'Netherlands', value: 'NL' });
    } else runRef.current = true;
  }, [field]);

  useEffect(() => {
    if (!value?.label || !value?.value) return;
    if (
      field.address?.country !== value?.label &&
      field.address?.countryCode !== value?.value &&
      !isEmptyField(field)
    )
      setField({
        ...field,
        address: { ...field.address!, country: value?.label, countryCode: value?.value },
      });
  }, [value]);

  const renderInput = () => {
    const addressGeneral = field.address as Address;
    switch (value?.value || addressGeneral.countryCode) {
      // @ts-ignore
      case 'NL':
        if (!onlyFull)
          return (
            <div className="flex flex-col ">
              <div className="flex flex-row w-full mt-2 gap-2">
                <div className="flex flex-col w-4/6">
                  <label htmlFor={field.id} className="pr-2 mb-1  text-sm">
                    {t('form.address.postalCode')}
                  </label>
                  <input
                    id={field.id}
                    type="text"
                    name="postal-code"
                    placeholder={t('form.address.postalCode')}
                    value={addressGeneral.postCode ?? ''}
                    onChange={(e) =>
                      setAddress({
                        ...addressGeneral!,
                        postCode: e.target.value.replaceAll(' ', '').toUpperCase(),
                      })
                    }
                    data-testid={`street-input-${field.id}`}
                    className={classNames('mr-1', {
                      '!border-red-500': error,
                    })}
                  />
                </div>
                <div className="flex flex-col w-1/6">
                  <label htmlFor={field.id} className="pr-2 mb-1  text-sm">
                    {t('form.address.houseNumber')}
                  </label>
                  <input
                    id={field.id}
                    type="text"
                    placeholder={t('form.address.houseNumber')}
                    value={addressGeneral.houseNumber ?? ''}
                    onChange={(e) =>
                      setAddress({ ...addressGeneral!, houseNumber: e.target.value })
                    }
                    data-testid={`number-input-${field.id}`}
                    className={classNames(' !mr-1 ', {
                      '!border-red-500': error,
                    })}
                  />
                </div>
                <div className="flex flex-col w-1/6">
                  <label htmlFor={field.id} className="pr-2 mb-1 text-sm">
                    {t('form.address.houseNumberAddition')}
                  </label>
                  <input
                    id={field.id}
                    type="text"
                    placeholder={t('form.address.houseNumberAddition')}
                    value={addressGeneral.houseNumberAddition ?? ''}
                    onChange={(e) =>
                      setAddress({ ...addressGeneral!, houseNumberAddition: e.target.value })
                    }
                    data-testid={`houseNumberAddition-input-${field.id}`}
                    className={classNames(' !mr-1 ', {
                      '!border-red-500': error,
                    })}
                  />
                </div>
              </div>
              <p className={`mt-2 text-sm mx-auto ${!addressString && 'hidden'}`}>
                {addressString}
              </p>
            </div>
          );
      default:
        return (
          <>
            {' '}
            <div className="flex flex-row gap-2 w-full my-1">
              <div className="flex flex-col w-4/6">
                <label htmlFor={field.id} className="pr-2 mb-1 text-sm">
                  {t('form.address.street')}
                </label>
                <input
                  id={field.id}
                  type="text"
                  name="street-address"
                  placeholder={t('form.address.street')}
                  data-testid={`street-input-${field.id}`}
                  value={addressGeneral.street}
                  className={classNames('!mr-1', {
                    '!border-red-500': error,
                  })}
                  onChange={(e) => setAddress({ ...addressGeneral!, street: e.target.value })}
                />
              </div>
              <div className="flex flex-col w-1/6">
                <label htmlFor={field.id} className="pr-2 mb-1 text-sm">
                  {t('form.address.houseNumber')}
                </label>
                <input
                  id={field.id}
                  name="street-address"
                  type="text"
                  placeholder={t('form.address.houseNumber')}
                  data-testid={`number-input-${field.id}`}
                  value={addressGeneral.houseNumber}
                  className={classNames('!mr-1', {
                    '!border-red-500': error,
                  })}
                  onChange={(e) => setAddress({ ...addressGeneral!, houseNumber: e.target.value })}
                />
              </div>
              <div className="flex flex-col w-1/6">
                <label htmlFor={field.id} className="pr-2 mb-1 text-sm">
                  {t('form.address.houseNumberAddition')}
                </label>
                <input
                  id={field.id}
                  type="text"
                  placeholder={t('form.address.houseNumberAddition')}
                  data-testid={`houseNumberAddition-input-${field.id}`}
                  value={addressGeneral.houseNumberAddition}
                  className={classNames('!mr-1', {
                    '!border-red-500': error,
                  })}
                  onChange={(e) =>
                    setAddress({ ...addressGeneral!, houseNumberAddition: e.target.value })
                  }
                />
              </div>
            </div>
            <div className="flex flex-row gap-2 mt-1">
              <div className="flex flex-col w-1/3">
                <label htmlFor={field.id} className="pr-2 mb-1 text-sm">
                  {t('form.address.postalCode')}
                </label>
                <input
                  id={field.id}
                  name="street-address"
                  type="text"
                  placeholder={t('form.address.postalCode')}
                  data-testid={`postCode-input-${field.id}`}
                  value={addressGeneral.postCode}
                  className={classNames('!mr-1', {
                    '!border-red-500': error,
                  })}
                  onChange={(e) =>
                    setAddress({
                      ...addressGeneral!,
                      postCode: e.target.value.replaceAll(' ', '').toUpperCase(),
                    })
                  }
                />
              </div>
              <div className="flex flex-col w-2/3">
                <label htmlFor={field.id} className="pr-2 mb-1 text-sm">
                  {t('form.address.city')}
                </label>
                <input
                  id={field.id}
                  name="street-address"
                  type="text"
                  placeholder={t('form.address.city')}
                  data-testid={`city-input-${field.id}`}
                  value={addressGeneral.city}
                  className={classNames('!mr-1', {
                    '!border-red-500': error,
                  })}
                  onChange={(e) => setAddress({ ...addressGeneral!, city: e.target.value })}
                />
              </div>
            </div>
          </>
        );
    }
  };

  return (
    <div className="w-full">
      {label && (
        <label className=" pr-2 font-medium text-base mb-1">
          {t('form.address.label')} {mandatory ? '*' : ''}
        </label>
      )}

      <div className="flex flex-col w-full relative">
        <div className="flex flex-col">
          <label htmlFor={field.id} className="mb-1 pr-2  text-sm">
            {t('form.address.country')}
          </label>
          <Select
            styles={{
              control: (baseStyles: any, state) => ({
                ...baseStyles,
                borderColor: !error
                  ? state.isFocused
                    ? COLOR_PRIMARY_300
                    : COLOR_PRIMARY
                  : '#EF4444',
                borderRadius: '9999px',
                color: COLOR_PRIMARY_900,
                backgroundColor: COLOR_SECONDARY_50,
                boxShadow: 'none',
                height: '40px',
                '&:hover': {
                  borderColor: !error
                    ? state.isFocused
                      ? COLOR_PRIMARY_300
                      : COLOR_PRIMARY
                    : '#EF4444',
                },
              }),
              option: (styles: any, { isSelected }) => {
                return {
                  ...styles,
                  backgroundColor: isSelected ? COLOR_PRIMARY : COLOR_SECONDARY_50,
                  color: isSelected ? COLOR_SECONDARY_50 : COLOR_PRIMARY_900,
                  '&:hover': {
                    backgroundColor: isSelected ? COLOR_PRIMARY_300 : COLOR_PRIMARY_300,
                    color: COLOR_PRIMARY_900,
                  },
                };
              },
              indicatorSeparator: () => ({
                display: 'none',
              }),
              dropdownIndicator: (baseStyles: any, state) => ({
                ...baseStyles,
                color: state.isFocused ? COLOR_PRIMARY_300 : COLOR_PRIMARY,
              }),
            }}
            options={options as any}
            placeholder={t('form.address.countryPlaceholder')}
            value={
              (value?.label || field.address?.country) &&
              (value?.value || field.address?.countryCode)
                ? {
                    label: value?.label! || field.address?.country!,
                    value: value?.value! || field.address?.countryCode!,
                  }
                : undefined
            }
            onChange={changeHandler}
            className="mb-1"
          />
        </div>
        {renderInput()}
        <p className="text-red-500 absolute top-0 right-1 text-sm transition-all">{error}</p>
      </div>
    </div>
  );
}
