import React from 'react';
import { FaAngleDoubleLeft, FaAngleDoubleRight, FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import { twMerge } from 'tailwind-merge';

interface PaginationProps {
  pageIndex: number;
  pageSize: 10 | 20 | 30 | 40 | 50;
  setPageIndex: (pageIndex: number) => void;
  setPageSize: (pageSize: 10 | 20 | 30 | 40 | 50) => void;
  length: number;
  className?: string;
}
export default function Pagination({
  pageIndex,
  pageSize,
  setPageIndex,
  setPageSize,
  length,
  className = '',
}: PaginationProps) {
  const numPages = Math.ceil(length / pageSize);
  return (
    <div className={twMerge('flex items-center gap-2 justify-end', className)}>
      <button
        type="button"
        className="text-primary-900 disabled:opacity-30 rounded-full bg-transparant p-2 aspect-square"
        onClick={() => setPageIndex(0)}
        disabled={pageIndex === 0}>
        <FaAngleDoubleLeft />
      </button>
      <button
        type="button"
        className="text-primary-900 disabled:opacity-30 rounded-full bg-secondary-200 p-2 aspect-square"
        onClick={() => setPageIndex(pageIndex - 1)}
        disabled={pageIndex === 0}>
        <FaAngleLeft />
      </button>
      <span className="flex items-center gap-1 text-primary-900 text-base">
        <div>Pagina</div>
        {pageIndex + 1} van {numPages}
      </span>
      <button
        type="button"
        className="text-primary-900 rounded-full disabled:opacity-30 bg-secondary-200 p-2 aspect-square"
        onClick={() => setPageIndex(pageIndex + 1)}
        disabled={pageIndex === numPages - 1}>
        <FaAngleRight />
      </button>
      <button
        type="button"
        className="text-primary-900 disabled:opacity-30 rounded-full bg-transparant p-2 aspect-square"
        onClick={() => setPageIndex(numPages - 1)}
        disabled={pageIndex === numPages - 1}>
        <FaAngleDoubleRight />
      </button>
      <select
        className="text-primary-300 rounded-3xl py-2 pl-3 pr-8 bg-transparent my-2"
        value={pageSize}
        onChange={(e) => {
          setPageSize(+e.target.value as 10 | 20 | 30 | 40 | 50);
          setPageIndex(0);
        }}>
        {[10, 20, 30, 40, 50].map((ps) => (
          <option key={ps} value={ps}>
            Toon {ps}
          </option>
        ))}
      </select>
    </div>
  );
}
