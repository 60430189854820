import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { HiArrowRight } from 'react-icons/hi';
import { AiOutlinePlus } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../../redux/reducers';
import { store } from '../../redux/store';
import { setSelectedBusiness, wipeSelectedBusiness } from '../../redux/slices/applicationSlice';
import { setConnections } from '../../redux/slices/connectionsSlice';
import useSelectedBusiness from '../../hooks/business/useSelectedBusiness';
import useBusinessTranslation from '../../hooks/useBusinessTranslation';
import ApiImage from '../components/misc/ApiImage';
import { businessTypeIcons } from '../../utils/iconUtils';
import Icon from '../components/icons/Icon';

export default function BusinessOverviewPage(): JSX.Element {
  const { t } = useBusinessTranslation();
  const businesses = useSelector((state: RootState) => state.application.businesses);
  const navigate = useNavigate();
  const selectedBusiness = useSelectedBusiness();

  useEffect(() => {
    if (!businesses) return;
    store.dispatch(wipeSelectedBusiness());
    store.dispatch(setConnections([]));
  }, [selectedBusiness]);

  const handleBusinessSelect = (businessId: number) => {
    store.dispatch(setSelectedBusiness(businessId));
    navigate('/dashboard');
  };

  return (
    <>
      <div className="ml-2 mb-2">
        <h1 className="font-semibold font-serif text-3xl">{t('page.businessOverview.title')}</h1>
        <p className="py-1">{t('page.businessOverview.subtitle')}</p>
      </div>
      <div className="flex gap-8 flex-wrap">
        {businesses?.map((business) => (
          <div
            onClick={() => handleBusinessSelect(business.businessId!)}
            className="w-48 rounded-lg border cursor-pointer border-primary-900 hover:bg-primary-300 hover:bg-opacity-10 relative transition-all group">
            {business.picture ? (
              <ApiImage
                alt="business-profile"
                path={business.picture}
                className="w-full h-48 rounded-t-lg object-cover"
              />
            ) : (
              <div className="w-full h-48 rounded-t-lg text-primary-900 flex items-center mx-auto">
                <Icon
                  className="w-20 h-20 text-primary-900 mx-auto my-auto"
                  icon={businessTypeIcons[business.type]}
                />
              </div>
            )}

            <div className=" w-full p-4 py-3 flex border-t border-primary-900 flex-col justify-between gap-2 flex-wrap wrap">
              <p className="font-medium  break-words overflow-hidden  w-full">{business.name}</p>
              <button type="button" className="bg-vera-green-lightest p-1 rounded-lg w-fit">
                <HiArrowRight className="w-6 h-6 mx-auto text-black" />
              </button>
            </div>
          </div>
        ))}
        <div
          onClick={() => navigate('/onboarding')}
          className="w-48 border-dashed border-primary-900  cursor-pointer hover:bg-primary-300 hover:bg-opacity-10 hover:border-opacity-80 border-2 rounded-lg flex items-center group">
          <div className="mx-auto text-md font-medium flex items-center flex-col text-primary-900 group-hover:text-opacity-80 ">
            <AiOutlinePlus className="w-20 h-20 stroke-1" />
            {t('page.businessOverview.new')}
          </div>
        </div>
      </div>
    </>
  );
}
