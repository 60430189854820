import React, { useState, HTMLAttributes, useRef, MouseEvent } from 'react';
import classNames from 'classnames';
import { twMerge } from 'tailwind-merge';
import { findReactElement } from '../../../utils/reactUtils';
import useOutsideEffect from '../../../hooks/effects/useOutsideEffect';

interface HoverButtonProps {
  id?: string;
  children: React.ReactNode;
  className?: string;
  clickable?: boolean;
  hoverable?: boolean;
  onClose?: () => void;
}
function HoverButton({
  id = '',
  children,
  className = '',
  clickable = true,
  hoverable = true,
  onClose = () => {},
}: HoverButtonProps): JSX.Element {
  const [hovered, setHovered] = useState(false);
  const [open, setOpen] = useState(false);
  const hoverTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const onCloseTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  useOutsideEffect(() => {
    setOpen(false);
    if (!hovered) onCloseTimeoutRef.current = setTimeout(onClose, 150);
  }, containerRef);

  const button = findReactElement(children, HoverButton.Button);
  const div = findReactElement<HTMLAttributes<HTMLDivElement>>(children, HoverButton.Div);

  const onMouseEnter = () => {
    if (!hoverable) return;
    setHovered(true);
    if (hoverTimeoutRef.current) {
      clearTimeout(hoverTimeoutRef.current);
    }
  };

  const onMouseLeave = () => {
    hoverTimeoutRef.current = setTimeout(() => {
      setHovered(false);
      if (!open) onCloseTimeoutRef.current = setTimeout(onClose, 150);
    }, 100);
  };

  const onAbsoluteMouseEnter = () => {
    if (hoverTimeoutRef.current) {
      clearTimeout(hoverTimeoutRef.current);
    }
    if (onCloseTimeoutRef.current) {
      clearTimeout(onCloseTimeoutRef.current);
    }
  };

  const expanded = hovered || open;

  return (
    <div id={id} className={twMerge('h-fit w-fit relative', className)} ref={containerRef}>
      <div
        onMouseEnter={() => onMouseEnter()}
        onMouseLeave={() => onMouseLeave()}
        onClick={(e) => {
          if (clickable) {
            setOpen(!open);
            if (open && !hovered) onCloseTimeoutRef.current = setTimeout(onClose, 150);
          }

          e.stopPropagation();
        }}
        className={classNames('group cursor-pointer', { expanded })}>
        {button}
      </div>
      {div &&
        React.cloneElement(div, {
          ...div.props,
          onMouseEnter: onAbsoluteMouseEnter,
          onMouseLeave,
          onClick: (e: MouseEvent) => e.stopPropagation(),
          className: twMerge(
            classNames('absolute transition-all w-fit h-fit', {
              'opacity-0 pointer-events-none': !expanded,
              'z-10 opacity-100': expanded,
              expanded,
            }),
            div.props.className,
          ),
        })}
    </div>
  );
}

function Button({ children }: { children: React.ReactNode }) {
  return <>{children}</>;
}

function Div(props: HTMLAttributes<HTMLDivElement>) {
  return <div {...props} />;
}

HoverButton.Button = Button;
HoverButton.Div = Div;

export default HoverButton;
