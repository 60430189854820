import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { RootState } from '../../../redux/reducers';
import { ConnectionIdentifier } from '../../../types/misc';
import { Connection } from '../../../redux/slices/connectionsSlice';
import { sendSMS } from '../../../utils/actionUtils';
import ChangeSelectionModal from '../../components/modals/ChangeSelectionModal';
import Button from '../../components/buttons/Button';

export default function SmsPage() {
  const connections = useSelector((state: RootState) => state.connections);
  const { t } = useTranslation();
  const location = useLocation();
  const [selected, setSelected] = useState<ConnectionIdentifier[]>(
    location?.state?.connections?.map(({ id, type }: Connection) => ({ id, type })) ?? [],
  );
  const [text, setText] = useState<string>('');
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const handleSubmit = () => {
    const phoneNumbers = connections
      .filter(({ id, type }) => selected.some((s) => s.id === id && s.type === type))
      .map(({ id, fields: { PHONENUMBER } }) => PHONENUMBER)
      .filter(Boolean);
    if (!phoneNumbers.length) toast.error(t('page.communication.noneSelected'));

    sendSMS(phoneNumbers, text);
  };

  return (
    <>
      <div className="grid grid-cols-8 gap-6 flex-1">
        <div className="col-span-8 lg:col-span-5 flex flex-col gap-6 flex-1">
          <div data-testid="postcard-info" className="bg-secondary-200 rounded-2xl p-4 h-fit">
            <h2 className="mb-2 pl-2 font-serif text-3xl font-semibold">
              {t('page.communication.sendSMS.title')}
            </h2>
            <div className="pl-2">
              <p>{t('page.communication.sendSMS.subtitle')}</p>
            </div>
          </div>
          <div className="flex justify-between mb-2 pl-2 ">
            <span className="flex">
              <p className="text-primary font-semibold">{selected.length}</p>/{connections.length}
              {t('page.payment.selected')}
            </span>
            <p
              data-testid="edit-selection"
              onClick={(): void => setModalOpen(true)}
              className="underline cursor-pointer">
              {t('page.payment.editSelection')}
            </p>
          </div>
          <div className="flex gap-4 flex-col border-t border-secondary-200 pt-6">
            <div className="flex flex-col gap-2">
              <h2 className="text-2xl font-semibold">{t('page.communication.sendSMS.header')}</h2>
              <p>{t('page.communication.sendSMS.text')}</p>
            </div>
            <div className="flex gap-1 flex-col">
              <label className="pr-2 font-medium text-base">
                {t('page.communication.message')}
              </label>
              <textarea
                className="w-full h-44"
                value={text}
                onChange={(e) => setText(e.target.value)}
              />
            </div>
          </div>
          <div className="flex-1 justify-end items-center gap-4 flex">
            <span className="text-xs font-medium text-primary-900">
              {t('page.communication.sendSMS.warning')}
            </span>
            <Button variant="primary" disabled={!selected.length} onClick={handleSubmit}>
              {t('page.communication.sendSMS.submit')}
            </Button>
          </div>
        </div>
      </div>
      <ChangeSelectionModal
        open={modalOpen}
        setOpen={setModalOpen}
        selection={selected}
        setSelection={setSelected}
        type="phone"
      />
    </>
  );
}
