import React, { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { FaEllipsisH } from 'react-icons/fa';
import { HiTrash } from 'react-icons/hi';
import { toast } from 'react-toastify';
import { BsEnvelope } from 'react-icons/bs';
import { Admin, AdminRole } from '../../../types/business';
import adminService from '../../../services/adminService';
import WarningModal from '../modals/WarningModal';
import useSelectedBusiness from '../../../hooks/business/useSelectedBusiness';
import useAdmins from '../../../hooks/business/useAdmins';
import useBusinessTranslation from '../../../hooks/useBusinessTranslation';
import { getUserId } from '../../../services/httpService';

interface AdminActionsProps {
  admin: Admin;
}
export default function AdminActions({ admin }: AdminActionsProps): JSX.Element {
  const [deleteDialog, setDeleteDialog] = React.useState(false);

  const { t } = useBusinessTranslation();
  const admins = useAdmins().filter((x) => x.role === AdminRole.OWNER && !x.pending);
  const { role } = useSelectedBusiness() || {};

  const handleDelete = async (): Promise<void> => {
    if (!admin.pending) {
      await adminService.deleteAdmins([admin.adminId!]);
    } else {
      await adminService.deleteAdminInvites([admin.inviteId!]);
    }
  };

  const handleInviteResend = async (): Promise<void> => {
    await adminService.resendInviteLink(admin.inviteId!);
    toast.success(t('toast.success.inviteSentAgain'));
  };

  const handleUpgradeToOwner = async (): Promise<void> => {
    await adminService.upgradeAdminToOwner(admin.adminId!);
    toast.success(t('toast.success.upgradedAdmin'));
  };

  const handleLeave = async (): Promise<void> => {
    await adminService.leaveBusiness();
    toast.success(t('toast.success.leftMoment'));
  };

  if (admin.userId === +getUserId()) {
    if (admins.length === 1 && admins[0].userId === +getUserId()) return <></>;
    return (
      <>
        <button
          type="button"
          onClick={(): void => setDeleteDialog(true)}
          className="bg-red-500 rounded-lg px-2 py-1 text-white font-semibold text-xs">
          {t('component.actions.admin.leave')}
        </button>
        <WarningModal
          open={deleteDialog}
          setOpen={setDeleteDialog}
          onClose={handleLeave}
          title={t('warning.leaveBusiness.title')}
          saveClassName="bg-red-500"
          description={t('warning.leaveBusiness.description')}
          button={t('warning.leaveBusiness.title')}
        />
      </>
    );
  }

  if (role !== AdminRole.OWNER) return <></>;

  return (
    <>
      <Menu as="div" className="relative origin-right w-fit h-fit">
        <Menu.Button className="p-2 flex items-center text-left">
          <FaEllipsisH className="h-6 w-6 font-bold icon" />
        </Menu.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95">
          <Menu.Items className="absolute right-10 -top-10 z-10 mt-2 w-fit origin-top-right max-h-48 h-fit rounded-md bg-secondary-50 shadow-lg ring-1 ring-black ring-opacity-5">
            {admin.pending && (
              <Menu.Item>
                {({ active }): JSX.Element => (
                  <button
                    type="button"
                    className={`${
                      active ? 'bg-secondary-200' : ''
                    } px-4 py-2 text-sm font-semibold rounded-b-md flex items-center p-1 w-full`}
                    onClick={handleInviteResend}>
                    <BsEnvelope className="w-5 h-5 mr-2 icon" />
                    <span className="whitespace-nowrap inline">
                      {t('component.actions.admin.resendInvite')}
                    </span>
                  </button>
                )}
              </Menu.Item>
            )}

            {!admin.pending && admin.role !== AdminRole.OWNER && (
              <Menu.Item>
                {({ active }): JSX.Element => (
                  <button
                    type="button"
                    className={`${
                      active ? 'bg-secondary-200' : ''
                    } px-4 py-2 text-sm text-primary-900 font-semibold rounded-b-md flex items-center p-1 w-full`}
                    onClick={handleUpgradeToOwner}>
                    <BsEnvelope className="w-5 h-5 mr-2 icon" />
                    <span className="whitespace-nowrap inline">
                      {t('component.actions.admin.makeOwner')}
                    </span>
                  </button>
                )}
              </Menu.Item>
            )}
            <Menu.Item>
              {({ active }): JSX.Element => (
                <button
                  type="button"
                  className={`${
                    active ? 'bg-secondary-200' : ''
                  } px-4 py-2 text-sm text-primary-900 font-semibold rounded-b-md flex items-center p-1 w-full`}
                  onClick={(): void => setDeleteDialog(true)}>
                  <HiTrash className="w-5 h-5 mr-2 icon" />
                  <span className="whitespace-nowrap inline">
                    {!admin.pending
                      ? t('component.actions.admin.removeAdmin')
                      : t('component.actions.admin.removeInvite')}
                  </span>
                </button>
              )}
            </Menu.Item>
          </Menu.Items>
        </Transition>
      </Menu>
      <WarningModal
        open={deleteDialog}
        setOpen={setDeleteDialog}
        onClose={(): Promise<void> => handleDelete()}
        title={
          !admin.pending ? t('warning.deleteAdmin.title') : t('warning.deleteAdminInvite.title')
        }
        saveClassName="bg-red-500"
        description={
          !admin.pending
            ? t('warning.deleteAdmin.description')
            : t('warning.deleteAdminInvite.description')
        }
        button={
          !admin.pending ? t('warning.deleteAdmin.title') : t('warning.deleteAdminInvite.title')
        }
      />
    </>
  );
}
