import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/reducers';
import { Auth } from '../../types/misc';
import { ApplicationType } from '../../redux/slices/applicationSlice';

export default function useAuth(): Auth | undefined {
  const app = useSelector((state: RootState): ApplicationType => state.application);

  if (!app.isAuthenticated) return undefined;

  return {
    firstName: app.firstName!,
    lastName: app.lastName!,
    email: app.email!,
  };
}
