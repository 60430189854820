import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export default function PaymentCompletionPage(): JSX.Element {
  const [status, setStatus] = useState<string>('loading');

  const navigate = useNavigate();

  useEffect((): void => {
    const params = new URLSearchParams(window.location.search);
    const redirectStatus: string = params.get('redirect_status')!;
    if (!redirectStatus) {
      navigate('/dashboard');
    }
    setStatus(redirectStatus);
  }, []);

  if (status === 'succeeded') {
    return (
      <div className="mx-auto my-auto flex flex-col">
        <h1 className="text-3xl font-bold mx-auto">Het is gelukt!</h1>
        <p className="text-xl font-bold mx-auto">Bedankt voor je aankoop, wij gaan voor je aan de slag!</p>
      </div>
    );
  }
  if (status === 'failed') {
    return (
      <div className="mx-auto my-auto flex flex-col ">
        <h1 className="text-3xl font-bold mx-auto">Er is iets misgegaan</h1>
        <p className="text-xl font-bold mx-auto">Probeer het later opnieuw!</p>
      </div>
    );
  }
  return <div>PaymentCompletionPage</div>;
}
