import httpService from './httpService';
import {
  CreateIntroductionBody,
  FilterConnectedIntroducedRequest,
  FilterConnectedIntroducedResponse,
  IntroductionResponseBody,
} from './model/introductionService.model';

/**
 * Gets all the non-connected non introduced people out
 * of the list with the userId.
 *
 * @param userId the id of the user to look by
 * @param otherUserIds the other userIds who we will
 *          see if they are connected or introduced
 */
function getCompatibleUserids(toIntoduceUserId: number, otherUserIds: number[]): Promise<number[]> {
  const body: FilterConnectedIntroducedRequest = { toIntoduceUserId, otherUserIds };
  return httpService
    .post<FilterConnectedIntroducedResponse>(`/notConnectedNotIntroduced`, body)
    .then(({ data }) => data.notConnectedNotIntroducedUserIds);
}

function createIntroductions(introducedId: number, userIds: number[]): Promise<number[]> {
  const intros: CreateIntroductionBody[] = userIds.map((id) => ({
    userIdA: introducedId.toString(),
    userIdB: id.toString(),
  }));
  return httpService
    .post<IntroductionResponseBody[]>('/business/introduction', intros)
    .then(({ data }) => data.map((i) => i.userIdB));
}

const introductionService = {
  getCompatibleUserids,
  createIntroductions,
};

export default introductionService;
