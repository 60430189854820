import React, { useEffect, useRef } from 'react';
import useOnScreen from '../../../hooks/effects/useOnScreen';

export default function AppearInDivView({
  children,
  className = '',
}: {
  children: React.ReactNode;
  className?: string;
}): JSX.Element {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const hasAppeared = useRef<boolean>(false);
  const isVisible = useOnScreen(wrapperRef);

  useEffect(() => {
    if (isVisible) hasAppeared.current = true;
  }, [isVisible]);

  if (hasAppeared.current) return <div className={className}>{children}</div>;

  return (
    <div
      ref={wrapperRef}
      id="scroll-transition-div"
      className={`scroll-transition ${
        isVisible
          ? 'show opacity-100 transform translate-y-0'
          : 'opacity-0 transform translate-y-5'
      } transition-all duration-1000 ease-in-out ${className}`}>
      {children}
    </div>
  );
}
