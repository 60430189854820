import { ApplicationVersion } from './types/misc';

export const APPLICATION_VERSION =
  (process.env.REACT_APP_APPLICATION_VERSION as ApplicationVersion) || ApplicationVersion.MOMENTS;

export const isMoments = APPLICATION_VERSION === ApplicationVersion.MOMENTS;
export const isCommunity = APPLICATION_VERSION === ApplicationVersion.COMMUNITY;

// Color pallette
export const COLOR_PRIMARY = '#0A1B89';
export const COLOR_PRIMARY_300 = '#92A2EC';
export const COLOR_PRIMARY_900 = '#081647';
export const COLOR_SECONDARY = '#F19E7A';
export const COLOR_SECONDARY_50 = '#FCEEEB';
export const COLOR_SECONDARY_200 = '#EDD5C9';

export const API_URL =
  process.env.REACT_APP_API_URL ??
  (process.env.NODE_ENV === 'development'
    ? 'http://localhost:3500'
    : 'https://veraconnect.app/api');

export const CONSUMER_URL = process.env.REACT_APP_CONSUMER_URL || 'https://localhost:3333';

export const GOOGLE_PLACES_API_KEY = process.env.REACT_APP_GOOGLE_PLACES_API_KEY || '';
