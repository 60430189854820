import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { ProfileField } from '../../../../types/Profile';
import { isValidEmail } from '../../../../utils/stringUtils';
import useDelayedEffect from '../../../../hooks/effects/useDelayedEffect';

interface EmailInputProps {
  field: ProfileField;
  setField: (field: ProfileField) => void;
  canBeEmpty?: boolean;
  mandatory?: boolean;
  label?: boolean;
  validate?: boolean;
}

export default function EmailInput({
  field,
  setField,
  canBeEmpty = false,
  mandatory = false,
  label = false,
  validate = false,
}: EmailInputProps): JSX.Element {
  const { t } = useTranslation();
  const [error, setError] = React.useState<string>('');

  useDelayedEffect(() => {
    if (!validate) return;
    if (!field.email) {
      setError(canBeEmpty ? '' : t('invalid.empty'));
      return;
    }
    setError(isValidEmail(field.email) ? '' : t('invalid.invalid'));
  }, [field.email, validate]);

  const handleChange = (s: string): void => {
    setField({ ...field, email: s });
  };

  return (
    <div className="w-full">
      {label && (
        <label className=" pr-2 font-medium text-base mb-1">
          {`${t('general.your')} ${t('dataType.email').toLowerCase()}`} {mandatory ? '*' : ''}
        </label>
      )}
      <div className="flex flex-col w-full relative">
        <label
          htmlFor={field.id}
          className="pr-2 mb-1 text-sm focus:ring-0 focus:ring-transparent focus:border-[#01AFA5] focus:border-3">
          {t('dataType.email')}
        </label>
        <input
          id={field.id}
          placeholder={t('dataType.email')}
          className={error ? '!border-red-500' : ''}
          name="email"
          data-testid={`email-input-${field.id}`}
          type="text"
          value={field.email ?? ''}
          onChange={(e) => handleChange(e.target.value)}
        />
        <p className="text-red-500 absolute top-0 right-1 text-sm transition-all">{error}</p>
      </div>
    </div>
  );
}
