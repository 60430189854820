import { store } from '../redux/store';
import httpService from './httpService';
import {
  Connection,
  setConnections,
  deleteConnections,
  ConnectionType,
  updateNote,
} from '../redux/slices/connectionsSlice';
import {
  PendingConnectionsType,
  setPendingConnections,
} from '../redux/slices/pendingConnectionsSlice';

/*
 * The connection service, contains all logic for connections
 */
const connectionService = {
  getConnections: async (force: boolean = false): Promise<Connection[]> => {
    if (!force && store.getState().connections.length > 0) return store.getState().connections;
    const { data } = await httpService.get('/business/connection');
    store.dispatch(setConnections(data));
    return data;
  },

  /**
   * Reports a customer.
   * @param id The id of the connection with the consumer to report
   */
  reportConnection: async (id: string): Promise<void> => {
    await httpService.post(`/b2c-connection/report/${id}`);
  },
  getPendingConnections: async (): Promise<PendingConnectionsType[]> => {
    const { data } = await httpService.get('/business/connection/pending');
    store.dispatch(setPendingConnections(data));
    return data;
  },
  acceptPendingConnection: async (id: string): Promise<void> => {
    const { data } = await httpService.post('/business/connection/update-status', {
      connectionId: id,
      accepted: true,
    });
    store.dispatch(setConnections([...store.getState().connections, data]));
  },
  declinePendingConnection: async (id: string): Promise<void> => {
    await httpService.post('/business/connection/update-status', {
      connectionId: id,
      accepted: false,
    });
  },
  deleteConnections: async (ids: string[]): Promise<void> => {
    if (!ids.length) return;
    await httpService.delete('/business/connection', {
      data: {
        connectionIds: ids,
      },
    });
    store.dispatch(deleteConnections({ ids, type: ConnectionType.B2C_CONNECTION }));
  },

  getProfilePictureUrl: async (id: string): Promise<string> => {
    return httpService
      .get(`/business/image/connection/${id}`)
      .then(({ data }): string => {
        return data.path;
      });
  },

  updateNote: async (id: string, note: string): Promise<void> => {
    return httpService.put(`/business/connection/note`, { note, id }).then(() => {
      store.dispatch(updateNote({ id, type: ConnectionType.B2C_CONNECTION, note }));
    });
  },
};

export default connectionService;
