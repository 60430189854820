import React from 'react';
import { ProfileDataType, ProfileField } from '../../../types/Profile';
import BirthDateInput from './profile/BirthDateInput';
import PhoneNumberInput from './profile/PhoneNumberInput';
import EmailInput from './profile/EmailInput';
import AddressInput from './profile/AddressInput';

interface ProfileFieldProps {
  field: ProfileField;
  setField: (field: ProfileField) => void;
  canBeEmpty?: boolean;
  onlyFull?: boolean;
  validate?: boolean;
}
/**
 * Renders a single profile field in a list of fields.
 *
 * @param {Object} props - The component props.
 * @param {ProfileField} props.field - The profile field to render.
 * @param {Function} props.setModalData - The function to call when the edit or delete button is clicked.
 * @returns {JSX.Element} The rendered profile field.
 */
export default function ProfileFieldForm({
  field,
  setField,
  canBeEmpty = false,
  onlyFull = false,
  validate = false,
}: ProfileFieldProps): JSX.Element {
  if (!field) return <></>;
  switch (field.dataType) {
    case ProfileDataType.EMAIL:
      return (
        <EmailInput field={field} setField={setField} canBeEmpty={canBeEmpty} validate={validate} />
      );
    case ProfileDataType.ADDRESS:
      if (!field.address) return <></>;
      return (
        <AddressInput
          field={field}
          setField={setField}
          canBeEmpty={canBeEmpty}
          onlyFull={onlyFull}
          validate={validate}
        />
      );
    case ProfileDataType.PHONENUMBER:
      if (!field.phoneNumber) return <></>;
      return (
        <PhoneNumberInput
          field={field}
          setField={setField}
          canBeEmpty={canBeEmpty}
          validate={validate}
        />
      );
    case ProfileDataType.BIRTHDATE:
      return (
        <BirthDateInput
          field={field}
          setField={setField}
          canBeEmpty={canBeEmpty}
          validate={validate}
        />
      );
    default:
      return <></>;
  }
}
