import React from 'react';
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { StripePaymentElementOptions } from '@stripe/stripe-js';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { isMoments } from '../../../constants';
import Button from '../buttons/Button';

const frontendUrl =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3000'
    : isMoments
    ? 'https://veramoments.app'
    : 'https://veracommunity.app';
interface CheckoutFormProps {
  clientSecret: string;
  disabled: boolean;
  setDisabled: any;
  onSubmit: any;
}

export default function CheckoutForm({
  clientSecret,
  onSubmit,
  disabled,
  setDisabled,
}: CheckoutFormProps): JSX.Element {
  const stripe = useStripe();
  const elements = useElements();
  const { t } = useTranslation();

  const handleSubmit = async (e: any): Promise<void> => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setDisabled(true);
    try {
      await onSubmit();
    } catch (error) {
      setDisabled(false);
      return;
    }

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: `${frontendUrl}/payment/completion`,
      },
    });

    if (error.type === 'card_error' || error.type === 'validation_error') {
      toast.error(error.message!);
    } else {
      toast.error(t('toast.error.unexpected'));
    }

    setDisabled(false);
  };

  const paymentElementOptions = {
    layout: 'tabs',
    loader: 'always',
  } as StripePaymentElementOptions;

  return (
    <div data-testid="checkout-form" id="payment-form relative">
      <PaymentElement
        data-testid="stripe-form"
        className="w-full my-4 bg-white shadow-xl mb-8 rounded-[20px] p-4"
        options={paymentElementOptions}
      />
      <div className="justify-end flex">
        <Button variant="primary" disabled={disabled} onClick={handleSubmit}>
          {t('page.payment.payAndConfirm')}
        </Button>
      </div>
    </div>
  );
}
