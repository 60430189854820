import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import Business from '../../types/business';
import Language from '../../types/language';
import { BusinessInvite } from '../../services/model/inviteService.model';
import { OnboardingStep } from '../../types/misc';

export type ApplicationType = {
  userId?: number;
  firstName?: string;
  lastName?: string;
  email?: string;
  businesses?: Business[];
  selectedBusiness?: number;
  isAuthenticated?: boolean;
  language: Language;
  onboardingStep?: OnboardingStep;
};

const initialState: ApplicationType = {
  language: Language.NL,
  isAuthenticated: false,
};

const applicationSlice = createSlice({
  name: 'application',
  initialState,
  reducers: {
    login: (
      state,
      action: PayloadAction<{
        firstName: string;
        lastName: string;
        userId: number;
        email: string;
        businesses?: Business[];
      }>,
    ): void => {
      state.isAuthenticated = true;
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
      state.userId = action.payload.userId;
      state.email = action.payload.email;
      state.businesses = action.payload.businesses || [];
    },
    logout: (state): void => {
      state.isAuthenticated = false;
      state.firstName = undefined;
      state.lastName = undefined;
      state.userId = undefined;
      state.email = undefined;
      state.businesses = undefined;
    },
    setState: (state, action: PayloadAction<ApplicationType>): void => {
      Object.assign(state, action.payload);
    },

    setPicture: (state, action: PayloadAction<string | null>): void => {
      state.businesses!.find((x) => x.businessId === state.selectedBusiness)!.picture =
        action.payload;
    },

    setName: (state, action: PayloadAction<string>): void => {
      state.businesses!.find((x) => x.businessId === state.selectedBusiness)!.name = action.payload;
    },

    setDescription: (state, action: PayloadAction<string>): void => {
      state.businesses!.find((x) => x.businessId === state.selectedBusiness)!.description =
        action.payload;
    },

    setCommunityLink: (state, action: PayloadAction<string>): void => {
      state.businesses!.find((x) => x.businessId === state.selectedBusiness)!.communityLink =
        action.payload;
    },

    addBusiness: (state, action: PayloadAction<Business>): void => {
      state.businesses!.push(action.payload);
    },

    deleteBusiness: (state, action: PayloadAction<number>): void => {
      state.businesses = state.businesses!.filter((x) => x.businessId !== action.payload);
    },

    setSelectedBusiness: (state, action: PayloadAction<number>): void => {
      state.selectedBusiness = action.payload;
    },

    wipeSelectedBusiness: (state): void => {
      state.selectedBusiness = undefined;
    },

    addInviteFields: (state, action: PayloadAction<BusinessInvite>): void => {
      const business = state.businesses!.find((x) => x.businessId === state.selectedBusiness);

      business!.inviteFields = action.payload;
    },

    setInviteLink: (state, action: PayloadAction<string | undefined>): void => {
      const business = state.businesses!.find((x) => x.businessId === state.selectedBusiness);
      business!.inviteFields!.friendlyId = action.payload;
      if (action.payload) business!.inviteFields!.linkExpiry = new Date(Date.now() + 12096e5);
    },

    setLanguage: (state, action: PayloadAction<Language>): void => {
      state.language = action.payload;
    },

    setCommunity: (state, action: PayloadAction<number | undefined>): void => {
      state.businesses!.find((x) => x.businessId === state.selectedBusiness)!.communityId =
        action.payload;
      if (action.payload === undefined) {
        state.businesses!.find((x) => x.businessId === state.selectedBusiness)!.communityLink =
          undefined;
      }
    },
    setOnboarding: (state, action: PayloadAction<OnboardingStep | undefined>): void => {
      state.onboardingStep = action.payload;
    },
  },
});

export const {
  setState,
  setPicture,
  setName,
  setDescription,
  addBusiness,
  deleteBusiness,
  setSelectedBusiness,
  wipeSelectedBusiness,
  addInviteFields,
  setInviteLink,
  setLanguage,
  setCommunity,
  login,
  logout,
  setCommunityLink,
  setOnboarding,
} = applicationSlice.actions;
export default applicationSlice.reducer;
