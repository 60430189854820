import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import authService from '../../../services/authService';

/*
 * The email verification page
 * @returns {JSX.Element} - The email verification page page.
 */
export default function VerifyEmailPage(): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  useEffect((): void => {
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('link');
    if (token === null) {
      toast.error(t('toast.error.invalidMagicLink'));
      navigate('/login');
      return;
    }
    authService.loginMagicLink(token);
    const businessName = queryParams.get('businessName');
    const businessType = queryParams.get('businessType');
    const businessDescription = queryParams.get('businessDescription');
    const connectedMembers = queryParams.get('connectedMembers') === 'true';
    const communityLink = queryParams.get('communityLink');

    if (businessName && businessType) {
      navigate('/onboarding', {
        state: { businessName, businessType, businessDescription, connectedMembers, communityLink },
      });
    } else {
      navigate('/');
    }
  }, []);

  return <div />;
}
