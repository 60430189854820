import {
  CustomField,
  CustomFieldType,
  CustomLabelField,
} from '../services/model/inviteService.model';

export function filterCustomLabelFields(fields: CustomField[]): CustomLabelField[] {
  if (!fields) return [];
  return fields.filter((field) =>
    [CustomFieldType.MULTIPLE_CHOICE, CustomFieldType.TEXT].includes(field.type),
  ) as CustomLabelField[];
}
