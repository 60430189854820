import React from 'react';
import { twMerge } from 'tailwind-merge';
import ApiImage from './ApiImage';
import { getInitialsFromAlias } from '../../../utils/stringUtils';

interface AvatarProps {
  src: string | undefined;
  alias: string;
  className?: string;
}
export default function Avatar({ src, alias, className }: AvatarProps): JSX.Element {
  return src ? (
    <ApiImage
      path={src}
      alt={alias}
      className={twMerge('rounded-[8px] w-8 h-8 flex-shrink-0 object-cover', className)}
    />
  ) : (
    <div
      className={twMerge(
        'rounded-[6px] bg-secondary text-secondary-50 text-sm w-10 h-10 items-center justify-center flex p-1 flex-shrink-0',
        className,
      )}>
      {getInitialsFromAlias(alias)}
    </div>
  );
}
