import React from 'react';
import { twMerge } from 'tailwind-merge';

interface ComingSoonProps {
  text: string;
  className?: string;
}

export default function ComingSoon({ text, className }: ComingSoonProps): JSX.Element {
  return (
    <div
      className={twMerge(
        'flex justify-center items-center text-center flex-col gap-2 w-full',
        className,
      )}>
      <img
        src={`${process.env.PUBLIC_URL}/assets/figures/mess.svg`}
        alt="Vera"
        className="mb-4 w-[50%]"
      />
      <h2 className="text-4xl font-semibold">Coming soon!</h2>
      <p className="text-lg">{text}</p>
    </div>
  );
}
