import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FaCheck } from 'react-icons/fa';
import { RootState } from '../../../redux/reducers';
import ModalLayout from '../layouts/ModalLayout';
import useBusinessTranslation from '../../../hooks/useBusinessTranslation';
import { ConnectionIdentifier } from '../../../types/misc';
import IconBadge from '../icons/IconBadge';
import CancelSaveButton from '../misc/CancelSaveButton';
import ConnectionsList from '../misc/ConnectionsList';

interface ChangeSelectionModalProps {
  selection: ConnectionIdentifier[];
  setSelection: (connections: ConnectionIdentifier[]) => void;
  open: boolean;
  setOpen: (open: boolean) => void;
  type: 'phone' | 'email' | 'address';
}
export default function ChangeSelectionModal({
  selection,
  setSelection,
  open,
  setOpen,
  type: modalType,
}: ChangeSelectionModalProps): JSX.Element {
  const { t } = useBusinessTranslation();
  const connections = useSelector((state: RootState) => state.connections);
  const [selected, setSelected] = useState<ConnectionIdentifier[]>([]);

  useEffect(() => {
    if (!open) return;
    setSelected(selection);
  }, [open]);

  return (
    <ModalLayout
      open={open}
      setOpen={setOpen}
      closeButton
      className="bg-secondary-50 min-w-[450px] flex flex-col pb-4 max-w-[600px] pt-5 mx-6 z-50 rounded-[20px] py-6 px-10 h-a">
      <div className="flex w-full">
        <IconBadge icon={FaCheck} />

        <div className="flex flex-col ml-4 mb-6 w-full ">
          <h1 className="text-2xl font-serif">{t('component.modal.selectionChange.title')}</h1>
          <p>{t('component.modal.selectionChange.subtitle')}</p>
        </div>
      </div>

      <ConnectionsList
        connections={connections.map(
          ({ id, type, fields: { FIRST_NAME, LAST_NAME, ADDRESS, EMAIL, PHONENUMBER } }) => ({
            id: { id, type },
            alias: `${FIRST_NAME}+${LAST_NAME}`,
            disabled:
              (modalType === 'email' && !EMAIL) ||
              (modalType === 'phone' && !PHONENUMBER) ||
              (modalType === 'address' && !ADDRESS),
          }),
        )}
        selected={selected}
        setSelected={setSelected}
      />
      <div className="w-full mt-6 justify-end flex">
        <CancelSaveButton
          onCancel={() => setOpen(false)}
          disabled={!selected.length}
          onSave={() => {
            setSelection(selected);
            setOpen(false);
          }}
        />
      </div>
    </ModalLayout>
  );
}
