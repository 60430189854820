import React from 'react';
import classNames from 'classnames';

interface SliderProps {
  state: boolean;
  handleToggle: () => void;
  className?: string;
}

export default function Slider({ state, handleToggle, className = '' }: SliderProps): JSX.Element {
  const onChange = (): void => {
    handleToggle();
  };
  return (
    <div
      data-testid="slider"
      className={classNames(
        'relative w-10 h-5 mr-2 p-0.5 rounded-full flex  items-center transition-all duration-500',
        {
          'bg-primary-300': state,
          'bg-secondary-200': !state,
        },
        className,
      )}
      onClick={(): void => onChange()}>
      <span
        className={classNames('h-4 w-4 rounded-full bg-secondary-50 transition-all duration-200', {
          'ml-5': state,
        })}
      />
    </div>
  );
}
