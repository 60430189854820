import React from 'react';
import { useNavigate } from 'react-router-dom';
import useSelectedBusiness from '../business/useSelectedBusiness';
import useEffectAfterFirstRender from './useEffectAfterFirstRender';

export default function useNavigateAfterBusinessChange() {
  const navigate = useNavigate();
  const business = useSelectedBusiness();

  useEffectAfterFirstRender(() => {
    if (business) {
      navigate('/dashboard');
    }
  }, [business?.businessId]);
}
