import { useSelector } from 'react-redux';
import { RootState } from '../../redux/reducers';
import { Event, EventViewType } from '../../types/event';

export default function useEvents(type: EventViewType = EventViewType.ALL): Event[] {
  const {
    application: { selectedBusiness },
    events: { eventMap },
  } = useSelector((state: RootState) => state);
  
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const filters = {
    [EventViewType.REGULAR]: (e: Event) => !e.isDatePicker && new Date(e.startTime) >= today,
    [EventViewType.DATE_PICKER]: (e: Event) => e.isDatePicker && new Date(e.startTime) >= today,
    [EventViewType.PAST]: (e: Event) => new Date(e.startTime) < today,
    [EventViewType.ALL]: () => true,
  };

  return eventMap[selectedBusiness!]?.filter(filters[type]) || [];
}
