import React, { useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaEnvelope, FaMapMarkerAlt, FaPhoneAlt } from 'react-icons/fa';
import { toast } from 'react-toastify';
import Select, { CSSObjectWithLabel } from 'react-select';
import { FaCakeCandles } from 'react-icons/fa6';
import { Label, ProfileDataType, ProfileField } from '../../../types/Profile';
import { profileDataTypeToString } from '../../../utils/stringUtils';
import { createEmptyField } from '../../../utils/formatUtils';
import ProfileFieldForm from '../forms/ProfileFieldForm';
import ModalLayout from '../layouts/ModalLayout';
import { IsValidField } from '../../../utils/validationUtils';
import userService from '../../../services/userService';
import CancelSaveButton from '../misc/CancelSaveButton';
import {
  COLOR_PRIMARY,
  COLOR_PRIMARY_300,
  COLOR_PRIMARY_900,
  COLOR_SECONDARY_50,
} from '../../../constants';

interface ProfileModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  type: ProfileDataType;
  modalType: 'CREATE' | 'EDIT';
  field?: ProfileField;
}

export default function ProfileModal({
  open,
  setOpen,
  type,
  modalType,
  field,
}: ProfileModalProps): JSX.Element {
  const [modalField, setModalField] = useState<ProfileField>();
  const [validate, setValidate] = useState<boolean>(false);
  const { t } = useTranslation();

  useLayoutEffect(() => {
    if (!open) return;
    setValidate(false);
    if (field) setModalField(field);
    else setModalField(createEmptyField(type));
  }, [open]);

  const getHeaderItems = (): JSX.Element => {
    switch (type) {
      case ProfileDataType.EMAIL:
        return <FaEnvelope className="h-6 w-6 text-xl text-secondary-50 icon" aria-hidden="true" />;
      case ProfileDataType.ADDRESS:
        // eslint-disable-next-line react/jsx-no-undef
        return <FaMapMarkerAlt className="h-6 w-6 text-secondary-50 icon" aria-hidden="true" />;
      case ProfileDataType.PHONENUMBER:
        return <FaPhoneAlt className="h-6 w-6 text-secondary-50 icon" aria-hidden="true" />;
      case ProfileDataType.BIRTHDATE:
        return <FaCakeCandles className="h-6 w-6 text-secondary-50 icon" aria-hidden="true" />;
      default:
        return <></>;
    }
  };

  const handleSubmit = async () => {
    if (!modalField?.label) {
      toast.error(t('toast.error.label'));
      return;
    }
    setValidate(true);
    if (!IsValidField(modalField, false)) return;

    await userService.setFields([modalField]);

    setOpen(false);
    setModalField(createEmptyField(type));
  };

  const labelOptions = [
    { label: 'General', value: Label.GENERAL },
    { label: 'Work', value: Label.WORK },
    { label: 'Private', value: Label.PRIVATE },
  ];

  return (
    <ModalLayout
      open={open}
      setOpen={setOpen}
      className="bg-secondary-50 min-w-[450px] w-fit pb-4 pt-5 mx-6 max-w-[600px] z-50 rounded-[20px] py-6 px-10 h-a">
      <div className="flex flex-col w-full">
        <div className="flex items-center mb-2">
          <div className="w-11 h-11 rounded-full bg-secondary flex items-center justify-center">
            {getHeaderItems()}
          </div>
          <h1 className="text-xl font-semibold ml-2">
            {t('general.your')} {profileDataTypeToString(type)}
          </h1>
        </div>
        <div>
          {type !== ProfileDataType.BIRTHDATE && (
            <>
              <label
                htmlFor={`${modalField?.id}-label`}
                className="pr-2 mb-1 text-sm focus:ring-0 focus:ring-transparent focus:border-[#01AFA5] focus:border-3">
                {t('general.label')}:
              </label>

              <Select
                isSearchable={false}
                className="mb-4"
                styles={{
                  control: (baseStyles: any, state): CSSObjectWithLabel => ({
                    ...baseStyles,
                    borderColor: state.isFocused ? COLOR_PRIMARY_300 : COLOR_PRIMARY,
                    borderRadius: '9999px',
                    accentColor: COLOR_PRIMARY_900,
                    color: COLOR_PRIMARY_900,
                    backgroundColor: COLOR_SECONDARY_50,
                    boxShadow: 'none',
                    height: '40px',
                    '&:hover': {
                      borderColor: state.isFocused ? COLOR_PRIMARY_300 : COLOR_PRIMARY,
                    },
                  }),
                  option: (styles: any, { isSelected }): CSSObjectWithLabel => ({
                    ...styles,
                    backgroundColor: isSelected ? COLOR_PRIMARY : COLOR_SECONDARY_50,
                    color: isSelected ? COLOR_SECONDARY_50 : COLOR_PRIMARY_900,
                    '&:hover': {
                      backgroundColor: isSelected ? COLOR_PRIMARY_300 : COLOR_PRIMARY_300,
                      color: COLOR_PRIMARY_900,
                    },
                  }),
                  indicatorSeparator: () => ({
                    display: 'none',
                  }),
                  dropdownIndicator: (baseStyles: any, state): CSSObjectWithLabel => ({
                    ...baseStyles,
                    color: state.isFocused ? COLOR_PRIMARY_300 : COLOR_PRIMARY,
                  }),
                }}
                options={labelOptions as any}
                value={{
                  label:
                    labelOptions.find((x) => x.value === modalField?.label)?.label! || 'General',
                  value:
                    labelOptions.find((x) => x.label === modalField?.label)?.value! || 'GENERAL',
                }}
                onChange={(v) => setModalField({ ...modalField!, label: v?.value! })}
              />
            </>
          )}

          <ProfileFieldForm field={modalField!} setField={setModalField} validate={validate} />
        </div>
        <div data-testid="buttons" className="mt-4 flex justify-end">
          <CancelSaveButton onCancel={() => setOpen(false)} onSave={handleSubmit} />
        </div>
      </div>
    </ModalLayout>
  );
}
