import React from 'react';
import { useLocation } from 'react-router-dom';
import { FiCalendar } from 'react-icons/fi';
import { HiOutlineLogout } from 'react-icons/hi';
import { useDispatch } from 'react-redux';
import useBusinessTranslation from '../../../../../hooks/useBusinessTranslation';
import useLanguage from '../../../../../hooks/account/useLanguage';
import Language from '../../../../../types/language';
import { setLanguage } from '../../../../../redux/slices/applicationSlice';
import authService from '../../../../../services/authService';
import useAuth from '../../../../../hooks/account/useAuth';
import Navigation from './Navigation';
import Action from './Action';

export default function SettingsPanel() {
  const { t } = useBusinessTranslation();
  const auth = useAuth();
  const location = useLocation();
  const dispatch = useDispatch();
  const lang = useLanguage();
  const oppositeLang = lang === Language.NL ? Language.EN : Language.NL;

  return (
    <>
      {location.pathname !== '/' && auth && (
        <Navigation
          path="/"
          textClassName="text-primary-300"
          icon={<FiCalendar className="w-5 h-5" />}
          name={t('page.businessOverview.title')}
        />
      )}
      <Action
        name={t('general.switchLanguage')}
        textClassName="text-primary-300"
        icon={
          <img
            src={`${process.env.PUBLIC_URL}/assets/flags/${oppositeLang}.svg`}
            alt="Nederlands"
            className="w-5 h-5 cursor-pointer"
          />
        }
        onClick={() => dispatch(setLanguage(oppositeLang))}
      />
      {auth && (
        <Action
          name={t('auth.logout')}
          textClassName="text-primary-300"
          icon={<HiOutlineLogout className="w-5 h-5 text-primary-300" />}
          onClick={authService.logout}
        />
      )}
    </>
  );
}
