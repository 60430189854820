/* eslint-disable no-else-return */
import { ConnectionType } from '../redux/slices/connectionsSlice';
import connectionService from '../services/connectionService';
import localContactService from '../services/localContactService';
import personalMomentConnectionService from '../services/personalMomentConnectionService';
import tagService from '../services/tagService';
import { ConnectionIdentifier } from '../types/misc';

const filterOnType = (type: ConnectionType) => (cs: ConnectionIdentifier[]) =>
  cs.filter((c) => c.type === type).map((c) => c.id);
const b2c = filterOnType(ConnectionType.B2C_CONNECTION);
const loc = filterOnType(ConnectionType.LOCAL_CONTACT);
const pmc = filterOnType(ConnectionType.PERSONAL_MOMENT_CONNECTION);

export async function addTag(tagId: string, connections: ConnectionIdentifier[]) {
  await Promise.all([
    tagService.assignTagToConnections(b2c(connections), tagId),
    tagService.assignTagToLocalContacts(loc(connections), tagId),
    tagService.assignTagToMomentConnections(pmc(connections), tagId),
  ]);
}

export async function removeTag(tagId: string, connection: ConnectionIdentifier) {
  if (connection.type === ConnectionType.B2C_CONNECTION)
    await tagService.removeTagFromConnection(connection.id, tagId);
  else if (connection.type === ConnectionType.LOCAL_CONTACT)
    await tagService.removeTagFromLocalContact(connection.id, tagId);
  else if (connection.type === ConnectionType.PERSONAL_MOMENT_CONNECTION)
    await tagService.removeTagFromMomentConnection(connection.id, tagId);
}

export async function deleteConnections(connections: ConnectionIdentifier[]): Promise<void> {
  await Promise.all([
    connectionService.deleteConnections(b2c(connections)),
    localContactService.deleteLocalContacts(loc(connections)),
    personalMomentConnectionService.deletePersonalMomentConnections(pmc(connections)),
  ]);
}

export async function updateNote(note: string, connection: ConnectionIdentifier) {
  if (connection.type === ConnectionType.B2C_CONNECTION)
    await connectionService.updateNote(connection.id, note);
  else if (connection.type === ConnectionType.LOCAL_CONTACT)
    await localContactService.updateNote(connection.id, note);
  else if (connection.type === ConnectionType.PERSONAL_MOMENT_CONNECTION)
    await personalMomentConnectionService.updateNote(connection.id, note);
}

export async function getProfilePictureUrl(connection: ConnectionIdentifier): Promise<string> {
  if (connection.type === ConnectionType.B2C_CONNECTION)
    return connectionService.getProfilePictureUrl(connection.id);
  else if (connection.type === ConnectionType.LOCAL_CONTACT)
    return localContactService.getProfilePictureUrl(connection.id);
  else if (connection.type === ConnectionType.PERSONAL_MOMENT_CONNECTION)
    return personalMomentConnectionService.getProfilePictureUrl(connection.userId!.toString());
  else return '';
}

export const filterB2CIds = b2c;
export const filterLocalContactIds = loc;
export const filterPersonalMomentConnectionIds = pmc;
