import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import authService from '../../../services/authService';
import { ApplicationType } from '../../../redux/slices/applicationSlice';
import PanelWithImageLayout from '../../components/layouts/PanelWithImageLayout';
import { isMoments } from '../../../constants';
import useBusinessTranslation from '../../../hooks/useBusinessTranslation';
import Button from '../../components/buttons/Button';

/*
 * The login page.
 * @returns {JSX.Element} - The login page.
 */
export default function LoginPage(): JSX.Element {
  const navigate = useNavigate();
  const { t } = useBusinessTranslation();
  const [email, setEmail] = useState<string>('');

  const { isAuthenticated: auth } = useSelector((state: any): ApplicationType => state.application);
  useEffect((): void => {
    if (auth) navigate('/');
  }, [auth]);

  /*
   * Handles the login, doesn't allow empty fields.
   * @returns {void}
   */
  const handleLogin = async (): Promise<void> => {
    if (email === '') {
      toast.error(t('toast.error.email'));
      return;
    }
    await authService.generateMagicLink(email);
    toast.success(t('toast.success.sentMagicLink'));
  };

  return (
    <PanelWithImageLayout
      image={`${process.env.PUBLIC_URL}/assets/onboarding/${
        isMoments ? 'wedding' : 'student'
      }.svg`}>
      <PanelWithImageLayout.Header>
        <h1 className="text-2xl font-serif font-medium">{t('auth.login')}</h1>
      </PanelWithImageLayout.Header>

      <div className="flex flex-col -mt-2 gap-4 flex-1 h-full">
        <div className="flex flex-col gap-2">
          <p className="text-sm">{t('page.login.description')}</p>
        </div>
        <div>
          <label className="pr-2 mb-1 text-sm ">{t('dataType.email')}</label>
          <input
            placeholder="E-mailadres"
            name="email"
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="flex flex-col gap-5 flex-1 h-full justify-end items-end align-bottom min-h-10">
          <Button variant="primary" onClick={handleLogin}>
            {t('page.login.button')}
          </Button>
          <div className="flex justify-end -mt-2">
            <span className="text-sm text-primary-900">
              {t('auth.noAccountYet')}{' '}
              <b
                className="border-b border-primary-900 cursor-pointer font-semibold "
                onClick={() => navigate('/onboarding')}>
                {t('page.login.make')}
              </b>
            </span>
          </div>
        </div>
      </div>
    </PanelWithImageLayout>
  );
}
