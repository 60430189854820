import {
  Connection,
  ConnectionType,
  addConnections,
  deleteConnections,
  updateConnection,
  updateNote as updateNoteDispatch,
} from '../redux/slices/connectionsSlice';
import { store } from '../redux/store';
import { ProfileField } from '../types/Profile';
import { PicturePathResponse } from '../types/misc';
import httpService from './httpService';
import {
  CreatePersonalMomentConnectionsBody,
  UpdatePersonalMomentConnectionsBody,
} from './model/personalMomentConnectionService.model';

function createPersonalMomentConnections(connections: CreatePersonalMomentConnectionsBody[]) {
  if (!connections.length) return Promise.resolve();
  return httpService
    .post<Connection[]>('/business/connection/personal', connections)
    .then(({ data }) => {
      store.dispatch(addConnections(data));
    });
}

function updateNote(momentConnectionId: string, note: string) {
  return httpService
    .post(`/business/connection/personal/note`, {
      note,
      momentConnectionId: +momentConnectionId,
    })
    .then(() => {
      store.dispatch(
        updateNoteDispatch({
          id: momentConnectionId,
          type: ConnectionType.PERSONAL_MOMENT_CONNECTION,
          note,
        }),
      );
    });
}

function deletePersonalMomentConnections(momentConnectionIds: string[]) {
  if (!momentConnectionIds.length) return Promise.resolve();

  return httpService
    .delete('/business/connection/personal', {
      data: { momentConnectionIds: momentConnectionIds.map((x) => +x) },
    })
    .then(() => {
      store.dispatch(
        deleteConnections({
          ids: momentConnectionIds,
          type: ConnectionType.PERSONAL_MOMENT_CONNECTION,
        }),
      );
    });
}

function getProfilePictureUrl(userId: string): Promise<string> {
  return httpService
    .get<PicturePathResponse>(`/users/${userId}/avatar`)
    .then(({ data }): string => data.path);
}

function updatePersonalMomentConnections(bodies: UpdatePersonalMomentConnectionsBody[]) {
  if (!bodies.length) return Promise.resolve();

  return httpService.put<Connection[]>('/business/connection/personal', bodies).then(({ data }) => {
    data.forEach((connection) => store.dispatch(updateConnection(connection)));
  });
}

function getFieldsForPersonalMomentConnection(momentConnectionId: number) {
  return httpService
    .get<ProfileField[]>(`/business/connection/personal/${momentConnectionId}`)
    .then(({ data }) => data);
}

const personalMomentConnectionService = {
  createPersonalMomentConnections,
  updateNote,
  deletePersonalMomentConnections,
  getProfilePictureUrl,
  updatePersonalMomentConnections,
  getFieldsForPersonalMomentConnection,
};

export default personalMomentConnectionService;
