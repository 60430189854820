import React from 'react';
import { useSelector } from 'react-redux';
import { Connection, ConnectionType } from '../../redux/slices/connectionsSlice';
import { RootState } from '../../redux/reducers';

export default function useConnection(id: string, type: ConnectionType): Connection | undefined {
  return useSelector((state: RootState) =>
    state.connections.find((connection) => connection.id === id && connection.type === type),
  );
}
