import { Address } from '../types/Profile';
import { PostcardTemplate } from '../types/misc';
import httpService from './httpService';
import { DiscountResponse } from './model/orderService.model';

const orderService = {
  placeAddressLabelOrder: async (
    connectionIds: string[],
    localContactIds: string[],
    momentConnectionIds: string[],
    amount: number,
    addressRecipient: Address,
    clientSecret: string,
    code: string,
  ): Promise<void> => {
    await httpService.post('business/order/address-label', {
      connectionIds,
      localContactIds,
      momentConnectionIds,
      amount,
      addressRecipient,
      clientSecret,
      code,
    });
  },

  placePostcardOrder: async (
    connectionIds: string[],
    localContactIds: string[],
    momentConnectionIds: string[],
    message: string,
    senderAddress: Address,
    template: string,
    clientSecret: string,
    code: string,
  ): Promise<void> => {
    const sendDate = new Date(Date.now());
    await httpService.post('/business/order/postcard', {
      connectionIds,
      localContactIds,
      momentConnectionIds,
      message,
      sendDate,
      senderAddress,
      template,
      clientSecret,
      code,
    });
  },

  createPaymentIntent: async (amount: number): Promise<string> => {
    const res = await httpService.post('/payment-intent', { amount });
    return res.data.clientSecret;
  },

  getPostcardTemplates: (): Promise<PostcardTemplate[]> => {
    return httpService
      .get<PostcardTemplate[]>('/templates')
      .then(({ data }) => data.filter((t) => t.format === 'POSTCARD_SQ15'));
  },

  getDiscount: async (code: string): Promise<number> => {
    return httpService
      .get<DiscountResponse>(`/business/order/discount/${code}`)
      .then(({ data }) => data.discount);
  },
};

export default orderService;
