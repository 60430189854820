import React, { ComponentProps, useLayoutEffect, useState } from 'react';
import { FaArrowLeft, FaArrowRight, FaPencilAlt, FaRegClock } from 'react-icons/fa';
import { HiTrash } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';
import { AiOutlineClose, AiOutlinePlus } from 'react-icons/ai';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import StepIndicator from '../../components/misc/StepIndicator';
import Button from '../../components/buttons/Button';
import VeraPanel from '../../components/misc/VeraPanel';
import {
  CreateEventModel,
  DateOption,
  EventType,
  EventViewType,
  Frequency,
} from '../../../types/event';
import IconBadge from '../../components/icons/IconBadge';
import { eventTypeIcons } from '../../../utils/iconUtils';
import Slider from '../../components/misc/Slider';
import {
  CustomFieldType,
  CustomMultipleChoiceField,
} from '../../../services/model/inviteService.model';
import Labeled from '../../components/misc/Labeled';
import eventService from '../../../services/eventService';
import AutoCompleteLocationInput from '../../components/forms/AutoCompleteLocationInput';
import EventDatesForm, { EventDatesInfo } from '../../components/forms/EventDatesForm';
import ModalLayout from '../../components/layouts/ModalLayout';
import dateUtils from '../../../utils/dateUtils';
import eventUtils from '../../../utils/eventUtils';

interface CreateEventPageProps {
  type: EventViewType;
}
export default function CreateEventPage({ type }: CreateEventPageProps) {
  const [step, setStep] = useState<number>(0);
  const [event, setEvent] = useState<Partial<CreateEventModel>>({});

  const handleStep = (diff: 1 | -1) => setStep((prev) => prev + diff);

  const props: ViewProps = { setStep: handleStep, event, setEvent, type };

  useLayoutEffect(
    () => setEvent({ ...event, isDatePicker: type === EventViewType.DATE_PICKER }),
    [],
  );

  const steps = [
    <ChooseTypeView {...props} />,
    <FillDetailsView {...props} />,
    type === EventViewType.DATE_PICKER ? (
      <ChooseDateOptionsView {...props} />
    ) : (
      <ChooseDateView {...props} />
    ),
    <AdditionalDataView {...props} />,
  ];

  return (
    <div className="flex flex-1 flex-col w-full h-full">
      <StepIndicator
        steps={steps.length}
        currentStep={step}
        onStepChange={setStep}
        className="mb-6"
      />
      {steps[step]}
    </div>
  );
}

type CreateEventLayoutProps = {
  disabled?: boolean;
  showNext?: boolean;
  showPrevious?: boolean;
  setStep: (step: 1 | -1) => void;
  children: React.ReactNode;
} & ComponentProps<typeof VeraPanel>;

function CreateEventLayout({
  disabled = false,
  showNext = true,
  showPrevious = true,
  setStep,
  children,
  ...props
}: CreateEventLayoutProps) {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col w-full h-full">
      <VeraPanel {...props}>{children}</VeraPanel>
      <div className="flex w-full justify-end gap-2 py-4 mt-4 border-t border-primary-300">
        {showPrevious && (
          <Button variant="tertiary" onClick={() => setStep(-1)}>
            <FaArrowLeft className="w-5 h-5" />
            {t('general.back')}
          </Button>
        )}
        {showNext && (
          <Button variant="primary" disabled={disabled} onClick={() => !disabled && setStep(1)}>
            {t('general.continue')}
            <FaArrowRight className="w-5 h-5" />
          </Button>
        )}
      </div>
    </div>
  );
}

interface ViewProps {
  setStep: (step: 1 | -1) => void;
  event: Partial<CreateEventModel>;
  setEvent: (event: Partial<CreateEventModel>) => void;
  // eslint-disable-next-line react/no-unused-prop-types
  type: EventViewType;
}

function ChooseTypeView({ setStep, event, setEvent }: ViewProps) {
  const { t } = useTranslation();

  const setSelected = (type: EventType | undefined) => setEvent({ ...event, type });
  const selected = event.type;

  return (
    <CreateEventLayout
      title={t('page.createEvent.type.title')}
      subtitle={t('page.createEvent.type.subtitle')}
      setStep={setStep}
      showPrevious={false}
      disabled={!selected}>
      {Object.values(EventType).map((type) => (
        <div
          key={type}
          onClick={() => setSelected(type === selected ? undefined : type)}
          className={classNames(
            'rounded-[8px] cursor-pointer transition-all gap-2 justify-between border-secondary-200 border px-4 py-2 w-full flex',
            { 'bg-secondary-200': selected === type },
          )}>
          <IconBadge icon={eventTypeIcons[type]} className="rounded-[6px] p-1" />
          <div className="flex  items-center justify-between w-full">
            <h3 className="font-serif text-lg">
              {t(`page.createEvent.type.type.${type.toLowerCase()}.title`)}
            </h3>
            <input type="checkbox" checked={selected === type} />
          </div>
        </div>
      ))}
    </CreateEventLayout>
  );
}

function FillDetailsView({ setStep, event, setEvent }: ViewProps) {
  const { title, description } = event;

  const { t } = useTranslation();

  useLayoutEffect(() => {
    if (title && description) return;
    setEvent({ ...event, title: title ?? '', description: description ?? '' });
  }, []);

  return (
    <CreateEventLayout
      title={t('page.createEvent.info.title')}
      subtitle={t('page.createEvent.info.subtitle')}
      setStep={setStep}
      disabled={!title}>
      <Labeled label={`${t('general.title')}*`}>
        <input
          type="text"
          className="w-full"
          value={title}
          onChange={(e) => setEvent({ ...event, title: e.target.value })}
        />
      </Labeled>
      <Labeled label={t(`general.description`)}>
        <textarea
          className="w-full resize-none"
          value={description}
          onChange={(e) => setEvent({ ...event, description: e.target.value })}
        />
      </Labeled>
    </CreateEventLayout>
  );
}

function ChooseDateOptionsView({ setStep, event, setEvent }: ViewProps) {
  const { dateOptions } = event;

  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);

  useLayoutEffect(() => {
    if (dateOptions) return;
    setEvent({ ...event, dateOptions: [] });
  }, []);

  if (!dateOptions) return null;

  const handleOptionChange =
    (index: number) => (option: Omit<DateOption, 'id' | 'answers'> | undefined) => {
      const newOptions = [...dateOptions];
      if (!option) newOptions.splice(index, 1);
      else newOptions[index] = option;
      setEvent({
        ...event,
        dateOptions: eventUtils.sortDateOptions(newOptions as DateOption[], 'time'),
      });
    };

  return (
    <>
      <CreateEventLayout
        title={t('page.createEvent.dateOptions.title')}
        subtitle={t('page.createEvent.dateOptions.subtitle')}
        setStep={setStep}
        disabled={dateOptions?.length < 2}
        className="gap-4">
        <h2 className="text-xl font-medium">{t('page.createEvent.dateOptions.options')}</h2>
        {dateOptions.map((o, index) => (
          <DateOptionCard key={index} option={o} setOption={handleOptionChange(index)} />
        ))}
        <Button variant="tertiary" onClick={() => setOpen(true)}>
          <AiOutlinePlus className="w-5 h-5" />
          {t('page.createEvent.dateOptions.button')}
        </Button>
      </CreateEventLayout>
      <DateOptionsModal
        open={open}
        type="CREATE"
        setOpen={setOpen}
        setOptions={(options) =>
          setEvent({
            ...event,
            dateOptions: eventUtils.sortDateOptions(
              [...(dateOptions ?? []), ...options] as DateOption[],
              'time',
            ),
          })
        }
      />
    </>
  );
}

interface DateOptionCardProps {
  option: Omit<DateOption, 'id' | 'answers'>;
  setOption: (option: Omit<DateOption, 'id' | 'answers'> | undefined) => void;
}

function DateOptionCard({ option, setOption }: DateOptionCardProps) {
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  return (
    <>
      <div className="flex flex-col gap-2">
        <p className="text-xl font-serif">{eventUtils.formatDate(option)}</p>
        <div className="flex justify-between items-center">
          <div className="flex gap-2 items-center">
            <IconBadge icon={FaRegClock} className="rounded-[6px]" />
            <p className="text-sm">{eventUtils.formatDate(option, 'time')}</p>
          </div>
          <div className="flex gap-2">
            <Button
              onClick={() => setModalOpen(true)}
              className="rounded-full flex-shrink-0 p-3"
              variant="primary">
              <FaPencilAlt className="w-4 h-4" />
            </Button>
            <Button
              className="rounded-full flex-shrink-0 p-3"
              variant="secondary"
              onClick={() => setOption(undefined)}>
              <HiTrash className="w-4 h-4" />
            </Button>
          </div>
        </div>
      </div>
      <DateOptionsModal
        open={modalOpen}
        type="EDIT"
        setOpen={setModalOpen}
        setOption={setOption}
        option={option}
      />
    </>
  );
}

interface DateOptionsModalCreateProps {
  setOptions: (options: Omit<DateOption, 'id' | 'answers'>[]) => void;
  type: 'CREATE';
}

interface DateOptionsModalEditProps {
  option: Omit<DateOption, 'id' | 'answers'>;
  setOption: (option: Omit<DateOption, 'id' | 'answers'>) => void;
  type: 'EDIT';
}
type DateOptionsModalProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
} & (DateOptionsModalCreateProps | DateOptionsModalEditProps);

function DateOptionsModal({ open, setOpen, type, ...props }: DateOptionsModalProps) {
  const { option, setOption } = props as DateOptionsModalEditProps;
  const { setOptions } = props as DateOptionsModalCreateProps;

  const { t } = useTranslation();

  const [info, setInfo] = useState<EventDatesInfo | Omit<DateOption, 'id' | 'answers'>>();
  const [view, setView] = useState<'FORM' | 'DATE'>('DATE');

  useLayoutEffect(() => {
    if (!open) return;
    setInfo(type === 'CREATE' ? { isRecurring: false } : option);
  }, [open]);

  const handleCreate = () => {
    if (type !== 'CREATE') throw new Error('Cannot create a date option in edit mode');
    const {
      frequency,
      recurrenceEndDate,
      startTime: startTimeFirst,
      endTime: endTimeFirst,
    } = info as EventDatesInfo;
    if (!startTimeFirst) return;

    const startTimes = dateUtils.getDatesBetween(
      startTimeFirst,
      recurrenceEndDate ?? startTimeFirst,
      frequency ?? Frequency.DAILY,
    );

    const difference = endTimeFirst ? endTimeFirst.getTime() - startTimeFirst.getTime() : undefined;

    const dateOptions = startTimes.map((startTime) => {
      const endTime = difference ? new Date(startTime.getTime() + difference) : undefined;
      return { startTime, endTime };
    });

    setOptions(dateOptions);
    setOpen(false);
  };

  const handleEdit = () => {
    if (type !== 'EDIT') throw new Error('Cannot edit a date option in create mode');
    setOption(info as Omit<DateOption, 'id' | 'answers'>);
    setOpen(false);
  };

  return (
    <ModalLayout
      open={open}
      setOpen={setOpen}
      closeButton
      className={classNames('bg-secondary-50 flex flex-col mx-6 z-50 rounded-[20px] gap-6', {
        'px-12 py-8 w-full min-w-[450px] max-w-[600px]': view === 'FORM',
      })}>
      <EventDatesForm
        className=""
        info={info as unknown as EventDatesInfo}
        setInfo={setInfo}
        staticModal
        includeRecurrence={type === 'CREATE'}
        setView={setView}
      />
      {view === 'FORM' && (
        <Button
          disabled={!info?.startTime}
          onClick={type === 'CREATE' ? handleCreate : handleEdit}
          variant="primary"
          className="w-full justify-center ">
          {t(`component.modal.dateOptions.${type.toLowerCase()}`)}
        </Button>
      )}
    </ModalLayout>
  );
}

function ChooseDateView({ setStep, event, setEvent }: ViewProps) {
  const { startTime, isRecurring } = event;

  const { t } = useTranslation();

  useLayoutEffect(() => {
    if (startTime && isRecurring) return;
    setEvent({ ...event, isRecurring: isRecurring ?? false });
  }, []);

  return (
    <CreateEventLayout
      title={t('page.createEvent.date.title')}
      subtitle={t('page.createEvent.date.subtitle')}
      setStep={setStep}
      disabled={startTime === undefined}
      className="gap-4">
      <EventDatesForm
        info={event as EventDatesInfo}
        setInfo={(info) => setEvent({ ...event, ...info })}
      />
    </CreateEventLayout>
  );
}

function AdditionalDataView({ setStep, event, setEvent, type }: ViewProps) {
  const { customFields, location } = event;

  const navigate = useNavigate();
  const { t } = useTranslation();

  const [addLocation, setAddLocation] = useState<boolean>(false);

  useLayoutEffect(() => {
    if (customFields && location) return;
    setEvent({ ...event, customFields: customFields ?? [], location: location ?? '' });
  }, []);

  const handleSubmit = () => {
    eventService
      .createEvent(event as CreateEventModel)
      .then(() => navigate(`/events${type === EventViewType.DATE_PICKER ? '/date-picker' : ''}`));
  };

  const disabled = customFields?.some((f) => !f.label || f.options.some((o) => !o));

  return (
    <div className="flex flex-col w-full h-full">
      <VeraPanel
        title={t('page.createEvent.additional.title')}
        subtitle={t('page.createEvent.additional.subtitle')}>
        <div className="flex flex-col gap-1 border-b border-secondary-200 pb-6 mb-4">
          <h2 className="text-xl font-medium">{t('page.createEvent.additional.location')}</h2>
          {!addLocation ? (
            <Button variant="tertiary" onClick={() => setAddLocation(true)}>
              <AiOutlinePlus className="w-5 h-5" />
              {t('page.createEvent.additional.addLocation')}
            </Button>
          ) : (
            <div className="flex gap-2 items-center">
              <AutoCompleteLocationInput
                location={location ?? ''}
                setLocation={({ location: loc, locationUrl }) =>
                  setEvent({ ...event, location: loc, locationUrl })
                }
              />
              <Button
                variant="tertiary"
                className="p-3 flex-shrink-0 rounded-full"
                onClick={() => {
                  setAddLocation(false);
                  setEvent({ ...event, location: '', locationUrl: undefined });
                }}>
                <HiTrash className="w-5 h-5" />
              </Button>
            </div>
          )}
        </div>
        <div className="flex flex-col gap-1">
          <h2 className="text-xl font-medium">{t('page.createEvent.additional.poll')}</h2>
          <EventPollForm
            fields={customFields ?? []}
            setFields={(fields) => setEvent({ ...event, customFields: fields })}
          />
        </div>
      </VeraPanel>{' '}
      <div className="flex w-full justify-end gap-2 py-4 mt-4 border-t border-primary-300">
        <Button variant="tertiary" onClick={() => setStep(-1)}>
          <FaArrowLeft className="w-5 h-5" />
          {t('general.back')}
        </Button>
        <Button variant="primary" disabled={disabled} onClick={handleSubmit}>
          {t('general.create')}
          <FaArrowRight className="w-5 h-5" />
        </Button>
      </div>
    </div>
  );
}

interface EventPollFormProps {
  fields: CustomMultipleChoiceField[];
  setFields: (fields: CustomMultipleChoiceField[]) => void;
}

function EventPollForm({ fields, setFields }: EventPollFormProps) {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col w-full gap-6">
      {fields.map((field, index) => (
        <MultipleChoiceForm
          key={index}
          field={field}
          setField={(newField) => {
            const newFields = [...fields];

            if (!newField) newFields.splice(index, 1);
            else newFields[index] = newField;

            setFields(newFields);
          }}
        />
      ))}
      <Button
        variant="tertiary"
        onClick={() =>
          setFields([
            ...fields,
            {
              label: '',
              mandatory: false,
              options: ['', ''],
              type: CustomFieldType.MULTIPLE_CHOICE,
            },
          ])
        }>
        <AiOutlinePlus className="h-5 w-5" />
        {t('general.add')}
      </Button>
    </div>
  );
}

interface MultipleChoiceFormProps {
  field: CustomMultipleChoiceField | undefined;
  setField: (field: CustomMultipleChoiceField | undefined) => void;
}

function MultipleChoiceForm({ field, setField }: MultipleChoiceFormProps) {
  const { t } = useTranslation();
  if (!field) return null;

  const { label, options, mandatory } = field;

  return (
    <div className="flex flex-col w-full gap-4">
      <Labeled label={t('component.eventPollForm.question')}>
        <div className="flex gap-2">
          <input
            type="text"
            value={label}
            onChange={(e) => setField({ ...field, label: e.target.value })}
          />
          <Button
            variant="tertiary"
            className="rounded-full p-3 flex-shrink-0"
            onClick={() => setField(undefined)}>
            <HiTrash className="w-5 h-5" />
          </Button>
        </div>
      </Labeled>
      <Labeled label={`${t('general.mandatory')}?`}>
        <Slider
          state={mandatory}
          handleToggle={() => setField({ ...field, mandatory: !mandatory })}
        />
      </Labeled>
      <Labeled label={t('component.eventPollForm.options')}>
        <div className="flex flex-col gap-2">
          {options.map((option, index) => (
            <div className="flex gap-2 items-center">
              <input
                key={index}
                type="text"
                value={option}
                onChange={(e) => {
                  const newOptions = [...options];
                  newOptions[index] = e.target.value;
                  setField({ ...field, options: newOptions });
                }}
              />
              <Button
                className="rounded-full p-3 flex-shrink-0"
                disabled={options.length <= 2}
                onClick={() =>
                  setField({ ...field, options: options.filter((_, i) => i !== index) })
                }>
                <AiOutlineClose className="w-4 h-4" />
              </Button>
            </div>
          ))}
        </div>
      </Labeled>
      <Button
        className="p-0 underline"
        onClick={() => {
          setField({ ...field, options: [...options, ''] });
        }}>
        {t('component.eventPollForm.addOption')}
      </Button>
    </div>
  );
}
