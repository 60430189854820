import React, { useState } from 'react';
import { toast } from 'react-toastify';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import { businessTypeToString } from '../../../utils/stringUtils';
import useSelectedBusiness from '../../../hooks/business/useSelectedBusiness';
import { createEmptyBusinessInvite } from '../../../utils/formatUtils';
import {
  BusinessInvite,
  CustomFieldType,
  CustomMultipleChoiceField,
} from '../../../services/model/inviteService.model';
import InviteForm from '../../components/forms/InviteForm';
import inviteService from '../../../services/inviteService';
import businessService from '../../../services/businessService';
import { filterCustomLabelFields } from '../../../utils/filterUtils';
import connectionService from '../../../services/connectionService';
import useBusinessTranslation from '../../../hooks/useBusinessTranslation';
import Button from '../../components/buttons/Button';
import InvitationLinkPanel from '../../components/panels/InvitationLinkPanel';
import useBusinessEffect from '../../../hooks/effects/useBusinessEffect';
import { isCommunity } from '../../../constants';

export default function RequestedDataPage(): JSX.Element {
  const [invite, setInvite] = useState<BusinessInvite>(createEmptyBusinessInvite());
  const [action, setAction] = useState<'CREATE' | 'UPDATE'>('CREATE');
  const { message, customFields } = invite;

  const business = useSelectedBusiness();
  const navigate = useNavigate();
  const { t } = useBusinessTranslation();

  useBusinessEffect(() => {
    const fetchInvite = async () => {
      const fields = await inviteService.fetchInvite();
      if (!fields) return;
      setInvite(fields);
      setAction('UPDATE');
    };
    fetchInvite();
    connectionService.getConnections();
  }, []);

  const handleSubmit = async (): Promise<void> => {
    if (!message) {
      toast.error(t('toast.error.personalMessage'));
      return;
    }
    const labelFields = filterCustomLabelFields(customFields);
    if (
      labelFields.some((field): boolean => !field.label) ||
      customFields
        .filter((x) => x.type === CustomFieldType.MULTIPLE_CHOICE)
        .map((x) => (x as CustomMultipleChoiceField).options)
        .flat()
        .some((option): boolean => !option)
    ) {
      toast.error(t('toast.error.allFields'));
      return;
    }
    if (
      Array.from(new Set(labelFields.map((field) => field.label))).length !== labelFields.length
    ) {
      toast.error(t('toast.error.noTwoSame'));
      return;
    }

    if (action === 'CREATE') await inviteService.createInvite(invite);
    if (action === 'UPDATE')
      await inviteService.updateInvite(invite).then(() => connectionService.getConnections(true));

    await businessService.fetchBusinesses();

    toast.success(
      action === 'UPDATE' ? t('toast.success.inviteUpdated') : t('toast.success.inviteCreated'),
    );
    if (isCommunity && action === 'CREATE') navigate('/invite-link', { state: { view: 'SETUP' } });
    else navigate('/dashboard');
  };

  const renderSecondPanel = (): JSX.Element => {
    if (!business?.inviteId) return <div />;
    return <InvitationLinkPanel />;
  };

  return (
    <>
      {/* Header */}
      <div className="flex flex-col border-b border-primary-300 pb-6 mb-6">
        <h1 className="font-semibold font-serif text-3xl mb-2">
          {t('page.settings.requested.title')}
        </h1>
        <p className="lg:w-1/2">
          {business?.inviteId
            ? t('page.settings.requested.subtitle')
            : t('page.settings.subtitle', { type: businessTypeToString(business?.type!) })}
        </p>
      </div>

      <div className="flex gap-6 lg:flex-row flex-col">
        <div className="w-full pb-4">
          <InviteForm invite={invite} setInvite={setInvite} />
        </div>
        <div className="w-full flex flex-col justify-between">
          <div className="w-full lg:block hidden">{renderSecondPanel()}</div>
          <div className="flex lg:justify-end w-full mb-4 lg:mt-0 border-b border-secondary-200 lg:border-b-0 pb-4 lg:pb-0 lg:mb-0">
            <Button
              onClick={() => setInvite(business?.inviteFields || createEmptyBusinessInvite())}
              className="disabled:opacity-20 px-0 underline font-medium">
              {t('general.cancel')}
            </Button>
            <Button variant="primary" onClick={handleSubmit} className="disabled:opacity-20 ml-6">
              {t('general.save')}
            </Button>
          </div>
          <div className="lg:hidden w-full">{renderSecondPanel()}</div>
        </div>
      </div>
    </>
  );
}
