import React, { ComponentProps, useEffect, useLayoutEffect, useState } from 'react';
import _ from 'lodash';
import { useLocation, useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { FiCalendar, FiMapPin } from 'react-icons/fi';
import { FaBell, FaCheck, FaChevronDown, FaThumbtack, FaUserMinus } from 'react-icons/fa6';
import classNames from 'classnames';
import { twMerge } from 'tailwind-merge';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { AiOutlineClose } from 'react-icons/ai';
import { HiTrash } from 'react-icons/hi';
import { FaEllipsisH, FaPencilAlt, FaRegClock, FaSave } from 'react-icons/fa';
import { HiUserGroup } from 'react-icons/hi2';
import Button from '../../components/buttons/Button';
import useEvents from '../../../hooks/business/useEvents';
import IconBadge from '../../components/icons/IconBadge';
import {
  CustomEventMulipleChoiceField,
  DateOption,
  Event,
  EventParticipant,
  EventParticipationAnswer,
  EventViewType,
  RecurrenceEditType,
  UpdateEventModel,
} from '../../../types/event';
import Avatar from '../../components/misc/Avatar';
import WarningModal from '../../components/modals/WarningModal';
import eventService from '../../../services/eventService';
import Labeled from '../../components/misc/Labeled';
import EventDatesForm from '../../components/forms/EventDatesForm';
import AutoCompleteLocationInput from '../../components/forms/AutoCompleteLocationInput';
import HoverButton from '../../components/buttons/HoverButton';
import { eventParticipantAnswerIcons } from '../../../utils/iconUtils';
import Icon from '../../components/icons/Icon';
import ProgressBar from '../../components/misc/ProgressBar';
import ModalLayout from '../../components/layouts/ModalLayout';
import eventUtils from '../../../utils/eventUtils';

interface EventDetailsPageState {
  eventId?: number;
}

interface EventDetailsPageProps {
  type: EventViewType;
}

export default function EventDetailsPage({ type }: EventDetailsPageProps): JSX.Element | null {
  const { eventId } = useLocation().state as EventDetailsPageState;
  const navigate = useNavigate();
  const event = useEvents().find((e) => e.id === eventId);

  const [view, setView] = useState<'DETAILS' | 'EDIT'>('DETAILS');

  const returnUrl = `/events${type === EventViewType.DATE_PICKER ? '/date-picker' : ''}`;

  useEffect(() => {
    if (!eventId) navigate(returnUrl);
    else eventService.fetchEvent(eventId).catch(() => navigate(returnUrl));
  }, []);

  useEffect(() => {
    if (!event) return;
    if (
      (type === EventViewType.DATE_PICKER && !event.isDatePicker) ||
      (type === EventViewType.REGULAR && event.isDatePicker)
    )
      navigate(returnUrl);
  }, [event]);

  if (!event) return null;

  return (
    <div className="flex flex-col lg:flex-row gap-4">
      <div className="flex flex-col gap-4 w-full">
        {view === 'DETAILS' ? (
          <DetailsPanel event={event} setView={setView} type={type} />
        ) : (
          <EditEventPanel event={event} setView={setView} type={type} />
        )}
        {type === EventViewType.DATE_PICKER && <DateOptionsPanel options={event.dateOptions} />}
        <PollsPanel polls={event.customFields} />
      </div>

      {type === EventViewType.REGULAR ? (
        <EventParticipantsPanel event={event} />
      ) : (
        <DatePickerResponsesOverviewPanel event={event} />
      )}
    </div>
  );
}

// EVENT
interface DetailsPanelProps {
  event: Event;
  setView: (view: 'DETAILS' | 'EDIT') => void;
  type: EventViewType;
}
function DetailsPanel({ event, setView, type }: DetailsPanelProps) {
  const { title, description, startTime, endTime, location, locationUrl, id } = event;
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [modalOpen, setModalOpen] = useState(false);

  const handleDelete = (deleteRecurrences: RecurrenceEditType = RecurrenceEditType.SINGLE) => {
    eventService.deleteEvent(id, deleteRecurrences).then(() => {
      navigate('/events');
    });
  };

  return (
    <>
      <div className="bg-secondary-200 h-fit rounded-[20px] gap-4 p-4 flex flex-col w-full">
        <div className="flex justify-between items-center">
          <h1 className="text-[28px] leading-[28px] font-serif">{title}</h1>
          <div className="flex gap-4">
            <Button
              className="rounded-full flex-shrink-0 p-3"
              variant="primary"
              onClick={() => setView('EDIT')}>
              <FaPencilAlt className="h-5 w-5" />
            </Button>
            <Button
              className="rounded-full flex-shrink-0 p-3"
              variant="secondary"
              onClick={() => setModalOpen(true)}>
              <HiTrash className="w-5 h-5" />
            </Button>
          </div>
        </div>
        <div className="border-b w-full border-primary-300" />
        <div className="flex flex-col w-full gap-2">
          <h2 className="font-serif text-[18px] leading-[24px]">{t('general.information')}</h2>
          {type === EventViewType.REGULAR ? (
            <>
              <div className="flex gap-2 items-center">
                <IconBadge icon={FiCalendar} className="rounded-[6px]" />
                <p className="text-sm">
                  {`${format(startTime, 'dd-MM-yyyy')}${
                    endTime ? ` -> ${format(startTime, 'dd-MM-yyyy')}` : ''
                  }`}
                </p>
              </div>
              <div className="flex gap-2 items-center">
                <IconBadge icon={FaRegClock} className="rounded-[6px]" />
                <p className="text-sm">{eventUtils.formatDate(event, 'time')}</p>
              </div>
            </>
          ) : (
            <>
              <div className="flex gap-2 items-center">
                <IconBadge icon={FiCalendar} className="rounded-[6px]" />
                <p className="text-sm">
                  {`${format(event.dateOptions[0]?.startTime, 'dd-MM-yyyy')} + ${
                    event.dateOptions.length
                  } ${t('general.options')}`}
                </p>
              </div>
              <div className="flex gap-2 items-center">
                <IconBadge icon={HiUserGroup} className="rounded-[6px]" />
                <p className="text-sm">{`${
                  event.participants.filter((p) =>
                    p.answers?.some((a) => a.answer !== EventParticipationAnswer.PENDING),
                  ).length
                } / ${event.memberCount}`}</p>
              </div>
            </>
          )}
          {location && (
            <a
              href={locationUrl}
              target="_blank"
              rel="noreferrer"
              className="flex gap-2 items-center">
              <IconBadge icon={FiMapPin} className="rounded-[6px]" />
              <p className="text-sm">{location}</p>
            </a>
          )}
          {description && (
            <div className="flex flex-col w-full">
              <h2 className="font-serif text-[18px] leading-[24px]">{t('general.description')}</h2>
              <p className="text-sm">{description}</p>
            </div>
          )}
        </div>
        {type === EventViewType.DATE_PICKER && (
          <>
            <div className="border-b w-full border-primary-300 " />
            <PickDateButton event={event} />
          </>
        )}
      </div>
      <WarningModal
        title={t('warning.deleteEvent.title')}
        description={t('warning.deleteEvent.description')}
        button={t('general.delete')}
        open={modalOpen && !event.isRecurring}
        setOpen={setModalOpen}
        onClose={handleDelete}
      />
      <ModalLayout
        open={modalOpen && event.isRecurring}
        setOpen={setModalOpen}
        closeButton
        className="bg-secondary-50 min-w-[450px] flex flex-col max-w-[600px] py-8 mx-6 z-50 rounded-[20px] px-6 lg:px-12 gap-4 justify-center">
        <div className="flex flex-col mb-4 gap-2">
          <h3 className="text-xl font-serif font-semibold">{t('warning.deleteRecurringEvent.title')}</h3>
          <p className="text">{t('warning.deleteRecurringEvent.description')}</p>
        </div>
        {Object.values(RecurrenceEditType).map((r) => (
          <Button
            variant="primary"
            className="w-full justify-center"
            onClick={() => handleDelete(r)}>
            {t(`warning.deleteRecurringEvent.${r.toLowerCase()}`)}
          </Button>
        ))}
        <Button className="underline w-full justify-center" onClick={() => setModalOpen(false)}>
          {t('general.cancel')}
        </Button>
      </ModalLayout>
    </>
  );
}

interface PickDateButtonProps {
  event: Event;
}

function PickDateButton({ event }: PickDateButtonProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedIds, setSelectedIds] = useState<number[]>([]);

  useLayoutEffect(() => {
    if (modalOpen) setSelectedIds([]);
  }, [modalOpen]);

  const handleClick = (id: number) => {
    if (selectedIds.includes(id)) setSelectedIds(selectedIds.filter((i) => i !== id));
    else setSelectedIds([...selectedIds, id]);
  };

  const handleSubmit = () => {
    eventService.pickDateOptions(event.id, selectedIds).then(() => {
      setModalOpen(false);
      navigate('/events');
    });
  };

  return (
    <>
      <div
        onClick={() => setModalOpen(true)}
        className="flex cursor-pointer justify-between hover:bg-opacity-80 items-center rounded-[20px] bg-primary-300 w-full p-4">
        <div className="flex gap-4">
          <img
            src={`${process.env.PUBLIC_URL}/assets/figures/question.svg`}
            alt="Vera Question"
            className="w-[50px]"
          />
          <div className="w-full flex flex-col my-auto">
            <h2 className="text-[18px] leading-[24px]">{t('page.eventDetails.pickDate.title')}</h2>
            <p className="text-sm">{t('page.eventDetails.pickDate.subtitle')} </p>
          </div>
        </div>
        <div className="flex rounded-full p-3 flex-shrink-0 bg-primary w-fit h-fit">
          <FaThumbtack className="w-5 h-5 text-secondary-50" />
        </div>
      </div>
      <ModalLayout
        open={modalOpen}
        setOpen={setModalOpen}
        closeButton
        className="bg-secondary-50 min-w-[450px] flex flex-col max-w-[600px] py-8 mx-6 z-50 rounded-[20px] px-6 lg:px-12 gap-6 justify-center">
        {eventUtils.sortDateOptions(event.dateOptions).map((o) => (
          <div className="flex gap-4 items-center">
            <DateOptionResult option={o} />
            <Button
              onClick={() => handleClick(o.id)}
              className={`w-10 h-10 justify-center rounded-[6px] hover:border-opacity-80 border-2 border-primary-300 ${
                selectedIds.includes(o.id)
                  ? 'bg-primary-300 text-primary-900 hover:bg-opacity-80'
                  : 'text-primary-300 hover:text-opacity-80'
              }`}>
              <FaThumbtack className="w-5 h-5 flex-shrink-0" />
            </Button>
          </div>
        ))}
        <Button
          className="w-full justify-center"
          variant="primary"
          disabled={!selectedIds.length}
          onClick={handleSubmit}>
          {t('page.eventDetails.pickDate.button')}
        </Button>
      </ModalLayout>
    </>
  );
}

interface EditEventPanelProps {
  event: Event;
  setView: (view: 'DETAILS' | 'EDIT') => void;
  type: EventViewType;
}

const NOT_ALLOWED_FIELDS = [
  'isCancelled',
  'additionalData',
  'businessId',
  'memberCount',
  'ownerId',
  'role',
  'isRecurring',
  'recurrenceBatchId',
  'frequency',
  'isDatePicker',
  'participants',
  'dateOptions',
  'customFields',
  'organizer',
  'notificationSettings',
];

function EditEventPanel({ event: originalEvent, setView, type }: EditEventPanelProps) {
  const [addLocation, setAddLocation] = useState<boolean>(!!originalEvent.location);
  const [event, setEvent] = useState<Event>(originalEvent);

  const { title, description, location } = event;

  const { t } = useTranslation();

  const handleSubmit = () => {
    const data = _.omit(event, NOT_ALLOWED_FIELDS);
    data.locationUrl = event.locationUrl ?? undefined;
    data.endTime = event.endTime ?? undefined;
    eventService.updateEvent(originalEvent.id, data as UpdateEventModel).then(() => {
      setView('DETAILS');
      toast.success(t('toast.success.eventUpdated'));
    });
  };

  return (
    <div className="bg-secondary-200 h-fit rounded-[20px] gap-4 p-4 flex flex-col w-full">
      <div className="flex justify-between items-center">
        <h1 className="text-[28px] leading-[28px] font-serif">{originalEvent.title}</h1>
        <div className="flex gap-4">
          <Button
            className="rounded-full flex-shrink-0 p-3"
            variant="primary"
            disabled={_.isEqual(event, originalEvent)}
            onClick={handleSubmit}>
            <FaSave className="h-5 w-5" />
          </Button>
          <Button className="rounded-full flex-shrink-0 p-3" variant="secondary">
            <AiOutlineClose className="w-5 h-5" onClick={() => setView('DETAILS')} />
          </Button>
        </div>
      </div>
      <div className="border-b w-full border-primary-300" />
      <div className="bg-secondary-50 rounded-[20px] border border-primary-300 p-4 gap-4 flex flex-col">
        <Labeled label={t('general.title')}>
          <input
            type="text"
            className="w-full"
            value={title}
            onChange={(e) => setEvent({ ...event, title: e.target.value })}
          />
        </Labeled>
        <Labeled label={t(`general.description`)}>
          <textarea
            className="w-full resize-none"
            value={description}
            onChange={(e) => setEvent({ ...event, description: e.target.value })}
          />
        </Labeled>
        {type === EventViewType.REGULAR && (
          <EventDatesForm
            info={event}
            setInfo={(info) => setEvent({ ...event, ...info })}
            includeRecurrence={false}
          />
        )}
        {!addLocation ? (
          <Button className="underline px-0 py-0" onClick={() => setAddLocation(true)}>
            Voeg Locatie toe
          </Button>
        ) : (
          <Labeled label={t('general.location')}>
            <div className="flex gap-4 items-center">
              <AutoCompleteLocationInput
                location={location ?? ''}
                setLocation={(l) => setEvent({ ...event, ...l })}
              />
              <Button
                variant="tertiary"
                className="p-3 flex-shrink-0 rounded-full"
                onClick={() => {
                  setEvent({ ...event, location: '', locationUrl: undefined });
                  setAddLocation(false);
                }}>
                <HiTrash className="w-5 h-5" />
              </Button>
            </div>
          </Labeled>
        )}
      </div>
    </div>
  );
}

// PARTICIPANTS
interface ParticipantsPanelProps {
  event: Event;
}

function EventParticipantsPanel({ event }: ParticipantsPanelProps) {
  const { t } = useTranslation();
  const getOnAnswer = (answer: EventParticipationAnswer) =>
    event.participants.filter((p) => p.answer === answer);

  return (
    <div className="flex flex-col w-full gap-4 lg:p-4">
      <h1 className="text-[28px] leading-[28px] font-serif">
        {t('page.eventDetails.participants')}
      </h1>
      <ParticipationAnswersOverview answers={_.countBy(event.participants, 'answer')} />
      {Object.values(EventParticipationAnswer).map((type) => {
        const participants = getOnAnswer(type);
        if (!participants.length) return null;
        return (
          <div className="flex flex-col gap-4">
            <p className="font-medium text-primary">{`${participants.length} ${t(
              `page.eventDetails.answerTitle.${type.toLowerCase()}`,
            )}`}</p>
            {participants.map((p) => (
              <ParticipantCard key={p.id} eventId={event.id} participant={p} />
            ))}
          </div>
        );
      })}
    </div>
  );
}

interface ParticipationAnswersOverviewProps {
  answers: { [key in EventParticipationAnswer]?: number };
}

function ParticipationAnswersOverview({ answers }: ParticipationAnswersOverviewProps) {
  return (
    <div className="flex flex-wrap gap-4">
      {Object.values(EventParticipationAnswer).map((type) => (
        <div
          className={classNames('flex gap-2 items-center text-sm flex-shrink-0', {
            'opacity-25': !answers[type],
          })}>
          <ParticipationBadge type={type} />
          <p className="text-sm flex-shrink-0">{answers[type] ?? 0}</p>
        </div>
      ))}
    </div>
  );
}

type ParticipantCardProps = {
  participant: EventParticipant;
} & (
  | { includeButton?: true; eventId: number; badge?: never }
  | { includeButton: false; eventId?: never; badge?: 'avatar' | 'right' }
);

function ParticipantCard({
  participant: { picture, firstName, lastName, answer, description, id },
  includeButton = true,
  badge = 'avatar',
  eventId,
}: ParticipantCardProps) {
  return (
    <div className="w-full items-center flex justify-between">
      <div className="flex items-center gap-4">
        <div className="relative">
          <Avatar className="w-12 h-12" src={picture} alias={`${firstName}+${lastName}`} />
          {answer && badge === 'avatar' && (
            <ParticipationBadge
              type={answer}
              className="absolute -bottom-2 -right-2 border border-secondary-50"
            />
          )}
        </div>
        <div className="flex flex-col">
          <p>{`${firstName} ${lastName}`}</p>
          {description && <p className="italic text-xs">“{description}”</p>}
        </div>
      </div>
      {includeButton && (
        <ParticipantButton eventId={eventId!} participantId={id} answer={answer!} />
      )}
      {answer && badge === 'right' && <ParticipationBadge type={answer} />}
    </div>
  );
}

interface ParticipantButtonProps {
  eventId: number;
  participantId: number;
  answer: EventParticipationAnswer;
}

function ParticipantButton({ eventId, participantId, answer }: ParticipantButtonProps) {
  const [warningOptions, setWarningOptions] = useState<{
    title: string;
    description: string;
    button: string;
    onClose: () => void;
    open: boolean;
  } | null>(null);
  const { t } = useTranslation();

  const [view, setView] = useState<'OPTIONS' | 'PRESENCE'>('OPTIONS');
  const [presence, setPresence] = useState<EventParticipationAnswer | undefined>(answer);

  const handleSelectPresence = () => {
    eventService.setAttendance(eventId, participantId, presence!);
  };

  const options = (
    <>
      <Button
        className="text-secondary-50 hover:bg-opacity-20 hover:bg-primary-300 w-full"
        onClick={() =>
          setWarningOptions({
            title: t('warning.remindUserEvent.title'),
            description: t('warning.remindUserEvent.description'),
            button: t('general.send'),
            open: true,
            onClose: () =>
              eventService.remindUserForEvent(eventId, participantId).then(() => {
                toast.success(t('toast.success.remindedUser'));
              }),
          })
        }>
        <FaBell className="w-5 h-5 flex-shrink-0" />
        {t('page.eventDetails.remind')}
      </Button>
      <Button
        className="text-secondary-50 hover:bg-opacity-20 hover:bg-primary-300 w-full"
        onClick={() => setView('PRESENCE')}>
        <FaCheck className="w-5 h-5 flex-shrink-0" />
        {t('page.eventDetails.changePresence')}
      </Button>
      <Button
        className="text-secondary-50 hover:bg-opacity-20 hover:bg-primary-300 w-full"
        onClick={() =>
          setWarningOptions({
            title: t('warning.removeUserEvent.title'),
            description: t('warning.removeUserEvent.description'),
            button: t('general.delete'),
            open: true,
            onClose: () => eventService.deleteParticipant(eventId, participantId),
          })
        }>
        <FaUserMinus className="w-5 h-5 flex-shrink-0" />
        {t('page.eventDetails.deleteUser')}
      </Button>
    </>
  );

  const presencePicker = (
    <div className="flex flex-col justify-center items-center gap-4">
      <div className="flex gap-4 justify-center w-full">
        {Object.values(_.omit(EventParticipationAnswer, EventParticipationAnswer.PENDING)).map(
          (a) => (
            <Button
              onClick={() => setPresence(presence === a ? undefined : a)}
              className={`p-3 rounded-lg hover:border-opacity-80  border-2 border-primary-300 ${
                presence === a
                  ? 'bg-primary-300 text-primary-900 hover:bg-opacity-80'
                  : 'text-primary-300 hover:text-opacity-80'
              }`}>
              <Icon icon={eventParticipantAnswerIcons[a]} className="w-6 h-6" />
            </Button>
          ),
        )}
      </div>
      <Button
        onClick={handleSelectPresence}
        variant="secondary"
        disabled={!presence || presence === answer}
        className="w-full justify-center">
        {t('page.eventDetails.editPresence')}
      </Button>
    </div>
  );

  return (
    <>
      <HoverButton
        hoverable={false}
        onClose={() => {
          setView('OPTIONS');
          setPresence(answer);
        }}>
        <HoverButton.Button>
          <FaEllipsisH className="w-5 h-5" />
        </HoverButton.Button>
        <HoverButton.Div className="bg-primary-900 transition-all right-7 top-0 min-w-[270px] border border-primary-300 w-full p-3 rounded-[28px] flex flex-col ">
          {view === 'OPTIONS' ? options : presencePicker}
        </HoverButton.Div>
      </HoverButton>
      <WarningModal
        {...(warningOptions ?? { title: '', description: '', open: false })}
        setOpen={() => setWarningOptions({ ...warningOptions!, open: false })}
      />
    </>
  );
}

type ParticipationBadgeProps = {
  type: EventParticipationAnswer;
} & Omit<ComponentProps<typeof IconBadge>, 'icon'>;

function ParticipationBadge({ type, className, iconClassName }: ParticipationBadgeProps) {
  const classNamesMap = {
    [EventParticipationAnswer.YES]: 'text-primary-900 bg-primary-300',
    [EventParticipationAnswer.MAYBE]: 'text-secondary-50 bg-primary-900',
    [EventParticipationAnswer.NO]: 'text-primary-900',
    [EventParticipationAnswer.PENDING]: 'text-primary-900 bg-secondary-200',
  };

  return (
    <IconBadge
      icon={eventParticipantAnswerIcons[type]}
      className={twMerge('rounded-[6px] w-6 h-6', classNamesMap[type], className)}
      iconClassName={twMerge('w-4 h-4', iconClassName)}
    />
  );
}

//  POLLS
interface PollsPanelProps {
  polls: CustomEventMulipleChoiceField[];
}

function PollsPanel({ polls }: PollsPanelProps) {
  const { t } = useTranslation();
  if (!polls.length) return null;
  return (
    <div className="flex flex-col gap-2">
      <h1 className="text-[28px] leading-[28px] font-serif">{t('page.eventDetails.polls')}</h1>
      <div className="flex flex-col gap-8">
        {polls.map((poll) => (
          <PollResult key={poll.customFieldId} poll={poll} />
        ))}
      </div>
    </div>
  );
}

interface PollResultProps {
  poll: CustomEventMulipleChoiceField;
}

function PollResult({ poll }: PollResultProps) {
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  return (
    <>
      <div className="flex flex-col gap-3">
        <div className="flex justify-between">
          <p className="font-medium text-primary">{poll.label}</p>
          <p className="font-medium text-primary">
            {poll.responses.length}{' '}
            {t('page.eventDetails.reactions', { count: poll.responses.length })}
          </p>
        </div>
        {poll.options.map((o) => {
          const responses = poll.responses.filter((r) => r.response === o);
          const numRes = responses.length;
          const percentage = (numRes / poll.responses.length) * 100;
          return (
            <div className="flex flex-col gap-2">
              <div className="flex justify-between">
                <p className="text-sm"> {o} </p>
                <p className="text-sm"> {numRes} </p>
              </div>
              <ProgressBar progress={percentage} variant="secondary" />
            </div>
          );
        })}
        {!!poll.responses.length && (
          <Button className="p-0 underline" onClick={() => setModalOpen(true)}>
            {t('page.eventDetails.viewAnswers')}
          </Button>
        )}
      </div>
      <ModalLayout
        closeButton
        open={modalOpen}
        setOpen={setModalOpen}
        className="bg-secondary-50 min-w-[450px] flex flex-col pb-4 max-w-[600px] py-8 mx-6 z-50 rounded-[20px] px-12 gap-6">
        {poll.options.map((o) => {
          const responses = poll.responses.filter((r) => r.response === o);
          return (
            <div className="flex flex-col gap-2">
              <div className="flex justify-between items-center">
                <p className="font-medium text-primary"> {o} </p>
                <p className="font-medium text-primary">
                  {responses.length}{' '}
                  {t('page.eventDetails.reactions', {
                    count: responses.length,
                  })}
                </p>
              </div>
              {responses.map((r) => {
                const alias = `${r.user.firstName}+${r.user.lastName}`;
                return (
                  <div className="flex items-center gap-2">
                    <Avatar className="w-8 h-8" src={r.user.picture} alias={alias} />
                    <p className="text-sm">{alias.replace('+', ' ')}</p>
                  </div>
                );
              })}
            </div>
          );
        })}
      </ModalLayout>
    </>
  );
}

interface DateOptionsPanelProps {
  options: DateOption[];
}
function DateOptionsPanel({ options }: DateOptionsPanelProps) {
  const { t } = useTranslation();

  const maxScore = options.reduce((acc, o) => Math.max(acc, eventUtils.scoreDateOption(o)), 0);
  const [bestOptions, otherOptions] = _.partition(
    options,
    (o) => eventUtils.scoreDateOption(o) === maxScore,
  ).map((os) => eventUtils.sortDateOptions(os));
  const [collapsed, setCollapsed] = useState(true);

  return (
    <div className="flex flex-col gap-2">
      <h1 className="text-[28px] leading-[28px] font-serif">
        {t('page.eventDetails.dateOptions')}
      </h1>
      <div className="flex flex-col gap-2">
        <h1 className="text-[18px] leading-[24px] font-serif">
          {t('page.eventDetails.bestDateOptions')}
        </h1>
        <div className="flex flex-col gap-6">
          {bestOptions.map((option) => (
            <DateOptionResult key={option.id} option={option} />
          ))}
        </div>
      </div>
      {!!otherOptions.length && (
        <div className="flex flex-col gap-2 mt-4">
          <h1 className="text-[18px] leading-[24px] font-serif">
            {t('page.eventDetails.otherDateOptions')}
          </h1>
          <Button variant="tertiary" onClick={() => setCollapsed(!collapsed)}>
            {t(`page.eventDetails.${collapsed ? 'show' : 'hide'}Other`)}
            <FaChevronDown
              className={classNames('w-5 h-5 transition-all ', { 'rotate-180': !collapsed })}
            />
          </Button>
          <div
            className={classNames('flex flex-col gap-6 overflow-hidden transition-all', {
              'max-h-[0px] ease-[cubic-bezier(0,1,0,1)] duration-500': collapsed,
              'max-h-[2000px] ease-[ease-in-out] duration-1000': !collapsed,
            })}>
            {otherOptions.map((option) => (
              <DateOptionResult key={option.id} option={option} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

interface DateOptionResultProps {
  option: DateOption;
}

function DateOptionResult({ option }: DateOptionResultProps) {
  const { answers } = option;

  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);

  const resolvedAnswers = answers.filter(
    ({ answer }) => answer !== EventParticipationAnswer.PENDING,
  );
  const percentage = eventUtils.calculatePercentage(option);

  return (
    <>
      <div className="flex flex-col gap-3 w-full">
        <p className="font-medium text-primary">{eventUtils.formatDate(option)}</p>
        <div className="flex justify-between items-center gap-4">
          <div className="flex gap-2 items-center">
            <IconBadge icon={FaRegClock} className="rounded-[6px]" />
            <p className="text-sm">{eventUtils.formatDate(option, 'time')}</p>
          </div>
          <ParticipationAnswersOverview answers={_.countBy(answers, 'answer')} />
        </div>
        <ProgressBar progress={percentage} />
        {!!resolvedAnswers.length && (
          <Button className="p-0 underline" onClick={() => setModalOpen(true)}>
            {t('page.eventDetails.viewAnswers')}
          </Button>
        )}
      </div>
      <ModalLayout
        open={modalOpen}
        setOpen={setModalOpen}
        closeButton
        className="bg-secondary-50 min-w-[450px] flex flex-col max-w-[600px] py-8 mx-6 z-50 rounded-[20px] px-12 gap-6">
        <div className="flex flex-col gap-2">
          <h1 className="text-[28px] leading-[28px] font-serif">{eventUtils.formatDate(option)}</h1>
          <div className="flex gap-2 items-center">
            <IconBadge icon={FaRegClock} className="rounded-[6px]" />
            <p className="text-sm">{eventUtils.formatDate(option, 'time')}</p>
          </div>{' '}
        </div>
        <div className="flex flex-col gap-2">
          <h2 className="font-serif text-[18px] leading-[24px]">
            {t('page.eventDetails.participants')}
          </h2>
          {resolvedAnswers.map((a) => (
            <ParticipantCard
              key={a.id}
              participant={a as EventParticipant}
              includeButton={false}
              badge="right"
            />
          ))}
        </div>
      </ModalLayout>
    </>
  );
}

interface DatePickerResponsesOverviewPanelProps {
  event: Event;
}

function DatePickerResponsesOverviewPanel({
  event: { participants },
}: DatePickerResponsesOverviewPanelProps) {
  const { t } = useTranslation();

  const [responded, notResponded] = _.partition(participants, (p) =>
    p.answers?.some(({ answer }) => answer !== EventParticipationAnswer.PENDING),
  );

  return (
    <div className="flex flex-col w-full gap-8 lg:p-4">
      {!!responded.length && (
        <div className="flex flex-col gap-4">
          <h1 className="text-[28px] leading-[28px] font-serif">
            {t('page.eventDetails.responded')}
          </h1>
          {responded.map((p) => (
            <ParticipantCard key={p.id} participant={p} includeButton={false} />
          ))}
        </div>
      )}
      {!!notResponded.length && (
        <div className="flex flex-col gap-2">
          <h1 className="text-[28px] leading-[28px] font-serif">
            {t('page.eventDetails.notResponded')}
          </h1>
          {notResponded.map((p) => (
            <ParticipantCard key={p.id} participant={p} includeButton={false} />
          ))}
        </div>
      )}
    </div>
  );
}
