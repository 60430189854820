import React from 'react';
import { twMerge } from 'tailwind-merge';

interface PageHeaderProps {
  title: string;
  subtitle: string;
  className?: string;
}

export default function PageHeader({ title, subtitle, className }: PageHeaderProps): JSX.Element {
  return (
    <div className={twMerge('flex flex-col', className)}>
      <h1 className="font-semibold font-serif text-3xl mb-2">{title}</h1>
      <p className="lg:w-1/2">{subtitle}</p>
    </div>
  );
}
