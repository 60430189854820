/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Connection, ConnectionType } from '../../../redux/slices/connectionsSlice';
import Button from '../buttons/Button';
import localContactService from '../../../services/localContactService';
import HoverButton from '../buttons/HoverButton';
import { PendingBadge, UnverifiedBadge, VerifiedBadge } from '../icons/ConnectionTypeBadge';

interface ConnectionStatusProps {
  connection: Connection;
  interactive?: boolean;
  className?: string;
}

export default function ConnectionStatus({
  connection,
  interactive = false,
  className = '',
}: ConnectionStatusProps): JSX.Element {
  if (connection.type === ConnectionType.PERSONAL_MOMENT_CONNECTION)
    return <VerifiedStatus className={className} />;
  if (connection.type === ConnectionType.B2C_CONNECTION)
    return <VerifiedStatus className={className} />;
  if (connection.type === ConnectionType.LOCAL_CONTACT && connection.inviteSent)
    return <PendingStatus className={className} />;
  return (
    <UnverifiedStatus connection={connection} interactive={interactive} className={className} />
  );
}

interface StatusProps {
  className: string;
}

function VerifiedStatus({ className }: StatusProps) {
  return <VerifiedBadge className={className} />;
}

function PendingStatus({ className }: StatusProps) {
  return <PendingBadge className={className} />;
}

type UnverifiedStatusProps = StatusProps & {
  connection: Connection;
  interactive: boolean;
};

function UnverifiedStatus({ connection, interactive, className }: UnverifiedStatusProps) {
  const { t } = useTranslation();

  if (!interactive) return <UnverifiedBadge className={className} />;

  return (
    <HoverButton clickable={false}>
      <HoverButton.Button>
        <UnverifiedBadge className={className} />
      </HoverButton.Button>

      <HoverButton.Div
        className={classNames(
          'gap-4 flex whitespace-normal h-fit -top-7 left-[180%] absolute transition-all flex-col  w-[400px] items-start justify-center rounded-[20px] px-4 py-5 bg-primary-900',
        )}>
        <div className="flex flex-col z-10 gap-1">
          <h1 className="text-secondary-50 leading-6 text-[22px] font-serif">
            {t('component.connectionStatus.pending.title')}
          </h1>
          <p className="text-secondary-50 text-sm font-normal">
            {t('component.connectionStatus.pending.subtitle')}
          </p>
        </div>
        {connection.fields.EMAIL && (
          <Button
            variant="secondary"
            onClick={(e) => {
              localContactService
                .inviteContacts([connection.id])
                .then(() => toast.success(t('toast.success.inviteSent_one')));
              e.stopPropagation();
            }}>
            {t('component.connectionStatus.pending.button')}
          </Button>
        )}
        <div className="w-10 h-10 absolute top-6 -left-2 rotate-45 bg-primary-900 rounded-[4px]" />
      </HoverButton.Div>
    </HoverButton>
  );
}
