import React from 'react';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';

interface CommunicationNameInputProps {
  name: string;
  setName: (name: string) => void;
  mandatory?: boolean;
  label?: boolean;
  className?: string;
}

export default function CommunicationNameInput({
  name,
  setName,
  mandatory = false,
  label = false,
  className,
}: CommunicationNameInputProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <div className={twMerge('w-full', className)}>
      {label && (
        <label className=" pr-2 font-medium text-base mb-1">
          {t('form.communicationName.label')} {mandatory ? '*' : ''}
        </label>
      )}
      <div className="flex flex-row items-center rounded-lg w-full">
        <div className="flex flex-col w-full">
          <label className="pr-2 mb-1 text-sm focus:ring-0 focus:ring-transparent focus:border-[#01AFA5] focus:border-3">
            {t('form.communicationName.communicationName')}
          </label>
          <input
            placeholder={t('form.communicationName.placeholder')}
            name="email"
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
      </div>
    </div>
  );
}
