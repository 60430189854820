import { useSelector } from 'react-redux';
import Business from '../../types/business';
import { RootState } from '../../redux/reducers';
import { store } from '../../redux/store';

function useSelectedBusiness(): Business | undefined {
  const business = useSelector((state: RootState): Business | undefined =>
    state.application.businesses?.find(
      (x: Business): boolean => x.businessId === state.application.selectedBusiness,
    ),
  );

  return business;
}

export function getSelectedBusiness(): Business | undefined {
  return store
    .getState()
    .application.businesses?.find(
      (x: Business): boolean => x.businessId === store.getState().application.selectedBusiness,
    );
}

export default useSelectedBusiness;
